import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as appRoutes from "./route-constants/app-routes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import VendorList from "../views/Vendors/VendorList";
import AppHeader from "../shared/components/AppHeader";
import AppSidebar from "../shared/components/AppSidebar";
import Dashboard from "../views/Dashboard";
import CreateVendor from "../views/Vendors/CreateVendor";
import VendorDetail from "../views/Vendors/VendorDetail";
import ScrollToTop from "../shared/components/ScrollToTop/ScrollToTop";
import ProductList from "../views/Products/ProductList";
import CreateProduct from "../views/Products/CreateProduct";
import ProductDetail from "../views/Products/ProductDetail";
import OrderList from "../views/Orders/OrderList";
import OrderDetail from "../views/Orders/OrderDetail";
import TicketList from "../views/TIckets/TicketList";
import CreateTicket from "../views/TIckets/CreateTicket";
import AppToast from "../shared/components/AppToast";
import { ToastProvider } from "react-toast-notifications";
import { requireAuth } from "../shared/components/HOC/require_auth";

import createHistory from "history/createBrowserHistory";
import { TicketDetails } from "../views/TIckets/TicketDetails";
import { InvoicePurchaseOrder } from "../views/InvoicePurchaseOrder";
import { Users } from "../views/Users";
import { RaiseInvoice } from "../views/InvoicePurchaseOrder/RaiseInvoice";
import InvoiceSummary from "../views/InvoicePurchaseOrder/InvoiceSummary";
import { EditTicket } from "../views/TIckets/EditTicket";
import { EditProduct } from "../views/Products/EditProduct";
import UserDetails from "../views/Users/UserDetaiils";
import Loader from "../shared/components/Loader";
import RMA from "../views/RMA/List";
import RMAForm from "../views/RMA/RMAForm";
import CreateRMA from "../views/RMA/CreateRMA";
import { RMADetails } from "../views/RMA/RMADetails";
import { Profile } from "../views/Users/Profile";

export const appHistory = createHistory();

export default function AppRoutes() {
    const routes = [
        { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
        {
            exact: true,
            path: appRoutes.DASHBOARD,
            component: requireAuth(Dashboard),
        },
        {
            exact: true,
            path: appRoutes.VENDORS,
            component: requireAuth(VendorList),
        },
        {
            exact: true,
            path: appRoutes.VENDOR_DETAIL,
            component: requireAuth(VendorDetail),
        },
        {
            exact: true,
            path: appRoutes.ADD_VENDOR,
            component: requireAuth(CreateVendor),
        },
        {
            exact: true,
            path: appRoutes.PRODUCTS,
            component: requireAuth(ProductList),
        },
        {
            exact: true,
            path: appRoutes.PRODUCT_DETAIL,
            component: requireAuth(ProductDetail),
        },
        {
            exact: true,
            path: appRoutes.ADD_PRODUCT,
            component: requireAuth(CreateProduct),
        },
        {
            exact: true,
            path: appRoutes.ORDERS,
            component: requireAuth(OrderList),
        },
        {
            exact: true,
            path: appRoutes.ORDER_DETAIL,
            component: requireAuth(OrderDetail),
        },
        {
            exact: true,
            path: appRoutes.TICKETS,
            component: requireAuth(TicketList),
        },
        {
            exact: true,
            path: appRoutes.ADD_TICKET,
            component: requireAuth(CreateTicket),
        },
        {
            exact: true,
            path: appRoutes.TICKET_DETAILS,
            component: requireAuth(TicketDetails),
        },
        {
            exact: true,
            path: appRoutes.INVOICE,
            component: requireAuth(InvoicePurchaseOrder),
        },
        {
            exact: true,
            path: appRoutes.USER,
            component: requireAuth(Users),
        },
        {
            exact: true,
            path: appRoutes.MY_PROFILE,
            component: requireAuth(Profile),
        },
        {
            exact: true,
            path: appRoutes.USER_DETAILS,
            component: requireAuth(UserDetails),
        },
        {
            exact: true,
            path: appRoutes.RAISE_INVOICE,
            component: requireAuth(RaiseInvoice),
        },
        {
            exact: true,
            path: appRoutes.INVOICE_DETAIL,
            component: requireAuth(InvoiceSummary),
        },
        {
            exact: true,
            path: appRoutes.EDIT_TICKET,
            component: requireAuth(EditTicket),
        },
        {
            exact: true,
            path: appRoutes.EDIT_PRODUCT,
            component: requireAuth(EditProduct),
        },{
            exact: true,
            path: appRoutes.RMA,
            component: requireAuth(RMA)
        }, {
            exact: true,
            path: appRoutes.RMA_FORM,
            component: requireAuth(CreateRMA)
        },{
            exact:true,
            path: appRoutes.RMA_DETAILS,
            component: requireAuth(RMADetails)
        }
    ];
    return (
        <div>
            <Router basename={appRoutes.BASE_NAME}>
                <AppHeader />
                <AppSidebar />
                <ToastProvider>
                    <AppToast />
                </ToastProvider>
                <div className="application-body">
                    <ScrollToTop>
                        <Switch>
                            {routes.map((route, i) => {
                                return (
                                    <Route
                                        key={i}
                                        exact={route.exact}
                                        path={route.path}
                                        component={route.component}
                                    />
                                );
                            })}
                        </Switch>
                    </ScrollToTop>
                </div>
            </Router>
        </div>
    );
}
