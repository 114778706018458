import axios from 'axios';
import { LOGIN_API_URL } from "../../routes/route-constants/api-routes";
import {deserialize} from "serializr";
import {User} from "../models/User/user.model";
import { UserService } from '../services/UserService/User.service';

let headers = {};

export const getHeaders = () => {
    let access_token: string;
    access_token = localStorage.getItem('access_token') || '';
    headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`,
    };
    return headers;
};

export const signIn = async(email: string,password: string) => {
    let response: any = {};
    try{
        response = await axios.post(`${LOGIN_API_URL}`, {email, password});
        if(response.data.data && response.data.data['token']) {
            localStorage.setItem('access_token', response.data.data['token']);
        }
        if(response.data.data && response.data.data['user']) {
            localStorage.setItem('user', JSON.stringify(deserialize(User,response.data.data['user'])));
        }
        return {
            data:response.data.data['user'],
            success:response.statusText === "OK",
            status:response.status
        };
    } catch (e) {
        if(e.response) {
            let errorResponse = e.response;
            let errorMessage  = e?.response?.data?.message || "Something went wrong";
            UserService.toastNotify(e, "error")
            if(errorResponse?.data?.errors?.length > 0) {
                errorMessage = errorResponse.data.errors[0];
            }
            return {
                success:errorResponse.data.success,
                message:errorMessage,
                status:errorResponse.status,
            }
        }
        else {
            return {
                success:false,
                message: 'Could not connect to server',
                status: '500',
            }
        }
    }
};
