import { combineReducers } from "redux";
import authReducer from "./authReducer";
import { loaderReducer } from "./loaderReducer";
import searchReducer from "./searchReducer";
import toastReducer from "./toastReducer";
import vendorReducer from "./vendorReducer";

const RootReducer = combineReducers({
    auth: authReducer,
    toast: toastReducer,
    vendor: vendorReducer,
    loader: loaderReducer,
    search: searchReducer,
});

export default RootReducer;
