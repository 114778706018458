import React, { useEffect, useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { RadioTable } from "../../../shared/components/RadioTable";
import { FinanceOrderDetail } from "../../../store/models/Finance/Order.model";
import { OrderService } from "../../../store/services/OrderService/Order.service";

const cols: { name: string; text: string }[] = [
    {
        name: "lycaSku",
        text: "Product SKU (Lyca)",
    },
    {
        name: "vendorSku",
        text: "Product SKU(vendor)",
    },
    {
        name: "vendorName",
        text: "Vendor Name",
    },
    {
        name: "vendorPrice",
        text: "Vendor Price",
    },
    {
        name: "customerPrice",
        text: "Customer price",
    },
    {
        name: "markupPrice",
        text: "Markup Price",
    },
];

interface Props {
    open: boolean;
    setOpen: (x: boolean) => void;
    data: any;
    handleFetchData: Function;
}

export const ProductApprovalConfirmationModal = ({
    open,
    setOpen,
    data,
    handleFetchData,
}: Props) => {
    const [orderDetail, setOrderDetail] = useState<any>(null);
    const [selected, setSelected] = useState<any>({});
    const [length, setLength] = useState(-1);

    useEffect(() => {
        if (data) {
            OrderService.getDetailOrder(
                data,
                (orderDetail: FinanceOrderDetail) => {
                    const data: any = [];
                    orderDetail?.orderItem?.forEach((orderItem, index) => {
                        setLength(index);
                        data.push(
                            orderItem.masterProduct?.vendorProducts?.map(
                                (vendorProduct) => {
                                    return {
                                        index,
                                        lycaSku: orderItem.masterProduct?.sku,
                                        vendorSku: vendorProduct?.sku,
                                        vendorPrice: vendorProduct?.vendorPrice,
                                        customerPrice:
                                            orderItem.masterProduct?.price,
                                        markupPrice: vendorProduct?.markupPrice,
                                        order_item: orderItem.id,
                                        vendor_product: vendorProduct.id,
                                        vendorName: vendorProduct.vendor?.name,
                                    };
                                }
                            )
                        );
                    });
                    setOrderDetail(data);
                },
                () => {}
            );
        }
    }, [data]);

    const handleApprove = () => {
        OrderService.approveFinanceOrder(
            data,
            Object.values(selected),
            () => {
                handleFetchData(data);
            },
            () => {}
        );
    };

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className="add-brand-modal"
        >
            <Modal.Content>
                <div className="add-brand-modal__container">
                    <Modal.Actions>
                        <Button
                            className="mb-5"
                            primary
                            disabled={
                                length !== Object.values(selected).length - 1
                            }
                            onClick={handleApprove}
                        >
                            <Icon name="check" />
                            Approve Vendor
                        </Button>
                    </Modal.Actions>
                    {orderDetail?.map((detail: any) => (
                        <RadioTable
                            rows={detail}
                            cols={cols}
                            onChange={(detail) => {
                                const selected = (data: any) => {
                                    return {
                                        ...data,
                                        [detail?.index]: {
                                            order_item: detail.order_item,
                                            vendor_product:
                                                detail.vendor_product,
                                        },
                                    };
                                };
                                setSelected(selected);
                            }}
                        />
                    ))}
                </div>
            </Modal.Content>
        </Modal>
    );
};
