import LocalStorage from "../../../shared/utils/LocalStorage";
import { RMA } from "../../../routes/route-constants/api-routes";
import { generatePath } from "react-router-dom";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { deserialize } from "serializr";
import { RMA as RMAModel } from "../../models/RMA/RMA.mode";
export class RMAService {
    static fetchAllRma(onSuccess: (data: any) => void) {
        const countryId = localStorage.getItem("country") || "";
        return axiosInstance
            .get(
                generatePath(RMA.getAll, {
                    id: countryId,
                })
            )
            .then((response) => {
                const RMA = deserialize(RMAModel, response?.data);
                onSuccess(RMA);
            })
            .catch((error) => {});
    }

    static createRMA(ticketId: any, onSuccess: (x:RMAModel) => void) {
        const payload = {
            maintenance_ticket_id:ticketId,
            return_type: 'pending_approval'
        }
        const countryId = LocalStorage.getValue("country");
        return axiosInstance
            .post(
                generatePath(RMA.createRMA, {
                    countryId,
                }),
                payload
            )
            .then((response) => {
                const data = deserialize(RMAModel, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                console.log(error?.message);
            });
    }

    static RMADetails(
        id: any,
        onSuccess: (x: RMAModel) => void,
        onError: () => void
    ) {
        const countryId = LocalStorage.getValue("country");
        return axiosInstance
            .get(generatePath(RMA.get, { countryId, id }))
            .then((response) => {
                const data = deserialize(RMAModel, response?.data);
                onSuccess(data);
            })
            .catch((error) => onError());
    }

    static updateRMA(
        id: any,
        type: string,
        onSuccess: (x: RMAModel) => void,
        onError: (x: Error) => void
    ) {
        const countryId = LocalStorage.getValue("country");

        const payload = {
            return_type: type,
        };
        return axiosInstance
            .put(
                generatePath(RMA.get, {
                    countryId,
                    id,
                }),
                payload
            )
            .then((response) => {
                const data = deserialize(RMAModel, response?.data);
                onSuccess(data);
            })
            .catch((error) => {
                console.log(error?.message);
                onError(error);
            });
    }
}
