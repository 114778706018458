import { serializable, alias, primitive, list, object } from "serializr";


class Options{
    @serializable(alias("label", primitive()))
    label:string = "";
    
    @serializable(alias("value", primitive()))
    value:string = "";

}

export class Attributes{
    @serializable(alias("attribute_id", primitive()))
    id?:string;

    @serializable(alias("attribute_code", primitive()))
    code?:string;
    
    @serializable(alias("options", list(object(Options))))
    options:Options[] =  [];
}