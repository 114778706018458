import { serializable, alias, primitive, list, object } from "serializr";

export class Maintenance {
    @serializable(alias("rma_number", primitive()))
    rmaNumber?: number;
}
export class RMA {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("maintenance_ticket_id", primitive()))
    maintenanceTicketId?: number;

    @serializable(alias("return_type", primitive()))
    returnType?: number;

    @serializable(alias("maintenance_ticket", object(Maintenance)))
    maintenanceTicket?: Maintenance;

    @serializable(alias("is_customer_approved", primitive()))
    isCustomerApproved?: Boolean;

    @serializable(alias("attachments", list(primitive())))
    attachments?: string[];
}
