import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import AuthContainer from "../../../store/containers/authContainer";

export const requireAuth = (ComposedComponent: any) => {
    class Authentication extends Component<any, any> {

        componentDidMount() {
            if (!this.props.authenticated) {
                this.props.history.push('/auth/login');
            }
        }

        componentDidUpdate(nextProps: any) {
            if (!nextProps.authenticated) {
                this.props.history.push('/auth/login');
            }
        }

        render() {
            return <ComposedComponent {...this.props} />;
        }
    }

    return (withRouter(AuthContainer(Authentication)));
};
