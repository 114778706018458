import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import { withRouter, RouteComponentProps } from "react-router";
import "./pageTitle.scss";

interface Props extends RouteComponentProps {
    title: any;
    noAction?: boolean;
}
function PageTitle(props: Props) {
    const { title, noAction } = props;
    return (
        <div className="page-title">
            <h3>
                {noAction ? null : (
                    <Icon
                        name="angle left"
                        onClick={() => props.history.goBack()}
                    />
                )}
                {title ? title : "Page title"}
            </h3>
        </div>
    );
}


export default withRouter(PageTitle);
