import React, { FC } from "react";
import { Modal, Button } from "semantic-ui-react";
import { SemanticCOLORS } from "semantic-ui-react/dist/commonjs/generic";
import "./promptModal.scss";

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit?: () => void;
    noActions?: boolean;
    className?: string;
    buttons?: { cancel?: string; submit?: string; cancelColor?:SemanticCOLORS; submitColor?:SemanticCOLORS };
}
export const PromptModal: FC<Props> = ({
    open,
    onClose,
    onSubmit,
    noActions,
    children,
    className,
    buttons,
}) => {
    const handleSubmit = () => {
        onClose();
        onSubmit && onSubmit();
    };
    return (
        <Modal
            closeIcon
            open={open}
            onClose={onClose}
            className={`prompt-modal ${className ? className : null}`}
        >
            <Modal.Content>
                <div className="prompt-modal__container">
                    {children}
                    {noActions ? null : (
                        <Modal.Actions>
                            <Button
                                // color="red"
                                color={buttons?.cancelColor}
                                className="mb-5"
                                onClick={onClose}
                            >
                                {buttons?.cancel || "Cancel"}
                            </Button>
                            <Button
                                color={buttons?.submitColor || "green"}
                                className="mb-5"
                                onClick={handleSubmit}
                            >
                                {buttons?.submit || "Submit"}
                            </Button>
                        </Modal.Actions>
                    )}
                </div>
            </Modal.Content>
        </Modal>
    );
};
