import { SET_SEARCH } from "../definitions/searchConstants";

export const setSearch = (search: string) => {
    return {
        type: SET_SEARCH,
        payload: {
            search,
        },
    };
};
