import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Form, Button, Modal } from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { useHistory } from "react-router";
import {
    getOptions,
    getDropdownOptions,
} from "../../../shared/utils/faker_data";
import "./userForm.scss";
import {
    userValidationSchema,
    userValidationSchemaProfilePage,
} from "./validationSchema";
import { Dropdown } from "../../../shared/components/Dropdown";
import { MetaService } from "../../../store/services/MetaService.service";
import { UserService } from "../../../store/services/UserService/User.service";
import ImageUpload from "../../../shared/components/ImageUpload";
import ImageUploadProfile from "../../../shared/components/ImageUpload/ImageUploadProfile";
import PageTitle from "../../../shared/components/PageTitle";
import * as yup from "yup";
import { ResetPasswordForAnyUser } from "./ResetPasswordForAnyUser";

interface IUser {
    name: string;
    email: string;
    phone: string;
    country: string[];
    role: number[];
    pic?: File;
}

const roles = ["Admin", "Product", "Finance", "Support"].map((text) => ({
    key: text,
    value: text,
    text,
}));

export const UserForm = ({
    controller,
    id = false,
    onData,
    initValues,
    profilePage,
    onSubmit,
}: any) => {
    const history = useHistory();
    const [user, setLoaded] = useState<any>({});
    const [countries, setCountries] = useState<any>([]);
    const [open, setOpen] = useState<boolean | number>(false);
    const [initialValues, setInitiaValue] = useState<IUser>({
        name: initValues?.name || "",
        email: initValues?.email || "",
        phone: initValues?.phoneNo || "",
        country: initValues?.userCountries || [],
        role: initValues?.userRoles.map((x: any) => x.role) || [],
        pic: initValues?.profilePic,
    });

    // useEffect(() => {
    //     setInitiaValue({
    //         name: initValues?.name || "",
    //         email: initValues?.email || "",
    //         phone: initValues?.phoneNo || "",
    //         country: initValues?.userCountries || [],
    //         role: initValues?.userRoles?.map((x:any)=>x.role)[0] || [],
    //     });
    // }, [initValues]);

    useEffect(() => {
        MetaService.getCountries(
            (data: any) => {
                const payload = data?.map((d: any) => ({
                    text: d.name,
                    value: d.id,
                    key: d.id,
                }));
                setCountries(payload);
                if (id) {
                    UserService.getUserById(
                        id,
                        (data: any) => {
                            console.log(payload);
                            const country = payload
                                ?.filter((country: any) => {
                                    return data?.countryName?.includes(
                                        country?.text
                                    );
                                })
                                ?.map((country: any) => country?.key);
                            setInitiaValue({
                                name: data?.userName,
                                email: data?.email,
                                phone: data?.phoneNo,
                                country,
                                role: data?.roles,
                            });
                        },
                        () => {}
                    );
                }
            },
            () => {}
        );
    }, [id]);

    const getErr = (touched: any, error: any, key: any): string => {
        return touched[key] && error[key] ? error[key] : "";
    };

    return (
        <div className="user-form">
            <Formik
                initialValues={initialValues}
                validationSchema={
                    profilePage
                        ? userValidationSchemaProfilePage
                        : userValidationSchema
                }
                enableReinitialize
                onSubmit={
                    profilePage && onSubmit
                        ? onSubmit
                        : (values, actions) => {
                              const payload = {
                                  ...values,
                                  phone_no: values.phone,
                                  user_roles: values.role?.map((role: any) => ({
                                      role,
                                  })),
                                  user_country_maps: values?.country?.map(
                                      (country_id: any) => ({
                                          country_id,
                                      })
                                  ),
                              };
                              if (id) {
                                  console.log(payload);
                                  UserService.updateUser(
                                      id,
                                      payload,
                                      (data: any) => {
                                          controller(false);
                                          onData(data);
                                      },
                                      () => {}
                                  );

                                  return;
                              }
                              UserService.createUser(
                                  payload,
                                  (data: any) => {
                                      controller(false);
                                      onData(data);
                                  },
                                  () => {}
                              );
                          }
                }
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                    setFieldValue,
                    touched,
                    handleBlur,
                    setTouched,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Field>
                                    <label>Name</label>
                                    <input
                                        name="name"
                                        onBlur={handleBlur}
                                        placeholder="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={getErr(
                                            touched,
                                            errors,
                                            "name"
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Email Address</label>
                                    <input
                                        name="email"
                                        onBlur={handleBlur}
                                        placeholder="email"
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange}
                                        disabled={profilePage}
                                    />
                                    <FormErrorMessage
                                        message={getErr(
                                            touched,
                                            errors,
                                            "email"
                                        )}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Phone Number</label>
                                    <input
                                        name="phone"
                                        onBlur={handleBlur}
                                        placeholder="Phone Number"
                                        type="text"
                                        value={values.phone}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={getErr(
                                            touched,
                                            errors,
                                            "phone"
                                        )}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Role</label>
                                    <Dropdown
                                        multiple
                                        onBlur={() =>
                                            setTouched({
                                                ...touched,
                                                role: true,
                                            })
                                        }
                                        placeholder="Select Role"
                                        options={roles}
                                        edit
                                        valOnly
                                        // defaultValue={initialValues.role}
                                        val={initialValues.role}
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("role", value)
                                        }
                                        disabled={profilePage}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.role
                                                ? errors.role instanceof Array
                                                    ? errors.role[0]
                                                    : errors.role
                                                : ""
                                        }
                                    />
                                    {/* <FormErrorMessage message={errors.role ? errors.role :  ""} /> */}
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                {profilePage ? null : (
                                    <Form.Field>
                                        <label>Country Access</label>
                                        <Dropdown
                                            valOnly
                                            // onBlur={handleBlur}
                                            onBlur={() =>
                                                setTouched({
                                                    ...touched,
                                                    country: true,
                                                })
                                            }
                                            placeholder="country"
                                            // defaultValue={initialValues.country}
                                            val={initialValues.country}
                                            multiple
                                            options={countries}
                                            onChange={(_, { value }: any) =>
                                                setFieldValue("country", value)
                                            }
                                            edit
                                        />
                                        <FormErrorMessage
                                            message={
                                                touched.country
                                                    ? errors.country instanceof
                                                      Array
                                                        ? errors.country[0]
                                                        : errors.country
                                                    : ""
                                            }
                                        />
                                    </Form.Field>
                                )}
                            </Form.Group>
                            {profilePage ? (
                                <Form.Group>
                                    <Form.Field className="fullwidth">
                                        <label>Profile Picture</label>
                                        <ImageUploadProfile
                                            value={initValues?.pic}
                                            onUpload={(image) => {
                                                setFieldValue(
                                                    "pic",
                                                    image[0]?.file
                                                );
                                            }}
                                            label="Upload Profile Picture"
                                        />

                                        <FormErrorMessage
                                            message={errors.pic}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            ) : null}
                            <Button
                                primary
                                className="float-right"
                                type="submit"
                                disabled={!isValid || (!profilePage && !dirty)}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                            {profilePage ? null : (
                                <Button
                                    className="float-right mr-2"
                                    type="button"
                                    onClick={() => controller(false)}
                                >
                                    {" "}
                                    Cancel
                                </Button>
                            )}
                            {profilePage ? null : (
                                <Button
                                    className="float-left mr-2"
                                    type="button"
                                    color="red"
                                    onClick={() => {
                                        setOpen(id);
                                    }}
                                >
                                    {" "}
                                    Reset Password
                                </Button>
                            )}
                        </Form>
                    );
                }}
            </Formik>
            <ResetPasswordForAnyUser
                open={open}
                setOpen={setOpen}
                parentModelClose={() => controller(false)}
            />
        </div>
    );
};
