import { serializable, alias, primitive, object, list } from "serializr";

class Revenue{
    @serializable(alias("revenue", primitive()))
    amount?: number;

    @serializable(alias("month", primitive()))
    month?: string;

    @serializable(alias("year", primitive()))
    year?: string | null;
}

export class RevenueByMonth{
    @serializable(alias('data', list(object(Revenue))))
    revenue?: Revenue[];
}