import { serializable, alias, primitive, list, object } from "serializr";
import { CountryMeta } from "../Meta/CountryMeta/countryMeta.model";

export class UserRole {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("role", primitive()))
    role?: string;

    @serializable(alias("user_id", primitive()))
    userId?: string;
}

export class MagentoCountry {
    @serializable(alias("id", primitive()))
    id?: string;
    @serializable(alias("name", primitive()))
    name?: string;
    @serializable(alias("magento_app_url", primitive()))
    magentoAppUrl?: string;
}

export class UserCountryMaps {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("user_id", primitive()))
    userId?: string;

    @serializable(alias("country_id", primitive()))
    countryId?: string;

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("country", object(MagentoCountry)))
    country?: MagentoCountry;
}

export class User {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("user_name", primitive()))
    userName?: string;

    @serializable(alias("roles", list(primitive())))
    roles?: string[];

    @serializable(alias("country_name", list(primitive())))
    countryName?: string[];

    @serializable(alias("email_verified_at", primitive()))
    emailVerifiedAt?: string;

    @serializable(alias("phone_no", primitive()))
    phoneNo?: string;

    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("user_roles", list(object(UserRole))))
    userRoles: UserRole[] = [];

    @serializable(alias("user_country_maps", list(object(CountryMeta))))
    userCountries: CountryMeta[] = [];

    @serializable(alias("user_country_maps", list(object(UserCountryMaps))))
    userCountryMaps: UserCountryMaps[] = [];

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("updated_at", primitive()))
    updatedAt?: string;
    
    @serializable(alias("profile_pic", primitive()))
    profilePic?: string;
}
