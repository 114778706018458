import { serializable, alias, primitive } from "serializr";

export class PurchaseOrder {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("lyca_order_no", primitive()))
    lycaOrderNo?: number;

    @serializable(alias("vendor_order_no", primitive()))
    vendorOrderNo?: null | string;

    @serializable(alias("po_number", primitive()))
    poNumber?: string;

    @serializable(alias("type", primitive()))
    type?: number;

    @serializable(alias("vendor_markup_price", primitive()))
    vendorMarkupPrice?: number;

    @serializable(alias("tax", primitive()))
    tax?: number;

    @serializable(alias("vendor_price", primitive()))
    vendorPrice?: number;

    @serializable(alias("delivery_status", primitive()))
    deliveryStatus?: number;

    @serializable(alias("invoice_id", primitive()))
    invoiceId?: number | null;
}
