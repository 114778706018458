import * as yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const vendorFormValidationSchema = yup.object({
    companyName: yup.string().required().label('Company name').min(3).max(191),
    pointOfContact: yup.string().required().label('Point of contact').min(3).max(191),
    emailAddress: yup.string().email().lowercase().required().label('Email address'),
    phoneNumber: yup.string().required().label('Phone number').min(8).max(15).matches(phoneRegExp,"Invalid phone number"),
    address: yup.string().required().label('Address').min(3).max(191),
    city: yup.string().required().label('City').min(3).max(191),
    country: yup.string().required().label('Country'),
    zipCode: yup.string().required().label('Zip code').min(5).max(7),
    taxType: yup.string().required().label('Tax type'),
    taxPercentage: yup.number().typeError('Tax should be valid number').required().label('Tax percentage').min(0).max(99.99),
    markupPercentage: yup.number().typeError('Markup should be valid number').required().label('Markup Percentage').min(0).max(99.99),
    shipmentAddress: yup.string().required().label('Shipment address').min(3).max(191),
    shipmentCity: yup.string().required().label('Shipment City').min(3).max(191),
    shipmentCountry: yup.string().required().label('Shipment Country'),
    shipmentZipCode: yup.string().required().label('Shipment zip code').min(5).max(7),
    // shipmentState: yup.array().of(yup.string()).required().label('Shipment state'),
    accountNumber: yup.string().required().label('Account number').min(8).max(15),
    sortCode: yup.string().label('Sort code').required().min(3).max(15),
    bankName:yup.string().required().label('Bank name').min(3).max(191)
});
