import { serializable, alias, primitive, object, list } from "serializr";

export class VendorTaxRule {
    @serializable(alias("tax_type_id", primitive()))
    taxTypeId?: string;

    @serializable(alias("tax_percentage", primitive()))
    taxPercentage?: string;

    @serializable(alias("global_markup_price", primitive()))
    globalMarkupPrice?: number;

    @serializable(alias("global_markup_currency", primitive()))
    globalMarkUpCurrency?: string;
}

export class VendorShipmentAddress {
    @serializable(alias("address", primitive()))
    address?: string;

    @serializable(alias("city", primitive()))
    city?: string;

    @serializable(alias("zip_code", primitive()))
    zipCode?: string;

    @serializable(alias("country_id", primitive()))
    countryId?: string;
}

export class VendorBankDetail {
    @serializable(alias("account_number", primitive()))
    accountNumber?: string;

    @serializable(alias("sort_code", primitive()))
    sortCode?: string;

    @serializable(alias("bank_name", primitive()))
    bankName?: string;
}

export class VendorShipmentRule {
    @serializable(alias("shipment_region_id", primitive()))
    shipmentRegionId?: string;
}

export class Vendor {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("phone_no", primitive()))
    phoneNo?: string;

    @serializable(alias("point_of_contact", primitive()))
    pointOfContact?: string;

    @serializable(alias("address", primitive()))
    address?: string;

    @serializable(alias("city", primitive()))
    city?: string;

    @serializable(alias("country_id", primitive()))
    countryId?: string;

    @serializable(alias("zip_code", primitive()))
    zipCode?: string;

    @serializable(alias("company_logo", primitive()))
    companyLogo?: string;

    @serializable(alias("vendor_tax_rule", object(VendorTaxRule)))
    vendorTaxRule?: VendorTaxRule;

    @serializable(
        alias("vendor_shipment_address", object(VendorShipmentAddress))
    )
    vendorShipmentAddress?: VendorShipmentAddress;

    @serializable(
        alias("vendor_shipment_rules", list(object(VendorShipmentRule)))
    )
    vendorShipmentRules: VendorShipmentRule[] = [];

    @serializable(alias("vendor_bank_detail", object(VendorBankDetail)))
    vendorBankDetail?: VendorBankDetail;

    @serializable(alias("profile_status", primitive()))
    profileStatus?: string;
}
export type RefurbishedStatus =
    | "new"
    | "like_new"
    | "excellent"
    | "very_good"
    | "good";

export type ApprovalStatus = "pending" | "merged" | "update" | "approved" | "hold";

export class VendorProductModel {
    @serializable(alias("sku", primitive()))
    sku?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("description", primitive()))
    description?: string;

    @serializable(alias("brand_id", primitive()))
    brandId?: number;

    @serializable(alias("color", primitive()))
    color?: string;

    @serializable(alias("storage", primitive()))
    storage?: string;

    @serializable(alias("memory", primitive()))
    memory?: string;

    @serializable(alias("category", primitive()))
    category?: string;

    @serializable(alias("quantity", primitive()))
    quantity?: number;

    @serializable(alias("vendor_price", primitive()))
    vendor_price?: Float32Array;

    @serializable(alias("miscellaneous_info", primitive()))
    miscellaneousInfo?: string;

    @serializable(alias("refurbished_status", primitive()))
    refurbishedStatus?: RefurbishedStatus;

    @serializable(alias("price_including_tax", primitive()))
    priceIncludingTax?: Float32Array;

    @serializable(alias("price_excluding_tax", primitive()))
    priceExcludingTax?: Float32Array;

    @serializable(alias("delivery_cost", primitive()))
    deliveryCost?: Float32Array;

    @serializable(alias("markup_price", primitive()))
    markupPrice?: number;

    @serializable(alias("approval_status", primitive()))
    approvalStatus?: ApprovalStatus;

    @serializable(alias("master_product_id", primitive()))
    masterProductId?: number;

    @serializable(alias("attribute_set_id", primitive()))
    attributeSetId?: number;

    @serializable(alias("vendor_id", primitive()))
    vendorId?: number;

    @serializable(alias("country_id", primitive()))
    countryId?: number;
}



// "vendor_tax_rule": {
//     "tax_type_id": 1,
//     "tax_percentage": 10,
//     "default_markup_percentage": 12
// },
// "vendor_shipment_address": {
//     "address": "123, test",
//     "city": "city",
//     "zip_code": "123456",
//     "country_id": 2
// },
// "vendor_shipment_rules": [
//     {
//         "shipment_region_id": 1
//     },
//     {
//         "shipment_region_id": 2
//     }
// ],
// "vendor_bank_detail": {
//     "account_number": "123456",
//     "sort_code": "test123",
//     "bank_name": "bank of spain"
// }


// export class VendorModel {
//     @serializable(alias("name", primitive()))
//     countryId?: string;


//     @serializable(alias("email", primitive()))
//     email?: string;

//     @serializable(alias("phone_no", primitive()))
//     phoneNo?: string;
    
//     @serializable(alias("point_of_contact", primitive()))
//     pointOfContact?: string;

//     @serializable(alias("address", primitive()))
//     address?: string;

//     @serializable(alias("city", primitive()))
//     city?: string;

//     @serializable(alias("city", primitive()))
//     city?: string;
    
// "country_id": 2,
// "zip_code": "123456",
// "company_logo": "[Object]",
// }