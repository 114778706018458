import React, { useState, useEffect } from "react";
import {
    getAllOrders,
    getProcessingOrders,
} from "../../../shared/utils/faker_data";
import { Icon, Button, Pagination } from "semantic-ui-react";
import ReactTable from "../../../shared/components/ReactTable";
import { ORDER_PROCESSING_STATUS } from "../../../shared/Constants/OrderStatus";
import { useHistory, generatePath } from "react-router";
import { ORDER_DETAIL } from "../../../routes/route-constants/app-routes";
import { fetchOrderService } from "../OrderService/service";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { namedDebounce } from "../../../shared/utils/debounce";
import { getPoNumbers } from "../../../shared/utils/StringConvertor";

export const ProcessingOrders = SearchContainer(
    ({ text }: { text: string }) => {
        const [search, setSearch] = useState(text);
        const [data, setData] = useState<{ [key: string]: any }[]>([]);
        const [current, setCurrent] = useState<any>(null);
        const [pageCount, setPagecount] = useState<any>(null);
        const history = useHistory();

        useEffect(()=>{
			namedDebounce(
				() => setSearch(text),
				250,
				"SEARCH_WaitingOnFinanceOrders"
			)
		},[text])

        useEffect(() => {
            (async () => {
                try {
                    const {
                        orders,
                        currentPage,
                        pageCount,
                    }: any = await fetchOrderService({
                        page: 1,
                        type: "processing",
                        orderNo: text,
                    });
                    setData(orders);
                    setCurrent(currentPage);
                    setPagecount(pageCount);
                } catch (error) {}
            })();
        }, [search]);
        const columns = [
            // {
            //     Header: "Lyca Order No",
            //     accessor: "id",
            //     Cell: ({ value }: any) => isFieldEmpty(value),
            // },
            {
                Header: "Magento Order No",
                accessor: "orderNo",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Order Date",
                accessor: "orderDate",
                Cell: ({ value }: any) => {
                    const date = new Date(value);
                    return `${date.getDate()}/${
                        date.getMonth() + 1
                    }/${date.getFullYear()}`;
                },
            },
            {
                Header: "Vendor Order No",
                accessor: "orderItem",
                Cell: ({ value }: any) => getPoNumbers(value) || "--",
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ value }: any) => <span>€{value}</span>,
            },
            {
                Header: "Customer Name",
                accessor: "customerName",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Pmt method",
                accessor: "paymentMethodName",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Region",
                accessor: "shipmentRegion[name]",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Delivery Status",
                accessor: "orderStatus",
                Cell: ({ value }: any) => {
                    console.log(value.charAt(0).toUpperCase() + value.slice(1));
                    return (
                        <>
                            <span style={{ color: "#006FBA" }}>
                                {isFieldEmpty(value)}
                            </span>
                        </>
                    );
                },
            },
            {
                Header: "Action",
                Cell: ({ row }: any) => (
                    <span
                        className="all-orders__action-button cursor-pointer"
                        onClick={() => {
                            history.push({
                                pathname: generatePath(ORDER_DETAIL, {
                                    orderId: row.original?.id,
                                }),
                            });
                        }}
                    >
                        <Icon name="eye" />
                    </span>
                ),
            },
        ];
        return (
            <div className="all-orders__table">
                <ReactTable headerLeft name="" columns={columns} data={data} />
                {pageCount === 1 || pageCount === null ? null : (
                    <Pagination
                        className="pagenation-container"
                        boundaryRange={0}
                        activePage={current}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={pageCount}
                        onPageChange={async (_, { activePage }: any) => {
                            try {
                                const {
                                    orders,
                                    currentPage,
                                    pageCount,
                                }: any = await fetchOrderService({page: activePage});
                                console.log(orders);
                                setCurrent(currentPage);
                                setPagecount(pageCount);
                            } catch (error) {}
                        }}
                    />
                )}
            </div>
        );
    }
);
