import React from "react";
import "./createTicket.scss";
import PageTitle from "../../../shared/components/PageTitle";
import RMAForm from "../RMAForm";

function CreateRMA() {
    return (
        <div className="create-ticket">
            <PageTitle title="New RMA" />
            <div className="create-ticket__form-wrapper">
                <RMAForm />
            </div>
        </div>
    );
}

export default CreateRMA;
