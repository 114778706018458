import React, { useState, useEffect } from "react";
import "./brandsAndMarkup.scss";
import { Button, Dropdown, Pagination } from "semantic-ui-react";
import ReactTable from "../../../shared/components/ReactTable";
import { getProducts, getBrandMarkup } from "../../../shared/utils/faker_data";
import { EditableCell } from "../../../shared/components/ReactTable/EditableCell";
import { ActionButton } from "../../../shared/components/ReactTable/ActionButton";
import { AddBrand } from "./AddBrand";
import { ManageBrands } from "./ManageBrands";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import { Brand } from "../../../store/models/Brand/Brand.model";
import { VendorBrand } from "../../../store/models/Vendor/vendorBrand.model";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";

export const BrandsAndMarkup = SearchContainer(({ text }: { text: string }) => {
    const [brands, setBrands] = useState<any>([]);
    const [data, setData] = useState<{ [key: string]: any }[]>([]);
    const [edit, setEdit] = useState(undefined);
    const [showModal, setShowModal] = useState<"manage" | "add" | false>(false);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const [brandId, setBrandId] = useState<any>(null);

    const fetchAllProductBrand = (brand_id: any, page: number) => {
        ProductService.getAllProductBrand(
            {
                brand_id,
                page,
                search: text,
            },
            (
                vendorBrands: VendorBrand[],
                currentPage: number,
                lastPage: number
            ) => {
                setData(vendorBrands);
                setCurrent(currentPage);
                setPagecount(lastPage);
            },
            () => {}
        );
    };
    useEffect(() => {
        if (brands.length) {
            fetchAllProductBrand(brands[0]?.key, 1);
            setBrandId(brands[0]?.key);
        }
    }, [brands, text]);

    useEffect(() => {
        if (!showModal) {
            ProductService.getAllBrandProducts(
                (brands: Brand[]) => {
                    setBrands(
                        brands?.map((brand) => ({
                            text: brand?.name,
                            value: brand?.id,
                            key: brand?.id,
                        }))
                    );
                },
                () => {}
            );
        }
    }, [showModal]);

    const updateMyData = (index: number, id: string, value: any) => {
        setData(([...prev]) => {
            prev[index][id] = value;
            return prev;
        });
    };
    const columns = [
        {
            Header: "Vendor Id",
            accessor: "vendorId",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "vendor Name",
            accessor: "vendor[name]",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Markup Percentage",
            accessor: "markupPercentage",
            Cell: (all: any) =>
                edit === all.row.id ? (
                    EditableCell({ ...all, updateMyData })
                ) : (
                    <span className="brand-and-markup__markup-percentage-cell">
                        {all.value}
                    </span>
                ),
        },
        {
            Header: "Action",
            Cell: ({ row }: any) => (
                <ActionButton
                    row={row}
                    edit={edit}
                    setEdit={setEdit}
                    makeCall
                />
            ),
        },
    ];
    return (
        <div className="brands-and-markup">
            <div className="brands-and-markup__card mb-6">
                <div className="brands-and-markup__select">
                    <div>Select Brand</div>
                    <Dropdown
                        placeholder="Select Brand"
                        options={brands}
                        value={brandId}
                        onChange={(_, { value }) => {
                            setBrandId(value);
                            fetchAllProductBrand(value, 1);
                        }}
                    />
                </div>
                <div>
                    <Button
                        className="mr-2"
                        positive
                        basic
                        onClick={() => setShowModal("manage")}
                    >
                        Manage Brands
                    </Button>
                    <Button
                        className="mr-2 ml-2"
                        primary
                        onClick={() => setShowModal("add")}
                    >
                        <span>
                            <i className="ui icon plus"></i>New Brand
                        </span>
                    </Button>
                </div>
            </div>
            <div className="brands-and-markup__table">
                <ReactTable name="" columns={columns} data={data} />
                {pageCount === 1 ? null : (
                    <Pagination
                        className="pagenation-container"
                        boundaryRange={0}
                        activePage={current}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={pageCount}
                        onPageChange={(_, { activePage }: any) => {
                            fetchAllProductBrand(brandId, activePage);
                        }}
                    />
                )}
            </div>
            <AddBrand
                open={showModal == "add"}
                setClose={() => setShowModal(false)}
            />
            <ManageBrands
                brands={brands}
                open={showModal == "manage"}
                onClose={() => setShowModal(false)}
            />
        </div>
    );
});
