import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, generatePath } from "react-router";
import "./masterProductsTable.scss";
import ReactTable from "../../../shared/components/ReactTable";
import { getMasterProducts } from "../../../shared/utils/faker_data";
import * as appRoutes from "../../../routes/route-constants/app-routes";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import { Pagination } from "semantic-ui-react";
import LocalStorage from "../../../shared/utils/LocalStorage";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { namedDebounce } from "../../../shared/utils/debounce";

function MasterProductsTable(props: any) {
    const [products, setProducts] = useState<any>([]);
    const [search, setSearch] = useState(props?.text);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const memory = LocalStorage.getAttribute("memory", true);
    const storage = LocalStorage.getAttribute("storage", true);
    const color = LocalStorage.getAttribute("product_color", true);

    const fetchAllMasterProduct = (pageNumber: number) => {
        ProductService.getAllMasterProduct(
            pageNumber,
            props?.text,
            (list: any, currentPage: number, lastPage: number) => {
                setProducts(list);
                setCurrent(currentPage);
                setPagecount(lastPage);
            },
            () => {}
        );
    };

    useEffect(()=>{
        namedDebounce(
            () => setSearch(props?.text),
            250,
            "SEARCH_WaitingOnFinanceOrders"
        )
    },[props?.text])

    useEffect(() => {
        fetchAllMasterProduct(1);
        console.log({
            memory,
            storage,
            color,
        });
    }, [props?.update, search]);

    const columns = [
        {
            Header: "Product Name",
            accessor: "name",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "SKU",
            accessor: "sku",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Quantity",
            accessor: "quantity",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Price in Magento",
            accessor: "price",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Storage",
            accessor: "storage",
            Cell: ({ value }: any) =>
                value
                    ? storage?.find((item) => item?.value === value)?.label ||
                      value
                    : "--",
        },
        {
            Header: "Memory",
            accessor: "memory",
            Cell: ({ value }: any) =>
                value
                    ? memory?.find((item) => item?.value === value)?.label ||
                      value
                    : "--",
        },
        {
            Header: "Color",
            accessor: "color",
            Cell: ({ value }: any) => {
                const productColor = color?.find(
                    (item) => item?.value === value
                )?.label;
                return value ? (
                    // ? color?.find((item) => item?.value === value)?.label
                    <div>
                        {/* <div
                            style={{
                                background: `#${productColor}`,
                                color: `#${productColor}`,
                                border: "1px solid #eee",
                                borderRadius: 100,
                                height: 15,
                                width: 15,
                                display: "inline-block",
                                paddingRight: "4px",
                            }}
                        ></div> */}
                        <span>{value}</span>
                    </div>
                ) : (
                    "--"
                );
            },
        },
        {
            Header: "Stock",
            accessor: "status",
            Cell: ({ value }: any) =>
                value == 1 ? "In Stock" : "Out of stock",
        },
    ];

    return (
        <div className="master-products-table">
            <ReactTable name="" columns={columns} data={products} />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        fetchAllMasterProduct(activePage);
                    }}
                />
            )}
        </div>
    );
}

MasterProductsTable.propTypes = {};

export default withRouter(SearchContainer(MasterProductsTable));
