import React from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { Button, Form } from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import Axios from "axios";
import { useHistory } from "react-router";
import { store } from "../../../store";
import { INVOKE_TOASTER } from "../../../store/definitions/toastConstants";
import { DASHBOARD } from "../../../routes/route-constants/app-routes";

function ResetPassword() {
    const initialValues = {
        password: "",
        confirmPassword: "",
    };
    const validationSchema = yup.object({
        password: yup.string().min(8).max(40),
        confirmPassword: yup
            .string()
            .required()
            .oneOf([yup.ref("password")], "Confirm Password doesnt match"),
    });

    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const baseUrl = params.get('url');
    const userName = params.get('user_name') || "User"

    const submitForm = (values:any) => {
        if(baseUrl)
        Axios.put(baseUrl,{
            password: values.password
        }).then(()=>{
            store.dispatch({
                type: INVOKE_TOASTER,
                payload: {
                    type: "success",
                    message:
                        "Password Reset Successful",
                },
            });
        })
        .catch(error=>{
            store.dispatch({
                type: INVOKE_TOASTER,
                payload: {
                    type: "error",
                    message:
                    error?.response?.data?.message || "Password Reset Failure",
                },
            });
        })
        .finally(()=>{
            setTimeout(()=>{
                history.push(DASHBOARD);
            },1600)
        })
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submitForm}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                }) => {
                    return (
                        <div className="login-form">
                            <h1 className="text-heading">Reset Password</h1>
                            <h3>Welcome {userName}</h3>
                            <Form onSubmit={handleSubmit}>
                                <Form.Field>
                                    <input
                                        className="input-login"
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={errors.password}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <input
                                        className="input-login"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        type="password"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={errors.confirmPassword}
                                    />
                                </Form.Field>
                                <Button
                                    className="float-right w-100 login-btn"
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                >
                                    {" "}
                                    Reset Password{" "}
                                </Button>
                            </Form>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
}

ResetPassword.propTypes = {};

export default ResetPassword;
