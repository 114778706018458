import {serializable, alias, primitive } from 'serializr';

export class Login {

    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('password', primitive()))
    password?: string;
}
