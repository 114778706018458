import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {getVendorActions} from "../actions/vendorActions";

export default function VendorContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({ getVendorActions }, dispatch);
}

function mapStateToProps(state: any) {
    return {
        vendorList: state.vendor.vendorList,
        activeVendor: state.vendor.activeVendor
    };
}
