import React, { FC } from "react";
import PropTypes from "prop-types";
import "./vendorTile.scss";
import { Link, generatePath } from "react-router-dom";
import * as appRoutes from "../../../routes/route-constants/app-routes";
import { Vendor } from "../../../store/models/Vendor/vendor.model";

interface Props {
    vendor: Vendor;
}
function VendorTile({ vendor }: Props) {
    return (
        <Link
            to={generatePath(appRoutes.VENDOR_DETAIL, { vendorId: vendor.id })}
        >
            <div className="vendor-tile">
                <div className="vendor-tile__header">
                    <span className="vendor-tile__thumbnail">
                        {vendor?.name
                            ?.match(/\b(\w)/g)
                            ?.join("")
                            .slice(0, 2)
                            .toUpperCase()}
                    </span>
                    <span className="vendor-tile__header-title">
                        {/* Bibendum orci porta */}
                        {vendor.name}
                    </span>
                </div>
                <div className="vendor-tile__body">
                    <div className="vendor-tile__body-row">
                        <span className="vendor-tile__body-icon">
                            <i className="ui icon user"></i>
                        </span>
                        <span className="vendor-tile__body-text">
                            {" "}
                            {vendor.name}{" "}
                        </span>
                    </div>
                    <div className="vendor-tile__body-row">
                        <span className="vendor-tile__body-icon">
                            <i className="ui icon envelope"></i>
                        </span>
                        <span className="vendor-tile__body-text">
                            {" "}
                            {vendor.email}{" "}
                        </span>
                    </div>
                    <div className="vendor-tile__body-row">
                        <span className="vendor-tile__body-icon">
                            <i className="ui icon phone"></i>
                        </span>
                        <span className="vendor-tile__body-text">
                            {" "}
                            {vendor.phoneNo}{" "}
                        </span>
                    </div>
                    <div className="vendor-tile__badge-wrapper">
                        {vendor.profileStatus === "active" ? (
                            <span className="badge primary rounded">
                                {" "}
                                Active{" "}
                            </span>
                        ) : null}
                    </div>
                </div>
            </div>
        </Link>
    );
}

VendorTile.propTypes = {};

export default VendorTile;
