import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./ticketForm.scss";
import { Formik } from "formik";
import { Form, Button } from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import {
    getOptions,
    getDropdownOptions,
} from "../../../shared/utils/faker_data";
import { useHistory, useLocation, useParams } from "react-router";
import { debouce } from "../../../shared/utils/debounce";
import ImageUpload from "../../../shared/components/ImageUpload";
import { ticketFormValidationSchema } from "./validationSchema";
import { Dropdown } from "../../../shared/components/Dropdown";
import { MetaService } from "../../../store/services/MetaService/Meta.service";
import { ProductMeta } from "../../../store/models/Meta/CountryMeta/Product.model";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import { TicketService } from "../../../store/services/TicketService/ticket.service";
import { RMAService } from "../../../store/services/RMAService/rma.service";

interface IRMAForm {
    orderItemId: string;
    issueType: string;
}

interface Props {
    initialTicketValues?: IRMAForm;
    edit?: boolean;
}
function TicketForm({ initialTicketValues, edit = false }: Props) {
    const initialValues: IRMAForm = initialTicketValues || {
        issueType: "",
        orderItemId: "",
    };

    const history = useHistory();
    const location = useLocation();
    const params: any = useParams();

    const [products, setProducts] = useState<any>([]);
    const [issueType, setIssueType] = useState<any>([]);

    useEffect(() => {
        MetaService.getIssueTypes(
            (data: any) => {
                setIssueType(data);
            },
        );

        TicketService.getAllTicket({}, (tickets: Ticket[]) => {
            setProducts(tickets?.map(ticket => ({
                text: ticket?.userOrder?.orderNo,
                key: ticket?.id,
                value: ticket?.id
            })))
        }, () => {

        })
    }, []);


    return (
        <div className="ticket-form">
            <Formik
                initialValues={initialValues}
                validationSchema={ticketFormValidationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    const [type] = location.pathname?.split("/").reverse();
                    if (type === "edit") {
                    } else {
                        const payload = {
                            "maintenance_ticket_id": values?.orderItemId,
                            "return_type": values?.issueType
                        };
                        RMAService.createRMA(payload, () => {
                            history.goBack();
                        })
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                    setFieldValue,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                            <Form.Field>
                                    <label>Ticket Id</label>
                                    <Dropdown
                                        placeholder="Select Ticket Id"
                                        options={products}
                                        defaultValue={
                                            initialTicketValues?.orderItemId
                                        }
                                        onChange={(_, { value }: any) => {
                                            setFieldValue("orderItemId", value);
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={errors.orderItemId}
                                    />
                            </Form.Field>
                            <Form.Field>
                                    <label>Issue Type</label>
                                    <Dropdown
                                        placeholder="Select Type"
                                        options={issueType}
                                        defaultValue={
                                            initialTicketValues?.issueType || ""
                                        }
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("issueType", value)
                                        }
                                    />
                                    <FormErrorMessage
                                        message={errors.issueType}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                primary
                                className="float-right"
                                type="submit"
                                disabled={!isValid}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                            <Button
                                className="float-right mr-2"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                {" "}
                                Cancel
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}


export default TicketForm;
