import { serializable, alias, primitive, object, list } from "serializr";
import { Vendor } from "../Vendor/vendor.model";
import { PurchaseOrder } from "./purchaseOrder.model";

class OrderItem {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("vendor_order_no", primitive()))
    vendorId?: null | string;
}

class PurchaseInvoice extends PurchaseOrder {
    @serializable(alias("order_item", object(OrderItem)))
    OrderItem?: OrderItem;
}

export class Invoice {
    @serializable(alias("id", primitive()))
    id?: string;

    @serializable(alias("vendor_id", primitive()))
    vendorId?: string;

    @serializable(alias("total_amount", primitive()))
    totalAmount?: string;

    @serializable(alias("total_tax", primitive()))
    totalTax?: string;

    @serializable(alias("payment_status", primitive()))
    paymentStatus?: string;

    @serializable(alias("created_at", primitive()))
    createdAt?: string;

    @serializable(alias("type", primitive()))
    type?: string;

    @serializable(alias("billable_to", primitive()))
    billableTo?: string;

    @serializable(alias("mode_of_transfer", primitive()))
    modeOfTransfer?: string | null;

    @serializable(alias("transaction_id", primitive()))
    transactionId?: string | null;

    @serializable(alias("purchase_orders", list(object(PurchaseInvoice))))
    purchaseOrders?: PurchaseInvoice[];

    @serializable(alias("vendor", object(Vendor)))
    vendor?: Vendor;

    @serializable(alias("number_of_po", primitive()))
    purchaseOrderCount?: Number;
}

