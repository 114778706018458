import { serializable, alias, primitive, object, list } from "serializr";
import { Brand } from "../Brand/Brand.model";

export type RefurbishedStatus =
    | "new"
    | "like_new"
    | "excellent"
    | "very_good"
    | "good";

export type ApprovalStatus =
    | "pending"
    | "merged"
    | "update"
    | "approved"
    | "hold";

export class MasterProduct {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("brand_id", primitive()))
    brandId?: number;

    @serializable(alias("visibility", primitive()))
    visibility?: number;

    @serializable(alias("country_id", primitive()))
    countryId?: number;

    @serializable(alias("attribute_set_id", primitive()))
    attribute_set_id?: number;

    @serializable(alias("sku", primitive()))
    sku?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("status", primitive()))
    status?: string;

    @serializable(alias("price", primitive()))
    price?: Float32Array;

    @serializable(alias("quantity", primitive()))
    quantity?: number;

    @serializable(alias("storage", primitive()))
    storage?: string | null;

    @serializable(alias("memory", primitive()))
    memory?: string | null;

    @serializable(alias("color", primitive()))
    color?: string | null;
}

export class Vendor {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("phone_no", primitive()))
    phoneNo?: string;
}

export class VendorProductModel {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("sku", primitive()))
    sku?: string;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("status", primitive()))
    status?: string | null;

    @serializable(alias("visibility", primitive()))
    visibility?: string | null;

    @serializable(alias("quantity", primitive()))
    quantity?: number;

    @serializable(alias("markup_price", primitive()))
    markupPrice?: number;

    @serializable(alias("approval_status", primitive()))
    approvalStatus?: ApprovalStatus;

    @serializable(alias("master_product_id", primitive()))
    masterProductId?: number;

    @serializable(alias("attribute_set_id", primitive()))
    attributeSetId?: number;

    @serializable(alias("vendor_id", primitive()))
    vendorId?: number;

    @serializable(alias("country_id", primitive()))
    countryId?: number;

    @serializable(alias("description", primitive()))
    description?: string;

    @serializable(alias("storage", primitive()))
    storage?: string;

    @serializable(alias("memory", primitive()))
    memory?: string;

    @serializable(alias("color", primitive()))
    color?: string;

    @serializable(alias("category", primitive()))
    category?: string;

    @serializable(alias("vendor_price", primitive()))
    vendorPrice?: Float32Array;

    @serializable(alias("delivery_cost", primitive()))
    deliveryCost?: Float32Array;

    @serializable(alias("refurbished_status", primitive()))
    refurbishedStatus?: string;

    @serializable(alias("miscellaneous_info", primitive()))
    miscellaneousInfo?: string;

    @serializable(alias("price_excluding_tax", primitive()))
    priceExcludingTax?: Float32Array;

    @serializable(alias("price_including_tax", primitive()))
    priceIncludingTax?: Float32Array;

    @serializable(alias("brand_id", primitive()))
    brandId?: number;

    @serializable(alias("master_product", object(MasterProduct)))
    masterProduct?: MasterProduct;

    @serializable(alias("vendor", object(Vendor)))
    vendor?: Vendor;
}

export class FinanceMasterProduct extends MasterProduct {
    @serializable(alias("vendor_products", list(object(VendorProductModel))))
    vendorProducts?: VendorProductModel[];
}

export class Product extends VendorProductModel {
    @serializable(alias("refurbished_status", primitive()))
    refurbishedStatus?: string;

    @serializable(alias("miscellaneous_info", primitive()))
    miscellaneousInfo?: string;

    @serializable(alias("price", primitive()))
    price?: number;

    @serializable(alias("vendor_sku", primitive()))
    vendorSku?: string;

    @serializable(alias("vendor", object(Vendor)))
    vendor?: Vendor;

    @serializable(alias("brand", object(Brand)))
    brand?: Brand;
}
