import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import Canvas from "../../../../Canvas/canvasjs.react";
import { DateRange } from "../../../../shared/components/DateRange";
import { namedDebounce } from "../../../../shared/utils/debounce";
import { DashboardService } from "../../../../store/services/DashboardService/Dashboard.service";
import "./vendorSales.scss";

const CanvasJSChart = Canvas.CanvasJSChart;


let chartRef: any;

const VendorSales = () => {
    const [selected, setSelected] = useState<any>("month");

    const [date, setDate] = useState<{start_date:String, end_date:String}>()
    const [dataPoints, setDataPoints] = useState<{label:String, y:number}[]>([])
    // const [dropwownOptions, setDropdownOptions] = useState<
    //     {
    //         key: string | number;
    //         value: string | number;
    //         text: string;
    //     }[]
    // >([
    //     {
    //         key: 1,
    //         value: 1,
    //         text: "Apr 2020 - Jul 2020",
    //     },
    // ]);
    const handleChart = (type: any) => {
        console.log(chartRef);
        // chartRef.options.data[0].dataPoints = type === "month" ? month : week;
        chartRef?.render();
        setSelected(type);
    };

    useEffect(()=>{
        namedDebounce(()=>{
            date &&
            DashboardService.getTop5Vendors(date, (data)=>{
                let temp = data?.vendors?.map(x=>({label:x.vendorName || "", y: x.sold || 0}));
                setDataPoints(temp || []);
            })
        },
        400,
        "vendor_sales_chart_debounce"
        )
    },[date])


    
    const options = {
        animationEnabled: true,
        theme: "light2",
        dataPointWidth: 7,
        axisX: {
            labelFontSize: 12,
        },
        data: [
            {
                type: "column",
                color: "#00A7BA",
                dataPoints,
            },
        ],
    };
    return (
        <div className="revenue-chart">
            <div className="header">
                <p>Vendor Sales</p>
                <div className="toggle-chart">
                    {/* <Dropdown className="dropdown-date-selector" options={dropwownOptions} defaultValue={1} /> */}
                    <DateRange onDateChange={setDate}/>
                </div>
            </div>
            <CanvasJSChart
                options={options}
                onRef={(ref: any) => (chartRef = ref)}
            />
        </div>
    );
};

export default VendorSales;
