import React from "react";
import PageTitle from "../../../shared/components/PageTitle";
import ProductForm from "../ProductForm";
import "./editProduct.scss";
import { RouteComponentProps } from "react-router";

interface Props extends RouteComponentProps {}
export const EditProduct = ({ match }: Props) => {
    const { params }: any = match;
    const initialValues = {
        vendorSku: "abc-123",
        vendorId: "vend-123",
        name: "IPhone",
        category: "phone",
        description: "new Iphone",
        brandId: "apple",
        markupPrice: "134",
        vendorPrice: "234",
        deliveryCost: "453",
        quantity: "4",
        refurbishedStatus: "new",
        storage: "64GB",
        memory: "4GB",
        color: "gold",
        miscellaneousInfo: "",
        priceExcludingTax: "430",
        priceIncludingTax: "520",
    };
    return (
        <div className="edit-product">
            <PageTitle title={`${params.id}`} />
            <div className="edit-product__form-wrapper">
                <ProductForm productInitialValues={initialValues} />
            </div>
        </div>
    );
};
