import React from "react";
import "./chart.scss";
import TopSold from "./TopSold";
import VendorSales from "./VendorSales";
const Chart = () => {
    return (
        <div className="stats-chart-wrapper">
            <TopSold />
            <VendorSales />
        </div>
    );
};

export default Chart;
