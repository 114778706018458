import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./ticketForm.scss";
import { Formik } from "formik";
import { Form, Button } from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import {
    getOptions,
    getDropdownOptions,
} from "../../../shared/utils/faker_data";
import { useHistory, useLocation, useParams } from "react-router";
import { debouce } from "../../../shared/utils/debounce";
import ImageUpload from "../../../shared/components/ImageUpload";
import { ticketFormValidationSchema } from "./validationSchema";
import { Dropdown } from "../../../shared/components/Dropdown";
import { MetaService } from "../../../store/services/MetaService/Meta.service";
import { ProductMeta } from "../../../store/models/Meta/CountryMeta/Product.model";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import { TicketService } from "../../../store/services/TicketService/ticket.service";

interface ITicketForm {
    userOrderId: string;
    orderItemId: string;
    email: string;
    mobileNo: string;
    issueType: string;
    issueDesc: string;
    groupAssigned: string;
    userAssigned: string;
    issueUpdates: string;
    issueStatus: string;
    csTeamGroup: string;
    rmaNumber: string;
    priority: string;
    dueDate: string;
    additionalDetails: string;
    attachments: File[];
}

interface Props {
    initialTicketValues?: ITicketForm;
    edit?: boolean;
}
function TicketForm({ initialTicketValues, edit = false }: Props) {
    const history = useHistory();
    const location = useLocation();
    const params: any = useParams();

    const [products, setProducts] = useState<any>([]);
    const [issueType, setIssueType] = useState<any>([]);
    const [groupAssigned, setGroupAssigned] = useState<any>([]);
    const [userAssigned, setUserAssigned] = useState<any>([]);
    const [csTeamGroup, setCsTeamGroup] = useState<any>([]);
    const [priority, setPriority] = useState<any>([]);
    const [issueStatus, setIssueStatus] = useState<any>([]);

    useEffect(() => {
        MetaService.getCSTeamGroup(
            (data: any) => {
                setCsTeamGroup(data);
            },
            () => {}
        );
        MetaService.getGroup(
            (data: any) => {
                setGroupAssigned(data);
            },
            () => {}
        );
        MetaService.getIssueType(
            (data: any) => {
                setIssueType(data);
            },
            () => {}
        );

        MetaService.getIssueProperties(
            (data: any) => {
                setPriority(data);
            },
            () => {}
        );
        MetaService.getIssueStatus(
            (data: any) => {
                setIssueStatus(data);
            },
            () => {}
        );
    }, []);

    useEffect(() => {
        if (initialTicketValues?.userOrderId) {
            MetaService.getProductList(
                initialTicketValues?.userOrderId,
                (products: ProductMeta) => {
                    setProducts(products);
                },
                () => {}
            );
        }
        if (initialTicketValues?.groupAssigned) {
            MetaService.getUserAssigned(
                initialTicketValues?.groupAssigned,
                (data: any) => {
                    setUserAssigned(data);
                },
                () => {}
            );
        }
    }, [initialTicketValues]);

    const initialValues: ITicketForm = initialTicketValues || {
        userOrderId: "",
        orderItemId: "",
        email: "",
        mobileNo: "",
        issueType: "",
        issueDesc: "",
        groupAssigned: "",
        userAssigned: "",
        issueUpdates: "",
        issueStatus: "",
        csTeamGroup: "",
        rmaNumber: "",
        priority: "",
        dueDate: "",
        additionalDetails: "",
        attachments: [],
    };

    return (
        <div className="ticket-form">
            <Formik
                initialValues={initialValues}
                validationSchema={ticketFormValidationSchema}
                enableReinitialize
                onSubmit={(values) => {
                    const payload = {
                        ...values,
                        assignedGroup: values.groupAssigned,
                        assignedUserId: values.userAssigned,
                    };
                    const parsedTicket = Object.assign(new Ticket(), payload);
                    const [type] = location.pathname?.split("/").reverse();
                    if (type === "edit") {
                        TicketService.editTicket(
                            params?.id,
                            parsedTicket,
                            () => {
                                history.goBack();
                            },
                            () => {}
                        );
                    } else {
                        TicketService.createTicket(
                            parsedTicket,
                            () => {
                                history.goBack();
                            },
                            () => {}
                        );
                    }
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                    touched,
                    setFieldValue,
                    handleBlur,
                    setTouched
                }) => {
                    const fetchProducts = debouce((orderId: string) => {
                        if (String(orderId).length > 9)
                            MetaService.getProductList(
                                orderId,
                                ({
                                    products,
                                    email,
                                    phone,
                                }: {
                                    products: ProductMeta;
                                    email: string;
                                    phone: string;
                                }) => {
                                    setFieldValue("email", email);
                                    setFieldValue("mobileNo", phone);
                                    setProducts(products);
                                },
                                () => {}
                            );
                    }, 500);

                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Field>
                                    <label>Order ID</label>
                                    <input
                                        name="userOrderId"
                                        onBlur={handleBlur}
                                        placeholder="Order Id"
                                        type="text"
                                        value={values.userOrderId}
                                        onChange={(...args) => {
                                            handleChange(...args);
                                            fetchProducts(args[0].target.value);
                                        }}
                                        disabled={edit}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.userOrderId &&
                                            errors.userOrderId
                                                ? errors.userOrderId
                                                : ""
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Select Product</label>
                                    <Dropdown
                                        placeholder="Select Product"
                                        options={products}
                                        defaultValue={
                                            initialTicketValues?.orderItemId
                                        }
                                        disabled={edit || !products.length}
                                        onChange={(_, { value }: any) => {
                                            setFieldValue("orderItemId", value);
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.orderItemId &&
                                            errors.orderItemId
                                                ? errors.orderItemId
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Customer Email address</label>
                                    <input
                                        name="email"
                                        onBlur={handleBlur}
                                        placeholder="Email"
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange}
                                        disabled={edit}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.email && errors.email
                                                ? errors.email
                                                : ""
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Customer Phone Number</label>
                                    <input
                                        name="mobileNo"
                                        onBlur={handleBlur}
                                        placeholder="Phone Number"
                                        type="text"
                                        value={values.mobileNo}
                                        onChange={handleChange}
                                        disabled={edit}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.mobileNo && errors.mobileNo
                                                ? errors.mobileNo
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Issue Type</label>
                                    <Dropdown
                                        placeholder="Select Type"
                                        options={issueType}
                                        defaultValue={
                                            initialTicketValues?.issueType || ""
                                        }
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("issueType", value)
                                        }
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.issueType &&
                                            errors.issueType
                                                ? errors.issueType
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field className="fullwidth">
                                    <label>Issue Description</label>
                                    <input
                                        type="text"
                                        onBlur={handleBlur}
                                        name="issueDesc"
                                        placeholder="issueDesc"
                                        value={values.issueDesc}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.issueDesc &&
                                            errors.issueDesc
                                                ? errors.issueDesc
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>

                            <Form.Group>
                                <Form.Field>
                                    <label>Group Assigned</label>
                                    <Dropdown
                                        placeholder="Select Group"
                                        options={groupAssigned}
                                        defaultValue={
                                            initialTicketValues?.groupAssigned ||
                                            ""
                                        }
                                        edit
                                        onChange={(_, { value }: any) => {
                                            setFieldValue(
                                                "groupAssigned",
                                                value
                                            );
                                            setFieldValue("userAssigned", "");
                                            MetaService.getUserAssigned(
                                                value,
                                                (data: any) => {
                                                    setUserAssigned(data);
                                                },
                                                () => {}
                                            );
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.groupAssigned &&
                                            errors.groupAssigned
                                                ? errors.groupAssigned
                                                : ""
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>User Assigned</label>
                                    <Dropdown
                                        placeholder="Select User"
                                        options={userAssigned}
                                        disabled={!userAssigned?.length}
                                        edit
                                        defaultValue={
                                            initialTicketValues?.userAssigned ||
                                            ""
                                        }
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("userAssigned", value)
                                        }
                                    />

                                    <FormErrorMessage
                                        message={
                                            touched.userAssigned &&
                                            errors.userAssigned
                                                ? errors.userAssigned
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Issue Updates</label>
                                    <input
                                        name="issueUpdates"
                                        onBlur={handleBlur}
                                        placeholder="Issue Updates"
                                        type="text"
                                        value={values.issueUpdates || ""}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.issueUpdates &&
                                            errors.issueUpdates
                                                ? errors.issueUpdates
                                                : ""
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Issue Status</label>
                                    <Dropdown
                                        placeholder="Select Issue"
                                        options={issueStatus}
                                        edit
                                        defaultValue={
                                            initialTicketValues?.issueStatus ||
                                            ""
                                        }
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("issueStatus", value)
                                        }
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.issueStatus &&
                                            errors.issueStatus
                                                ? errors.issueStatus
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>CS Team Group</label>
                                    <Dropdown
                                        placeholder="Select CS Team"
                                        options={csTeamGroup}
                                        edit
                                        defaultValue={
                                            initialTicketValues?.csTeamGroup ||
                                            ""
                                        }
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("csTeamGroup", value)
                                        }
                                    />

                                    <FormErrorMessage
                                        message={
                                            touched.csTeamGroup &&
                                            errors.csTeamGroup
                                                ? errors.csTeamGroup
                                                : ""
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>RMA number</label>
                                    <input
                                        name="rmaNumber"
                                        onBlur={handleBlur}
                                        placeholder="RMA"
                                        type="text"
                                        value={values.rmaNumber}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.rmaNumber &&
                                            errors.rmaNumber
                                                ? errors.rmaNumber
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Priority</label>
                                    <Dropdown
                                        placeholder="Select Priority"
                                        options={priority}
                                        edit
                                        defaultValue={
                                            initialTicketValues?.priority || ""
                                        }
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("priority", value)
                                        }
                                    />

                                    <FormErrorMessage
                                        message={
                                            touched.priority && errors.priority
                                                ? errors.priority
                                                : ""
                                        }
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Due Date</label>
                                    <input
                                        name="dueDate"
                                        onBlur={handleBlur}
                                        placeholder="Date"
                                        type="date"
                                        value={values.dueDate}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.dueDate && errors.dueDate
                                                ? errors.dueDate
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field className="fullwidth">
                                    <label>Additional Details</label>
                                    <input
                                        name="additionalDetails"
                                        onBlur={handleBlur}
                                        placeholder="Additional Details"
                                        type="text"
                                        value={values.additionalDetails}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={
                                            touched.additionalDetails &&
                                            errors.additionalDetails
                                                ? errors.additionalDetails
                                                : ""
                                        }
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field className="fullwidth">
                                    <ImageUpload
                                        maxNumber={5}
                                        onUpload={(images: File[]) => {
                                            setFieldValue(
                                                "attachments",
                                                images
                                            );
                                        }}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                primary
                                className="float-right"
                                type="submit"
                                disabled={!isValid}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                            <Button
                                className="float-right mr-2"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                {" "}
                                Cancel
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

TicketForm.propTypes = {};

export default TicketForm;
