import React, { useEffect, useState } from "react";
import ReactTable from "../../../shared/components/ReactTable";
import { Button, Pagination } from "semantic-ui-react";
import { generatePath, useHistory } from "react-router";
import { PRODUCT_DETAIL } from "../../../routes/route-constants/app-routes";
import { PromptModal } from "../../../shared/components/PromptModal";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import LocalStorage from "../../../shared/utils/LocalStorage";
import "./magentoProductsTable.scss";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { namedDebounce } from "../../../shared/utils/debounce";

function MagentoProductsTable({ text }: { text: string }) {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [search, setSearch] = useState(text);
    const [products, setProducts] = useState<any>([]);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const [readyForApproval, setReadyForApproval] = useState<any>(null);
    const memory = LocalStorage.getAttribute("memory", true);
    const storage = LocalStorage.getAttribute("storage", true);
    const color = LocalStorage.getAttribute("product_color", true);

    const fetchMergedProducts = (pageNumber: number) => {
        ProductService.getAllProductFilter(
            pageNumber,
            "merged",
            text,
            (list: any, currentPage: number, lastPage: number) => {
                setProducts(list);
                setCurrent(currentPage);
                setPagecount(lastPage);
            },
            () => {},
            () => {}
        );
    };

    useEffect(() => {
        fetchMergedProducts(current);
    }, [search]);

    useEffect(()=>{
        namedDebounce(
            () => setSearch(text),
            250,
            "SEARCH_WaitingOnFinanceOrders"
        )
    },[text])

    const handleApprove = (e: any, data: any) => {
        setReadyForApproval(data?.original);
        e.stopPropagation();
        setShowPrompt(true);
        fetchMergedProducts(current);
    };

    const columns = [
        {
            Header: "Product Name",
            accessor: "name",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Vendor Name",
            accessor: "vendor[name]",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "SKU",
            accessor: "sku",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Quantity",
            accessor: "quantity",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Storage",
            accessor: "storage",
            Cell: ({ value }: any) =>
                value
                    ? storage?.find((item) => item?.value === value)?.label ||
                      value
                    : "--",
        },
        {
            Header: "Memory",
            accessor: "memory",
            Cell: ({ value }: any) =>
                value
                    ? memory?.find((item) => item?.value === value)?.label ||
                      value
                    : "--",
        },
        {
            Header: "Color",
            accessor: "color",
            Cell: ({ value }: any) => {
                const productColor = color?.find(
                    (item) => item?.value === value
                )?.label;
                return value ? (
                    // ? color?.find((item) => item?.value === value)?.label
                    <div>
                        {/* <div
                            style={{
                                background: `#${productColor}`,
                                color: `#${productColor}`,
                                border: "1px solid #eee",
                                borderRadius: 100,
                                height: 15,
                                width: 15,
                                display: "inline-block",
                                paddingRight: "4px",
                            }}
                        ></div> */}
                        <span>{value}</span>
                    </div>
                ) : (
                    "--"
                );
            },
        },
        {
            Header: "Vendor Price",
            accessor: "vendorPrice",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Price Exc Tax",
            accessor: "priceExcludingTax",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Price Inc Tax",
            accessor: "priceIncludingTax",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Markup Price",
            accessor: "markupPrice",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Action",
            Cell: ({ row }: any) => (
                <Button onClick={(e: any) => handleApprove(e, row)} primary>
                    Approve
                </Button>
            ),
        },
    ];

    return (
        <div>
            <ReactTable
                selectable
                name=""
                columns={columns}
                data={products}
                getTrProps={({ ...args }: any) => {
                    history.push(
                        generatePath(PRODUCT_DETAIL, {
                            productId: args.original?.id,
                        })
                    );
                }}
            />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        fetchMergedProducts(activePage);
                    }}
                />
            )}
            <PromptModal
                open={showPrompt}
                onClose={() => setShowPrompt(false)}
                onSubmit={() => {
                    console.log(readyForApproval);
                    ProductService.approveVendorProducts(
                        {
                            vendor_product: readyForApproval?.id,
                        },
                        () => {
                            fetchMergedProducts(current);
                        },
                        () => {}
                    );
                }}
                buttons={{ cancel: "No", submit: "Yes" }}
            >
                Do you really want to approve this product?
            </PromptModal>
        </div>
    );
}

MagentoProductsTable.propTypes = {};

export default SearchContainer(MagentoProductsTable);
