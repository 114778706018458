import { CreateReducer } from "../../shared/utils/create_reducer";
import { SET_SEARCH } from "../definitions/searchConstants";
import { REMOVE_TOAST, SET_TOAST } from "../definitions/toastConstants";

interface IToastState {
    text?: string;
}

const initState: IToastState = {
    text: "",
};

interface Action {
    payload: {
        search: string;
    };
}

const searchReducer = CreateReducer(initState, {
    [SET_SEARCH](
        state: IToastState,
        { payload: { search } }: Action
    ): IToastState {
        return {
            ...state,
            text: search,
        };
    },
});

export default searchReducer;
