import React, { useState } from "react";
import "./invoicePurchaseOrder.scss";
import { Button } from "semantic-ui-react";
import { PurchaseOrder } from "./PurchaseOrder";
import { Invoice } from "./Invoice";
import _ from "lodash";

export const InvoicePurchaseOrder = () => {
    const [activeTab, setActiveTab] = useState("purchaseOrder");
    const tabs = [
        {
            name: "purchaseOrder",
            text: "Purchase Order",
            component: <PurchaseOrder />,
        },
        {
            name: "invoice",
            text: "Invoice",
            component: <Invoice />,
        },
    ];
    const activeTabIndex = _.findIndex(tabs, { name: activeTab });
    return (
        <div className="invoice-purchase-order">
            <div className="invoice-purchase-order__header">
                {tabs.map((tab, i) => {
                    return (
                        <Button
                            secondary={activeTab === tab.name}
                            key={i}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {" "}
                            {tab.text}{" "}
                        </Button>
                    );
                })}
            </div>
            <div className="invoice-purchase-order__body">
                {activeTabIndex >= 0 && tabs[activeTabIndex].component}
            </div>
        </div>
    );
};
