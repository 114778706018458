import React, { useEffect } from 'react';
import 'semantic-ui-css/semantic.min.css';
import './styles/_main.scss';
import { Provider } from 'react-redux';
import Loader from "./shared/components/Loader";
import AppRoutes from "./routes";
import {AUTHENTICATED} from "./store/definitions/authConstants";
import {signOutAction} from "./store/actions/authActions";
import {store} from "./store";
import { INVOKE_TOASTER } from './store/definitions/toastConstants';

let accessToken = localStorage.getItem('access_token');
let currentUser:any = JSON.parse(localStorage.getItem('user') || '{}');

if (accessToken && currentUser) {
    store.dispatch({
        type: AUTHENTICATED,
        payload: currentUser
    });
}
else {
    store.dispatch(signOutAction());
}

function App() {
  return (
      <Provider store={store}>
          <div className="App">
              <AppRoutes/>
              <Loader/>
          </div>
      </Provider>
  );
}

export default App;
