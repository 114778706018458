import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Formik,
    FormikTouched,
    FormikErrors,
    Field,
    ErrorMessage,
} from "formik";
import { Form, Button } from "semantic-ui-react";
import "./vendorForm.scss";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { vendorFormValidationSchema } from "./validationSchema";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import ImageUpload from "../../../shared/components/ImageUpload";
import { Dropdown } from "../../../shared/components/Dropdown";
import { getDropdownOptions } from "../../../shared/utils/faker_data";
import { VendorService } from "../../../store/services/Vendor.service";
import { MetaService } from "../../../store/services/MetaService.service";
import {
    CountryMeta,
    ShippingZoneMeta,
    TaxTypeMeta,
} from "../../../store/models/Meta/CountryMeta/countryMeta.model";
import { toTitles } from "../../../shared/utils/StringConvertor";

interface IVendorForm {
    companyName: string;
    pointOfContact: string;
    emailAddress: string;
    phoneNumber: string;
    address: string;
    city: string;
    country: string;
    zipCode: string;
    companyLogo?: File;
    taxType: string;
    taxPercentage: string;
    markupPercentage: string;
    shipmentAddress: string;
    shipmentCity: string;
    shipmentCountry: string;
    shipmentZipCode: string;
    shipmentZones: string;
    sortCode: string;
    bankName: string;
    accountNumber: string;
}

function VendorForm() {
    let history = useHistory();
    const initialValues: IVendorForm = {
        companyName: "",
        pointOfContact: "",
        emailAddress: "",
        phoneNumber: "",
        address: "",
        city: "",
        country: "",
        zipCode: "",
        companyLogo: undefined,
        taxType: "",
        taxPercentage: "",
        markupPercentage: "",
        shipmentAddress: "",
        shipmentCity: "",
        shipmentCountry: "",
        shipmentZipCode: "",
        shipmentZones: "",
        sortCode: "",
        bankName: "",
        accountNumber: "",
    };

    const [countries, setCountries] = useState<any>([]);
    const [shippingZone, setShippingZone] = useState<any>([]);
    const [taxTypes, setTaxType] = useState<any>([]);

    const getShippingZone = (id: number) =>
        MetaService.shippingDeliveryAddress(
            id,
            (shippingZone: ShippingZoneMeta[]) => {
                setShippingZone(
                    shippingZone?.map((zone) => ({
                        value: zone?.id,
                        text: zone?.name,
                        key: zone?.id,
                    }))
                );
            },
            (error: any) => {}
        );

    useEffect(() => {
        MetaService.getCountries(
            (countries: CountryMeta[]) => {
                setCountries(
                    countries?.map((country) => ({
                        value: country?.id,
                        text: toTitles(country?.name || "", true),
                        key: country?.id,
                    }))
                );
            },
            (error: any) => {}
        );

        MetaService.getTaxType(
            (taxType: TaxTypeMeta[]) => {
                setTaxType(
                    taxType?.map((type) => ({
                        value: type?.id,
                        text: type?.name,
                        key: type?.id,
                    }))
                );
            },
            () => {}
        );
    }, []);

    const getErrorMsg = (
        touched: FormikTouched<IVendorForm>,
        error: FormikErrors<IVendorForm>,
        key: keyof IVendorForm
    ) => {
        console.log({ touched, error, key });
        return key ? error[key] : "";
    };

    return (
        <div className="vendor-form">
            <Formik
                initialValues={initialValues}
                validationSchema={vendorFormValidationSchema}
                onSubmit={(values, actions) => {
                    VendorService.createVendor(
                        values,
                        () => {
                            history.goBack();
                        },
                        () => {},
                        () => {}
                    );
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    touched,
                    dirty,
                    setFieldValue,
                    handleBlur,
                    resetForm,
                }) => {
                    return (
                        <Form
                            onSubmit={(vaules) => {
                                const val: any = initialValues;
                                // resetForm(val);
                                handleSubmit(vaules);
                            }}
                        >
                            <Form.Group>
                                <Form.Field>
                                    <label>Company Name</label>
                                    <input
                                        name="companyName"
                                        placeholder="Company Name"
                                        type="text"
                                        value={values.companyName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="companyName">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.companyName}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>Point of Contact</label>
                                    <input
                                        name="pointOfContact"
                                        placeholder="Point of Contact"
                                        type="text"
                                        value={values.pointOfContact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="pointOfContact">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.pointOfContact}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Email Address</label>
                                    <input
                                        name="emailAddress"
                                        placeholder="Email Address"
                                        type="email"
                                        value={values.emailAddress}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="emailAddress">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.emailAddress}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>Phone Number</label>
                                    <input
                                        name="phoneNumber"
                                        placeholder="Phone Number"
                                        type="text"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="phoneNumber">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.phoneNumber}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Address</label>
                                    <input
                                        name="address"
                                        placeholder="Address"
                                        type="text"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="address">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.address}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>City</label>
                                    <input
                                        name="city"
                                        placeholder="City"
                                        type="text"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="city">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.city}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                {/* <Form.Field>
                                    <label>Country</label>
                                    <input
                                        name="country"
                                        placeholder="Country"
                                        type="text"
                                        value={values.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormErrorMessage
                                        message={errors.country}
                                    />
                                </Form.Field> */}
                                <Form.Field>
                                    <label>Country</label>
                                    <Dropdown
                                        placeholder="Select Country"
                                        options={countries}
                                        // defaultValue={
                                        //     initialValues.country
                                        // }
                                        onChange={(_, { value }: any) => {
                                            setFieldValue("country", value);
                                        }}
                                    />
                                    <ErrorMessage name="country">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.country}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>Zip Code</label>
                                    <input
                                        name="zipCode"
                                        placeholder="Zip Code"
                                        type="text"
                                        value={values.zipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="zipCode">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.zipCode}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Company Logo</label>
                                    <ImageUpload
                                        onUpload={([image]: any) => {
                                            console.log({ image });
                                            setFieldValue("companyLogo", image);
                                        }}
                                    />

                                    <ErrorMessage name="companyLogo">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.companyLogo}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <h4>Tax Rules</h4>
                            <Form.Group>
                                <Form.Field>
                                    <label>Tax Type</label>
                                    <Dropdown
                                        placeholder="Select Tax Type"
                                        options={taxTypes}
                                        defaultValue={initialValues.taxType}
                                        onChange={(_, { value }: any) =>
                                            setFieldValue("taxType", value)
                                        }
                                    />
                                    <ErrorMessage name="taxType">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.taxType}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>Tax Percentage (%)</label>
                                    <div className="ui icon input">
                                        <input
                                            name="taxPercentage"
                                            placeholder="Tax Percentage"
                                            type="text"
                                            value={values.taxPercentage}
                                            onChange={handleChange}
                                        />
                                        <i className="percent icon"></i>
                                    </div>
                                    <ErrorMessage name="taxPercentage">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.taxPercentage}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Default markup percentage</label>
                                    <div className="ui icon input">
                                        <input
                                            name="markupPercentage"
                                            placeholder="Markup Percentage"
                                            type="text"
                                            value={values.markupPercentage}
                                            onChange={handleChange}
                                        />
                                        <i className="percent icon"></i>
                                    </div>
                                    <ErrorMessage name="markupPercentage">
                                        {() => (
                                            <FormErrorMessage
                                                message={
                                                    errors.markupPercentage
                                                }
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <h4>Shipment Origin Address</h4>
                            <Form.Group>
                                <Form.Field>
                                    <label>Address</label>
                                    <input
                                        name="shipmentAddress"
                                        placeholder="Address"
                                        type="text"
                                        value={values.shipmentAddress}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="shipmentAddress">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.shipmentAddress}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>City</label>
                                    <input
                                        name="shipmentCity"
                                        placeholder="City"
                                        type="text"
                                        value={values.shipmentCity}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="shipmentCity">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.shipmentCity}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                {/* <Form.Field>
                                    <label>Country</label>
                                    <input
                                        name="shipmentCountry"
                                        placeholder="Country"
                                        type="text"
                                        value={values.shipmentCountry}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <FormErrorMessage
                                        message={errors.shipmentCountry}
                                    />
                                </Form.Field> */}
                                <Form.Field>
                                    <label>Country</label>
                                    <Dropdown
                                        placeholder="Select Country"
                                        options={countries}
                                        // defaultValue={
                                        //     initialValues.shipmentZones
                                        // }
                                        onChange={(_, { value }: any) => {
                                            getShippingZone(value);
                                            setFieldValue(
                                                "shipmentCountry",
                                                value
                                            );
                                        }}
                                    />
                                    <ErrorMessage name="shipmentCountry">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.shipmentCountry}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>Zip Code</label>
                                    <input
                                        name="shipmentZipCode"
                                        placeholder="Zip Code"
                                        type="text"
                                        value={values.shipmentZipCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="shipmentZipCode">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.shipmentZipCode}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <h4>Shipment Rules</h4>
                            <Form.Group>
                                <Form.Field>
                                    <label>Shipment Zones</label>
                                    <Dropdown
                                        placeholder="Select Zones"
                                        options={shippingZone}
                                        // defaultValue={
                                        //     initialValues.shipmentZones
                                        // }
                                        multiple
                                        disabled={!shippingZone?.length}
                                        onChange={(_, { value }: any) =>
                                            setFieldValue(
                                                "shipmentZones",
                                                value
                                            )
                                        }
                                    />
                                    <ErrorMessage name="shipmentZones">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.shipmentZones}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <h4>Bank Account Details</h4>
                            <Form.Group>
                                <Form.Field>
                                    <label>Sort Code</label>
                                    <input
                                        name="sortCode"
                                        placeholder="Sort Code"
                                        type="text"
                                        value={values.sortCode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="sortCode">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.sortCode}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                                <Form.Field>
                                    <label>Bank Name</label>
                                    <input
                                        name="bankName"
                                        placeholder="Bank Name"
                                        type="text"
                                        value={values.bankName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="bankName">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.bankName}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Account Number</label>
                                    <input
                                        name="accountNumber"
                                        placeholder="Account Number"
                                        type="text"
                                        value={values.accountNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="accountNumber">
                                        {() => (
                                            <FormErrorMessage
                                                message={errors.accountNumber}
                                            />
                                        )}
                                    </ErrorMessage>
                                </Form.Field>
                            </Form.Group>
                            <Button
                                primary
                                className="float-right"
                                type="submit"
                                // disabled={!isValid || !dirty}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                            <Button
                                className="float-right mr-2"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                {" "}
                                Cancel
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

VendorForm.propTypes = {};

export default withRouter(VendorForm);
