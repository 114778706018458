import React, { FC } from "react";
import LoaderGIF from "../../../assets/loader/spinLoader.gif";
import LoaderContainer from "../../../store/containers/loaderContainer";
import "./loader.scss";

interface LoaderProps {
    loader: number;
}

const Loader: FC<LoaderProps> = ({ loader }) => {
    if (!loader) {
        return null;
    }
    return (
        <div className="loader-container">
            <img src={LoaderGIF} alt="" />
        </div>
    );
};

export default LoaderContainer(Loader);
