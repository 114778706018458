import { FinanceOrder, FinanceOrderDetail } from "../../../store/models/Finance/Order.model";
import { OrderService } from "../../../store/services/OrderService/Order.service";

export type FinanceStatus =
    | "waiting_on_lyca_finance"
    | "processing"
    | "accepted"
    | "shipped"
    | "delivered"
    | "rejected_by_lyca_finance"
    | "rejected_by_vendor"
    | "cancelled_by_lyca"
    | "returned";

export const fetchOrderService = ({
    page,
    type,
    orderNo,
}: {
    page: number;
    type?: FinanceStatus | "processed" |"completed"| "others";
    orderNo?: string;
}) =>
    new Promise((resolve, reject) => {
        OrderService.getFinanceOrders(
            (
                orders: FinanceOrderDetail[],
                currentPage: number,
                pageCount: number
            ) => {
                resolve({
                    orders,
                    currentPage,
                    pageCount,
                });
            },
            (error: Error) => {
                reject(error);
            },
            () => {
                resolve("");
            },
            page,
            type,
            orderNo
        );
    });
