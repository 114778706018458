import React from "react";
import { CompanyStats } from "../index";
import "./card.scss";

const Card = ({ stats }: { stats: CompanyStats }) => {
    const {
        title,
        value,
        stats: { growth, amount, description },
        img,
    } = stats;
    const titleForCurrency = [
        "revenue",
        "lyca profit",
        "total"
    ]
    return (
        <div className="card-container">
            <div className="top">
                <div className="left">
                    <p className="title">{title}</p>
                    <p className={`value ${titleForCurrency.includes(title.toLocaleLowerCase())?"currency-pound":""}`}>{value}</p>
                </div>
                <div className="right">
                    <img src={img} />
                </div>
            </div>
            <div className="bottom">
                {amount ? (
                    <span className="percentage">
                        {growth ? "+" : "-"} {amount}{"% "}
                    </span>
                ) : null}
                <span className="description">{description}</span>
            </div>
        </div>
    );
};

export default Card;
