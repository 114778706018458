import React from "react";
import { Button } from "semantic-ui-react";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import "./reactTable.scss";

export const ActionButton = ({
    merge,
    value,
    row,
    edit,
    noEdit,
    setEdit,
    makeCall = false,
}: any) => {
    return (
        <div className="react-table__actions">
            {merge ? (
                <Button
                    secondary
                    onClick={(e) => {
                        merge(row?.original);
                        // e.stopPropagation();
                        // setActiveQuoteId(value);
                        // setConfirmModalOpen(true);
                    }}
                >
                    Merge
                </Button>
            ) : null}
            {noEdit ? null : edit !== row.id ? (
                <div>
                    <span
                        className="react-table__icon"
                        onClick={() => {
                            setEdit(row.id);
                        }}
                    >
                        <i className="ui icon pencil alternate"></i>
                    </span>
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <span
                        className="react-table__icon"
                        onClick={() => {
                            if (makeCall) {
                                ProductService?.updateBrandMarkup(
                                    {
                                        markupPercentage:
                                            row?.original?.markupPercentage,
                                        productId: row?.original?.id,
                                    },
                                    () => {
                                        setEdit(undefined);
                                    },
                                    () => {
                                        setEdit(undefined);
                                    }
                                );
                                return;
                            }
                            setEdit(undefined);
                        }}
                    >
                        <i className="ui icon check"></i>
                    </span>
                    <span
                        className="react-table__icon"
                        onClick={() => setEdit(undefined)}
                    >
                        <i className="ui icon close icon"></i>
                    </span>
                </div>
            )}
        </div>
    );
};
