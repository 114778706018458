import React from 'react';
import PropTypes from 'prop-types';
import VendorForm from "../VendorForm";
import './createVendor.scss';
import {Icon} from "semantic-ui-react";
import PageTitle from "../../../shared/components/PageTitle";

function CreateVendor() {
    return (
        <div className='create-vendor'>
            <PageTitle title='New Vendor' />
            <div className='create-header__vendor-form-wrapper'>
                <VendorForm/>
            </div>
        </div>
    );
}

CreateVendor.propTypes = {};

export default CreateVendor;
