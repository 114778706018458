import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as routes from '../../routes/route-constants/app-routes';
import LoginForm from "./LoginForm";
import CreatePassword from "./CreatePassword";
import ResetPassword from "./ResetPassword";

function AuthRoutes() {
    return (
        <div>
            <Switch>
                <Redirect exact strict from={routes.AUTH} to={routes.LOGIN} />
                <Route exact path={routes.LOGIN} component={LoginForm} />
                <Route exact path={routes.CREATE_NEW_PASSWORD} component={CreatePassword} />
                <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
            </Switch>
        </div>
    );
}

AuthRoutes.propTypes = {};

export default AuthRoutes;

