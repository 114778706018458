import React, { useEffect, useState } from "react";
import { Link, useHistory, generatePath } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import {
    ADD_TICKET,
    RMA_FORM,
    TICKET_DETAILS,
    RMA_DETAILS,
} from "../../../routes/route-constants/app-routes";
import ReactTable from "../../../shared/components/ReactTable";
import { TicketService } from "../../../store/services/TicketService/ticket.service";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import SearchContainer from "../../../store/containers/searchContainer";
import "./rma.scss";
import { RMAService } from "../../../store/services/RMAService/rma.service";
import { namedDebounce } from "../../../shared/utils/debounce";

const isFieldEmpty = (value: any) => {
    return value || "--"
}

function TicketList({ text }: { text: string }) {
    const [tickets, setTickets] = useState<any>([]);
    const [search, setSearch] = useState(text);
    const rma = useRMA();

    console.log({rma});

    const handleTicket = () => {
        TicketService.getAllTicket(
            { search: text },
            (tickets: Ticket[]) => {
                setTickets(tickets);
            },
            () => { }
        );
    };

    useEffect(()=>{
        namedDebounce(
            () => setSearch(text),
            250,
            "SEARCH_WaitingOnFinanceOrders"
        )
    },[text])

    useEffect(() => {
        handleTicket();
    }, [search]);

    console.log({ text });
    const history = useHistory();
    const columns: any = [
        {
            Header: "RMA Number",
            accessor: "id",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Maintenance Ticket Id",
            accessor: "maintenanceTicketId",
            Cell: ({ value }: any) => isFieldEmpty(value)
        },
        {
            Header: "Return Type",
            accessor: "returnType",
            Cell: ({ value }: any) => isFieldEmpty(value)

        },
        {
            Header: "Customer Approval",
            accessor: "isCustomerApproved",
            Cell: ({value}:any)=> String(Boolean(value))
        }
    ];

    return (
        <div className="ticket-list">
            {/* <Link to={RMA_FORM}>
                <Button className="float-right mb-6" secondary>
                    <Icon name="plus" />
                    New RMA{" "}
                </Button>
            </Link> */}
            <ReactTable
                headerLeft
                name=""
                columns={columns}
                data={rma}
                getTrProps={(e: any) => {
                    history.push(
                        generatePath(RMA_DETAILS, { id: e?.original?.id })
                    );
                }}
            />
        </div>
    );
}

TicketList.propTypes = {};

export default SearchContainer(TicketList);


const useRMA = () => {
    const [rma, setRma] = useState([]);

    useEffect(() => {
        RMAService.fetchAllRma((rma: any) => {
            setRma(rma);
        });
    }, []);
    return rma;
}