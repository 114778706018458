import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./appHeader.scss";
import AuthContainer from "../../../store/containers/authContainer";
import { Input, Icon } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
// import NetherLand from "../../images/Image_1.png";
import { MetaService } from "../../../store/services/MetaService.service";
import { CountryMeta } from "../../../store/models/Meta/CountryMeta/countryMeta.model";
import LocalStorage from "../../utils/LocalStorage";
import {
    ATTRIBUTE_TYPES,
    ATTRIBUTE_TYPES_ARRAY,
} from "../../../enums/attributeTypes";
import { AttributeService } from "../../../store/services/MetaService/Attribute.service";
import { useLocation, useHistory } from "react-router-dom";
import { debouce } from "../../utils/debounce";
import SearchContainer from "../../../store/containers/searchContainer";
import { CountriesEnum } from "../../../enums/countriesEnum";
import {
    BASE_URL,
    PRODUCT_LIST_URL,
} from "../../../routes/route-constants/api-routes";
import {
    BASE_NAME,
    DASHBOARD,
    PRODUCTS,
} from "../../../routes/route-constants/app-routes";
import { UserService } from "../../../store/services/UserService/User.service";
import { User } from "../../../store/models/User/user.model";
import { toTitles } from "../../utils/StringConvertor";
import { setSearch } from "../../../store/actions/searchAction";

function updateTitle(pathname: string) {
    switch (pathname) {
        case "/":
            return "Dashboard";
        case "/vendors":
            return "All Vendors";
        case "/products":
            return "All Products";
        case "/orders":
            return "All Orders";
        case "/tickets":
            return "All Tickets";
        case "/invoice":
            return "All Invoices";
        case "/user":
            return "All Users";
        default:
            return "";
    }
}

function AppHeader(props: any) {
    const location = useLocation<any>();
    let search = "";
    useEffect(() => {
        if (location.state?.search != undefined) {
            props?.setSearch(location.state?.search);
            search = location.state?.search;
        } else {
            search = props?.text;
        }
    }, []);

    const { authenticated } = props;
    const [country, setCountry] = useState<any>(null);
    const [countries, setCountries] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<User>();

    const history = useHistory();
    
    const handleUpdateStore = props?.setSearch

    useEffect(() => {
        if (location?.pathname != PRODUCTS) {
            handleUpdateStore("");
        }
    }, [location.pathname]);

    useEffect(()=>{
        if (props?.text?.length && location?.pathname == DASHBOARD) {
            history.push(PRODUCTS);
        }
    }, [props.text])


    const fetchAttributes = () => {
        if (!LocalStorage.isSet("attributes", true)) {
            ATTRIBUTE_TYPES_ARRAY.forEach((key: ATTRIBUTE_TYPES) => {
                AttributeService.getAttributes(key);
            });
        }
    };

    type countries =
        | "uk"
        | "germany"
        | "netherlands"
        | "belgium"
        | "switzerland"
        | "denmark"
        | "france"
        | "italy";

    const countryFlag: { [x:string]: string } = {
        uk: "united-kingdom.png",
        germany: "germany.png",
        netherlands: "netherlands.png",
        belgium: "belgium.png",
        switzerland: "switzerland.png",
        denmark: "denmark.png",
        france: "france.png",
        italy: "italy.png",
    };

    const getFlag = (country: string) => {
        return BASE_NAME + "/country_flags/" + countryFlag[country];
    };

    useEffect(() => {
        fetchAttributes();
        if (authenticated) {
            setLoading(true);
            MetaService.getCountries(
                (countries: CountryMeta[]) => {
                    if (countries?.length) {
                        setCountry(countries[0].id);
                        localStorage.setItem(
                            "country",
                            countries[0].id?.toString() as string
                        );
                    }
                    setCountries(
                        countries?.map((country) => {
                            return {
                                text: (
                                    <div className="country">
                                        <img
                                            src={
                                                country?.name &&
                                                getFlag(country?.name?.toLocaleLowerCase())
                                            }
                                            alt=""
                                        />
                                        <span>
                                            {country?.name &&
                                                toTitles(country?.name, true)}
                                        </span>
                                    </div>
                                ),
                                value: country?.id,
                                key: country?.id,
                            };
                        })
                    );
                },
                () => {},
                () => {
                    setLoading(false);
                }
            );
        }

        if (authenticated)
            UserService.me(
                (user) => {
                    setUser(user);
                },
                () => {},
                () => {}
            );
    }, [authenticated]);

    const handleChange = (_: any, { value }: any) => {
        setCountry(value);
        localStorage.setItem("country", value);
    };

    if (loading) return null;

    const trigger = (
        <span>{JSON.parse(localStorage.getItem("user") as string)?.name}</span>
    );

    const options = [
        { value: "profile", text: "My Profile" },
        { value: "sign-out", text: "Logout" },
    ];

    const handleAccountOptions = (_: any, { value }: any) => {
        switch (value) {
            case "profile":
                history.push("/profile");
                break;
            case "sign-out":
                localStorage.clear();
                window.location.reload();
                break;
            default:
                break;
        }
    };

    return authenticated ? (
        <div className="app-header">
            <div className="app-header__left">
                {updateTitle(location.pathname)}
                <Input
                    placeholder="Search"
                    icon="search"
                    iconPosition="left"
                    onChange={({ target: { value } }: any) => {
                        handleUpdateStore(value);
                    }}
                    value={props?.text}
                />
            </div>
            <div className="app-header__right">
                <Dropdown
                    defaultValue={country}
                    placeholder="NetherLand"
                    options={countries}
                    onChange={handleChange}
                />
                <div className="profile">
                    <img src={user?.profilePic} alt="" />
                    {/* <span> */}
                    {/* {
                            JSON.parse(localStorage.getItem("user") as string)
                                ?.name
                        } */}
                    {/* </span> */}
                    <Dropdown
                        trigger={trigger}
                        options={options}
                        value={""}
                        className="logout-dropdown"
                        onChange={handleAccountOptions}
                    />
                </div>
                {/* <i
                    className="sign-out icon logout-icon"
                    onClick={() => {
                    }}
                /> */}
                {/* <Dropdown
                    defaultValue="test"
                    placeholder="NetherLand"
                    options={[
                        {
                            text: (
                            ),
                            value: "test",
                            key: "key",
                        },
                    ]}
                /> */}
            </div>
        </div>
    ) : null;
}

AppHeader.propTypes = {};

export default AuthContainer(SearchContainer(AppHeader));
