import { serializable, alias, primitive, object, list } from "serializr";

class Product{
    @serializable(alias("sold", primitive()))
    sold?: number;

    @serializable(alias("master_product_id", primitive()))
    masterProductId?: string | number;

    @serializable(alias("master_product_name", primitive()))
    masterProductName?: string;

    @serializable(alias("master_product_sku", primitive()))
    masterProductSku?: string;
}

export class TopFiveProducts{
    @serializable(alias('data', list(object(Product))))
    products?: Product[];
}