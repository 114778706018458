export const PO_ORDER_STATUS = ["Completed", "Delivered", "Processing"];

export const PO_ORDER_STATUS_COLOR: any = {
    complete: "#2DCE98",
    delivered: "#11CDEF",
    processing: "#FB6340",
    paid: "#11CDEF",
    cancelled: "#F53C56",
    pending: "#FB6340",
};

export const CREDIT_MEMO_STATUS = ["Returned", "Cancelled"];

export const INVOICE_STATUS = [
    "Completed",
    "Paid",
    "Cancelled",
    "Pending",
    "Processing",
];

export const INVOICE_STATUS_COLOR: any = {
    Completed: "#2DCE98",
    Paid: "#11CDEF",
    Cancelled: "#F53C56",
    Pending: "#FB6340",
    Processing: "#FB6340",
};
