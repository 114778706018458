import { deserialize } from "serializr";
import {
    ALL_USERS_URL,
    USERS,
} from "../../../routes/route-constants/api-routes";
import { store } from "../../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { User } from "../../models/User/user.model";
import AppToast from "../../../shared/components/AppToast";
import { INVOKE_TOASTER } from "../../definitions/toastConstants";
import { generatePath } from "react-router";

export class UserService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static toastNotify(err:any, type:string ){
        store.dispatch({
            type: INVOKE_TOASTER,
            payload: {
                type,
                message:
                    err?.response?.data?.message || "Something went wrong",
            },
        });
    }
    static getAllUsers(role: string, onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(ALL_USERS_URL, {
                params: {
                    role,
                },
            })
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getUserById(id: any, onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(`${ALL_USERS_URL}/${id}`)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static createUser(payload: any, onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .post(ALL_USERS_URL, payload)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static updateUser(
        id: any,
        payload: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(`${ALL_USERS_URL}/${id}`, payload)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static me(
        onSuccess: (user: User) => void,
        onError: (err: any) => void,
        onEnd: () => void
    ) {
        this.addLoader();
        return axiosInstance
            .get(USERS.me)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
                onEnd();
            });
    }
    static updateMe(
        payload: any,
        onSuccess: (user: User) => void,
        onError: (err: any) => void,
        onEnd: () => void
    ) {
        this.addLoader();
        return axiosInstance
            .post(USERS.me, payload)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
                onEnd();
            });
    }

    static updatePassword(
        payload: any,
        onSuccess?: (user: User) => void,
        onError?: (err: any) => void,
        onEnd?: () => void
    ) {
        this.addLoader();
        return axiosInstance
            .put(USERS.updatePassword, payload)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess && onSuccess(data);
            })
            .catch((err) => {
                store.dispatch({
                    type: INVOKE_TOASTER,
                    payload: {
                        type: "error",
                        message:
                            err?.response?.data?.message || "Something went wrong",
                    },
                });
                onError && onError(err);
            })
            .finally(() => {
                this.removeLoader();
                onEnd && onEnd();
            });
    }

    static resetPassword(
        id:any,
        payload: any,
        onSuccess?: (user: User) => void,
        onError?: (err: any) => void,
        onEnd?: () => void
    ) {
        this.addLoader();
        return axiosInstance
            .put(generatePath(USERS.resetPassword, {id}), payload)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess && onSuccess(data);
            })
            .catch((err) => {
                store.dispatch({
                    type: INVOKE_TOASTER,
                    payload: {
                        type: "error",
                        message:
                            err?.response?.data?.message || "Something went wrong",
                    },
                });
                onError && onError(err);
            })
            .finally(() => {
                this.removeLoader();
                onEnd && onEnd();
            });
    }


    static deleteUser(
        id:string| number,
        onSuccess?: (user: User) => void,
        onError?: (err: any) => void,
        onEnd?: () => void
    ){
        this.addLoader();
        return axiosInstance
            .delete(`${ALL_USERS_URL}/${id}`)
            .then((response) => {
                const data = deserialize(User, response.data);
                onSuccess && onSuccess(data);
            })
            .catch((err) => {
                store.dispatch({
                    type: INVOKE_TOASTER,
                    payload: {
                        type: "error",
                        message:
                            err?.response?.data?.message || "Something went wrong",
                    },
                });
                onError && onError(err);
            })
            .finally(() => {
                this.removeLoader();
                onEnd && onEnd();
            });
    }

    static forgotPassword(
        payload:any,
        onSuccess?: ()=> void,
        onError?: (err: any) => void,
        onEnd?: () => void
    ){
        this.addLoader();
        return axiosInstance
            .post(`password-reset`,payload )
            .then((response) => {
                const data = response?.data?.message;
                store.dispatch({
                    type: INVOKE_TOASTER,
                    payload: {
                        type: "info",
                        message:
                            data || "Password reset link sent to your email",
                    },
                });
                onSuccess && onSuccess();
            })
            .catch((err) => {
                store.dispatch({
                    type: INVOKE_TOASTER,
                    payload: {
                        type: "error",
                        message:
                            err?.response?.data?.message || "Something went wrong",
                    },
                });
                onError && onError(err);
            })
            .finally(() => {
                this.removeLoader();
                onEnd && onEnd();
            });
    }
}
