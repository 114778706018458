import { serializable, alias, primitive, object, list } from "serializr";

class Revenue{
    @serializable(alias("revenue", primitive()))
    amount?: number;

    @serializable(alias("week", primitive()))
    week?: number;

    @serializable(alias("year", primitive()))
    year?: number;
}

export class RevenueByWeek{
    @serializable(alias('data', list(object(Revenue))))
    revenue?: Revenue[];
}