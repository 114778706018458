import React, { useCallback, useEffect, useState } from "react";
import { PromptModal } from "../../../shared/components/PromptModal";
import { Input, Pagination, Search } from "semantic-ui-react";
import ReactTable from "../../../shared/components/ReactTable";
import { ActionButton } from "../../../shared/components/ReactTable/ActionButton";
import { getProducts } from "../../../shared/utils/faker_data";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import { Product } from "../../../store/models/Vendor/VendorProduct";
import { debounce } from "lodash";
import LocalStorage from "../../../shared/utils/LocalStorage";

interface Props {
    onClose: () => void;
    open: boolean;
    prods: any;
}

// const searchData = useCallback(debounce((text: string) => {

// }, []);

export const ProductMergeModal = ({ onClose, open, prods }: Props) => {
    const [products, setProducts] = useState<any>([]);
    const [vendor, setVendor] = useState<any>(null);
    const [search, setSearch] = useState("");
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);

    const memory = LocalStorage.getAttribute("memory", true);
    const storage = LocalStorage.getAttribute("storage", true);
    const color = LocalStorage.getAttribute("product_color", true);

    const columns = [
        {
            Header: "Lyca SKU",
            accessor: "sku",
            style: { whiteSpace: "unset", maxWidth: "3.5rem" },
        },
        {
            Header: "Product Name",
            accessor: "name",
        },
        {
            Header: "Storage",
            accessor: "storage"
            
        },
        {
            Header: "Memory",
            accessor: "memory",
        },
        {
            Header: "Color",
            accessor: "color",
            // Cell: ({ value }: any) => {
            //     const productColor = color?.find(
            //         (item) => item?.value === value
            //     )?.label;
            //     return value ? (
            //         <div
            //             style={{
            //                 background: `#${productColor}`,
            //                 color: `#${productColor}`,
            //                 border: "1px solid #eee",
            //                 borderRadius: 100,
            //                 height: 15,
            //                 width: 15,
            //             }}
            //         ></div>
            //     ) : (
            //         "--"
            //     );
            // },
        },
        {
            Header: "Action",
            // accessor: "action",
            Cell: ({ row, value }: any) => (
                <ActionButton
                    merge={() => {
                        handleMerge(row?.original);
                    }}
                    row={row}
                    noEdit
                    value={value}
                    // edit={edit}
                    // setEdit={setEdit}
                />
            ),
        },
    ];

    const handleMerge = (data: any) => {
        console.log(vendor);
        const payload = {
            master_product: data?.id,
            vendor_product: vendor?.id,
            markup_price: vendor?.markupPrice,
        };
        ProductService.VendorMergeRequest(
            payload,
            () => {
                onClose();
            },
            () => {
                onClose();
            }
        );
    };

    const getMergeProducts = (page: number, search = "") => {
        ProductService.searchMergeProduct(
            {
                page,
                search,
            },
            (products: Product[], currentPage: number, lastPage: number) => {
                setProducts(products);
                setCurrent(currentPage);
                setPagecount(lastPage);
            },
            () => {}
        );
    };

    useEffect(() => {
        setVendor(prods);
        getMergeProducts(1);
    }, [prods]);

    const searchData = useCallback(
        debounce((text: string) => {
            getMergeProducts(1, text);
        }, 500),
        []
    );

    const handleSearch = ({ target: { value } }: any) => {
        searchData(value);
        setSearch(value);
    };

    return (
        <PromptModal
            className="product-merge-modal"
            onClose={onClose}
            open={open}
            noActions
        >
            <Input
                className="mb-5"
                icon="search"
                iconPosition="left"
                placeholder="search"
                onChange={handleSearch}
                value={search}
            />
            <ReactTable name="" columns={columns} data={products} />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        getMergeProducts(activePage, search);
                    }}
                />
            )}
        </PromptModal>
    );
};
