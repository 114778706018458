import React, { useCallback, useState } from "react";
import "./users.scss";
import _ from "lodash";
import { Button, Icon, Modal } from "semantic-ui-react";
import { UserTable } from "./UserTable";
import { Link, generatePath } from "react-router-dom";
import { ADD_USER } from "../../routes/route-constants/app-routes";
import { CreateUser } from "./CreateUser";

export const Users = () => {
    const [activeTab, setActiveTab] = useState("admin");
    const [showModal, setShowModal] = useState(false);
    const tabs = [
        {
            name: "admin",
            text: "Admin",
            component: (
                <UserTable
                    role="Admin"
                    setShowModall={setShowModal}
                    showModall={showModal}
                />
            ),
        },
        {
            name: "product",
            text: "Product and Pricing Team",
            component: (
                <UserTable
                    role="Product"
                    setShowModall={setShowModal}
                    showModall={showModal}
                />
            ),
        },
        {
            name: "finance",
            text: "Finance Team",
            component: (
                <UserTable
                    role="Finance"
                    setShowModall={setShowModal}
                    showModall={showModal}
                />
            ),
        },
        {
            name: "customerSupport",
            text: "Customer Support Team",
            component: (
                <UserTable
                    role="Support"
                    setShowModall={setShowModal}
                    showModall={showModal}
                />
            ),
        },
    ];
    const activeTabIndex = _.findIndex(tabs, { name: activeTab });
    const onAdd = useCallback((data: any) => {
        console.log(data);
    }, []);

    return (
        <div className="users">
            <div className="users__header">
                {tabs.map((tab, i) => {
                    return (
                        <Button
                            secondary={activeTab === tab.name}
                            key={i}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {" "}
                            {tab.text}{" "}
                        </Button>
                    );
                })}
                <Button
                    className="float-right"
                    secondary
                    onClick={() => setShowModal(true)}
                >
                    <Icon name="plus" />
                    New User
                </Button>
            </div>

            <div className="users__body">
                {activeTabIndex >= 0 && tabs[activeTabIndex].component}
            </div>
            {/* <CreateUser setOpen={setShowModal} open={showModal} onData={onAdd}/> */}
        </div>
    );
};
