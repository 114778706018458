import {
    ORDER_STATUS,
    ORDER_PROCESSING_STATUS,
} from "../Constants/OrderStatus";
import {
    ISSUE_TYPES,
    CS_TEAM_GROUPS,
    ISSUE_STATUS,
} from "../Constants/CustomerSupport";
import {
    PO_ORDER_STATUS,
    INVOICE_STATUS,
    CREDIT_MEMO_STATUS,
} from "../Constants/InvoicePurchaseOrder";
import Cat from "../images/cat.jpeg";

let faker = require("faker");

export function getProducts(n: number): { [key: string]: any }[] {
    let products: {}[] = [];
    for (let i = 0; i < n; i++) {
        let product = {
            productName: faker.commerce.productName(),
            vendorName: faker.company.companyName(),
            sku: faker.random.uuid().slice(0, 12),
            vendorSku: faker.random.uuid().slice(0, 12),
            quantity: faker.random.number() % 100,
            color: faker.commerce.color(),
            storage: `${2 ** ((faker.random.number() % 4) + 4)}GB`,
            memory: `${2 ** ((faker.random.number() % 4) + 1)}GB`,
            totalPrice: `€${faker.commerce.price()}`,
            markupPrice: `${faker.commerce.price()}`,
            deliveryPrice: `€${faker.commerce.price()}`,
            priceExcTax: `€${faker.commerce.price()}`,
            priceIncTax: `€${faker.commerce.price()}`,
            action: faker.random.number(),
        };
        products.push(product);
    }
    return products;
}

export const getBrandMarkup = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            vendorId: faker.random.uuid().slice(0, 12),
            vendorName: faker.company.companyName(),
            markupPercentage: faker.random.number() % 50,
        }));

export const getMasterProducts = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            productName: faker.commerce.productName(),
            sku: faker.random.uuid().slice(0, 12),
            quantity: faker.random.number() % 100,
            price: `€${faker.commerce.price()}`,
            color: faker.commerce.color(),
            storage: `${2 ** ((faker.random.number() % 4) + 4)}GB`,
            memory: `${2 ** ((faker.random.number() % 4) + 1)}GB`,
            inStock: faker.random.number() % 2 == 1,
        }));

export const getMagentoPendingProducts = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            productName: faker.commerce.productName(),
            vendorName: faker.company.companyName(),
            sku: faker.random.uuid().slice(0, 12),
            quantity: faker.random.number() % 100,
            totalPrice: `€${faker.commerce.price()}`,
            markupPrice: `€${faker.commerce.price()}`,
            color: faker.commerce.color(),
            storage: `${2 ** ((faker.random.number() % 4) + 4)}GB`,
            memory: `${2 ** ((faker.random.number() % 4) + 1)}GB`,
        }));

export const getVendors = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            vendorName: faker.company.companyName(),
            vendorId: faker.random.uuid().slice(0, 8),
        }));

export const getOptions = (n: number): { label: string; value: string }[] =>
    Array(n)
        .fill(null)
        .map(() => ({
            label: faker.name.firstName(),
            value: faker.name.firstName(),
        }));

export const getDropdownOptions = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            text: faker.name.firstName(),
            value: faker.name.lastName(),
            key: faker.name.firstName(),
        }));
export const getAllOrders = (n: number, status?: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            orderId: faker.random.uuid().slice(0, 12),
            orderDate: faker.date.recent().toLocaleString().split(",")[0],
            vendorOrderId: faker.random.uuid().slice(0, 12),
            amount: faker.commerce.price(),
            customerName: faker.name.firstName(),
            paymentMethod: faker.name.lastName(),
            region: faker.address.country().slice(0, 15),
            status: status
                ? status
                : faker.random.number() % ORDER_STATUS.length,
        }));

export const getProcessingOrders = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            orderId: faker.random.uuid().slice(0, 12),
            orderDate: faker.date.recent().toLocaleString().split(",")[0],
            vendorOrderId: faker.random.uuid().slice(0, 12),
            amount: faker.commerce.price(),
            customerName: faker.name.firstName(),
            paymentMethod: faker.name.lastName(),
            region: faker.address.country().slice(0, 15),
            status: faker.random.number() % ORDER_PROCESSING_STATUS.length,
        }));

export const getProductDetailsForApproval = (n: number, sku?: string) =>
    Array(n)
        .fill(null)
        .map(() => ({
            lycaSku: sku ? sku : "sdfe3ss33y4",
            vendorSku: faker.random.uuid().slice(0, 16),
            vendorPrice: `€${faker.commerce.price()}`,
            customerPrice: `€${faker.commerce.price()}`,
            markupPrice: `€${faker.commerce.price()}`,
        }));

export const getProductDetails = (n: number, sku?: string) =>
    Array(n)
        .fill(null)
        .map(() => ({
            lycaSku: sku ? sku : "sdfe3ss33y4",
            productName: faker.commerce.productName(),
            quantity: (faker.random.number() % 12) + 1,
            unitRate: `€${faker.commerce.price()}`,
            productTotal: `€${faker.commerce.price()}`,
            taxApplicable: 1 / faker.random.number(),
        }));

export const getCustomers = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            email: faker.internet.email(),
            phone: faker.phone.phoneNumber(),
            region: faker.address.country(),
            address: faker.address.streetAddress(),
            zip: faker.address.zipCode(),
        }));

export const gettickets = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            ticketId: faker.random.uuid().slice(0, 12),
            orderId: faker.random.uuid().slice(0, 12),
            email: faker.internet.email(),
            issueType: ISSUE_TYPES[faker.random.number() % ISSUE_TYPES.length],
            assignedTo:
                faker.random.number() % 3 == 0 ? faker.name.firstName() : "",
            csTeamGroup:
                CS_TEAM_GROUPS[faker.random.number() % CS_TEAM_GROUPS.length],
            dueDate: faker.date.recent().toLocaleDateString(),
            status: faker.random.number() % ISSUE_STATUS.length,
        }));

export const getTicketDetails = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            orderId: faker.random.number(),
            product: faker.commerce.productName(),
            email: faker.internet.email(),
            phone: faker.phone.phoneNumber(),
            issueType: faker.name.firstName(),
            description: faker.commerce.productName(),
            groupAssigned: faker.name.firstName(),
            userAssigned: faker.name.firstName(),
            csTeamGroup: faker.name.lastName(),
            RMA: faker.random.number(),
            issueUpdates: faker.commerce.productAdjective(),
            issueStatus: faker.commerce.productAdjective(),
            priority: faker.random.number(),
            dueDate: faker.date.recent().toLocaleDateString(),
            additionalDetails: faker.commerce.productName(),
            shippingInformation: faker.address.streetAddress(),
            orderStatus: faker.random.number(),
            attachments: [
                {
                    name: `${faker.system.fileName()}.pdf`,
                    link: "/mock/something.pdf",
                },
                {
                    name: `${faker.system.fileName()}.jpg`,
                    link: "/mock/something.jpg",
                },
            ],
        }));

export const getPurchaseOrderList = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            lycaOrderId: faker.random.uuid().slice(0, 12),
            vendorOrderId: faker.random.uuid().slice(0, 12),
            PO: faker.random.uuid().slice(0, 12),
            type: faker.random.number(),
            invoiceGenerated: faker.random.uuid().slice(0, 12),
            markup: `€${faker.commerce.price()}`,
            tax: `€${faker.commerce.price()}`,
            vendorCost: `€${faker.commerce.price()}`,
            status: faker.random.number() % PO_ORDER_STATUS.length,
        }));

export const getInvoiceList = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            invoiceId: faker.random.uuid().slice(0, 12),
            date: faker.date.recent().toLocaleDateString(),
            type: faker.random.number() % 2 == 0 ? "Regular" : "Credit memo",
            orderCount: (faker.random.number() % 30) + 1,
            tax: `€${faker.commerce.price()}`,
            total: `€${faker.commerce.price()}`,
            status: faker.random.number() % INVOICE_STATUS.length,
        }));

export const getUsers = (n: number) =>
    Array(n)
        .fill(null)
        .map(() => ({
            user: {
                id: faker.random.uuid().slice(0, 12),
                name: faker.name.firstName(),
                profile: Cat,
            },
            email: faker.internet.email(),
            phone: faker.phone.phoneNumber(),
            country: faker.address.country(),
        }));

export const getInvoicableOrders = (
    n: number,
    creditMemo?: boolean
): { [x: string]: string }[] =>
    Array(n)
        .fill(null)
        .map(() => ({
            lycaOrderId: `${faker.random.uuid().slice(0, 12)}`,
            vendorOrderId: `${faker.random.uuid().slice(0, 12)}`,
            PO: `${faker.random.uuid().slice(0, 12)}`,
            markup: `€${faker.commerce.price()}`,
            tax: `€${faker.commerce.price()}`,
            vendorCost: `€${faker.commerce.price()}`,
            status: `${faker.random.number() % PO_ORDER_STATUS.length}`,
        }));
