import { deserialize } from "serializr";
import {
    FINANCE_ORDERS,
    REJECT_FINANCE_ORDER,
    APPROVAL_FINANCE_ORDER,
    UPDATE_ORDER_STATUS,
    VENDOR_API_ORDERS,
    VENDOR_API_ORDER_UPDATE,
} from "../../../routes/route-constants/api-routes";
import { store } from "../../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import axiosInstance from "../../interceptors/axiosInterceptor";
import {
    FinanceOrder,
    FinanceOrderDetail,
    VendorOrder,
} from "../../models/Finance/Order.model";
import { VENDOR_ORDER_STATUS } from "../../../enums/VendorOrderStatus";
import { generatePath } from "react-router";
import { FinanceStatus } from "../../../views/Orders/OrderService/service";



export class OrderService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static getFinanceOrders(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void,
        page: number,
        status?: FinanceStatus |"completed"| "processed" | "others",
        search?: string
    ) {
        const country_id = localStorage.getItem("country");

        const user = JSON.parse(localStorage.getItem("user") as string);
        const role = user?.userRoles?.map((user: any) => user?.role);

        let modal = FinanceOrderDetail;
        let url = FINANCE_ORDERS;

        if (role.includes("Vendor")) {
            url = VENDOR_API_ORDERS;
            modal = VendorOrder;
        }

        const options: {
            country_id: string | null;
            page: number;
            status?: FinanceStatus | "completed" | "others" | "processed";
            lyca_order_no?: string;
        } = {
            country_id,
            page,
        };
        if (status) {
            options.status = status;
        }
        if (search) {
            options.lyca_order_no = search;
        }
        this.addLoader();
        return axiosInstance
            .get(url, {
                params: options,
            })
            .then((response) => {
                const orders = deserialize(modal, response.data?.data);
                onSuccess(
                    orders,
                    response.data?.current_page,
                    response.data?.last_page
                );
            })
            .catch((error) => {
                console.log(error.message);
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
                onFinal();
            });
    }

    static getDetailOrder(
        orderId: number,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .get(`${FINANCE_ORDERS}/${orderId}`)
            .then((response) => {
                const orderDetail = deserialize(
                    FinanceOrderDetail,
                    response.data
                );

                console.log(orderDetail);
                onSuccess(orderDetail);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static rejectFinanceOrder(
        id: number,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(REJECT_FINANCE_ORDER(id))
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static approveFinanceOrder(
        id: number,
        payload: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(APPROVAL_FINANCE_ORDER(id), payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static vendorUpdateOrder(
        status: VENDOR_ORDER_STATUS,
        po: string,
        onSuccess: Function,
        onError: Function,
        comment: string | null = null
    ) {
        this.addLoader();
        const payload = {
            status,
            comment,
        };
        return axiosInstance
            .put(generatePath(VENDOR_API_ORDER_UPDATE, { po }), payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => onError())
            .finally(() => this.removeLoader());
    }

    static updateOrderStatus(
        orderId: any,
        payload: { status: string },
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(UPDATE_ORDER_STATUS(orderId), payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }
}
