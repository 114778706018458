import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./orderDetail.scss";
import PageTitle from "../../../shared/components/PageTitle";
import { Button, Icon } from "semantic-ui-react";
import { RadioTable } from "../../../shared/components/RadioTable";
import {
    getProductDetailsForApproval,
    getProductDetails,
    getCustomers,
} from "../../../shared/utils/faker_data";
import { RouteComponentProps, withRouter } from "react-router";
import { OrderService } from "../../../store/services/OrderService/Order.service";
import { FinanceOrderDetail } from "../../../store/models/Finance/Order.model";
import { MetaService } from "../../../store/services/MetaService.service";
import {
    snakecaseToTitleCase,
    toTitles,
} from "../../../shared/utils/StringConvertor";
import { Dropdown } from "../../../shared/components/Dropdown";

const productCount = (Math.round(Math.random() * 10) % 2) + 1;
const cols: { name: string; text: string }[] = [
    {
        name: "lycaSku",
        text: "Product SKU (Lyca)",
    },
    {
        name: "vendorSku",
        text: "Product SKU(vendor)",
    },
    {
        name:"vendorName",
        text:"Vendor Name"
    },
    {
        name: "vendorPrice",
        text: "Vendor Price",
    },
    {
        name: "customerPrice",
        text: "Customer price",
    },
    {
        name: "markupPrice",
        text: "Markup Price",
    },
];

const productDetailsTableCols: { name: string; text: string }[] = [
    {
        name: "lycaSku",
        text: "Product SKU",
    },
    {
        name: "productName",
        text: "Name & Variant",
    },
    {
        name: "vendorName",
        text: "vendor Name",
    },

    {
        name: "quantity",
        text: "Quantity",
    },
    {
        name: "unitRate",
        text: "Unit Rate",
    },
    {
        name: "taxApplicable",
        text: "Tax Applicable",
    },
    {
        name: "productTotal",
        text: "Product Total",
    },
];

const getProducts = (n: number) =>
    Array(n)
        .fill(null)
        .map(() =>
            getProductDetailsForApproval(
                3,
                "SKU-" + Math.round(Math.random() * 100000)
            )
        );

type keys =
    | "lycaOrderId"
    | "orderDate"
    | "subTotal"
    | "shipmentCharges"
    | "orderAmount"
    | "paymentMethod"
    | "paymentStatus"
    | "orderStatus"
    | "productDetails";
const keys: { key: keys; name: string }[] = [
    {
        key: "lycaOrderId",
        name: "Lyca Order No",
    },
    {
        key: "orderDate",
        name: "Order Date",
    },
    {
        key: "productDetails",
        name: "Product Details",
    },
    {
        key: "subTotal",
        name: "Sub Total",
    },
    {
        key: "shipmentCharges",
        name: "Shipment Charges",
    },
    {
        key: "orderAmount",
        name: "Order Amount",
    },
    {
        key: "paymentMethod",
        name: "Payment Method",
    },
    {
        key: "paymentStatus",
        name: "Payment Status",
    },
    {
        key: "orderStatus",
        name: "Order Status",
    },
];

interface Props extends RouteComponentProps {}

function OrderDetail({ location, match, history }: Props) {
    const params: any = match.params;
    const state: any = location.state;
    const [length, setLength] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orderStatus, setOrderStatus] = useState<Array<string>>([]);
    const [selected, setSelected] = useState<any>({});
    const [data, setData] = useState<any>([]);
    const [orderDetail, setOrderDetail] = useState<any>({
        ycaOrderId: "",
        orderDate: new Date().toLocaleDateString(),
        productDetails: {
            cols: [],
            rows: [],
        },
        subTotal: "",
        shipmentCharges: "",
        orderAmount: "",
        paymentMethod: "",
        paymentStatus: "",
        orderStatus: "",
    });

    const [customerDetail, setCutomerDetail] = useState<any>({
        keys: [
            {
                key: "email",
                name: "Customer Email",
            },
            {
                key: "phone",
                name: "Phone",
            },
            {
                key: "region",
                name: "Region of shipment",
            },
            {
                key: "address",
                name: "Address",
            },
            {
                key: "zip",
                name: "Zip Code",
            },
        ],
        details: [
            {
                email: "",
                phone: "",
                region: "",
                address: "",
                zip: "",
            },
        ],
    });

    useEffect(() => {
        const params: any = match.params;
        OrderService.getDetailOrder(
            params.orderId,
            (orderDetail: any) => {
                setOrderDetail({
                    lycaOrderId: orderDetail.orderNo,
                    orderDate: new Date(
                        orderDetail?.orderDate as string
                    ).toLocaleDateString(),
                    productDetails: {
                        cols: productDetailsTableCols,
                        rows: orderDetail?.orderItem?.map((orderItem: any) => {
                            const vendorId = orderItem?.vendorProductId;
                            const vendorName = vendorId
                                ? orderItem?.masterProduct?.vendorProducts?.find(
                                      (vendorProduct: any) => {
                                          return vendorProduct?.id === vendorId;
                                      }
                                  )?.vendor?.name
                                : "--";

                            return {
                                lycaSku: orderItem?.masterProduct?.sku,
                                vendorName,
                                productName: orderItem?.masterProduct?.name,
                                quantity: orderItem?.quantity,
                                unitRate: orderItem?.unitRate,
                                productTotal:
                                    (orderItem?.unitRate as number) *
                                    orderItem?.quantity,
                                taxApplicable: orderItem?.taxApplicable,
                            };
                        }),
                    },
                    subTotal: `€${orderDetail?.subTotal}`,
                    shipmentCharges: `€${orderDetail?.shipmentCharges || 0}`,
                    orderAmount: `€${orderDetail?.amount}`,
                    paymentMethod: orderDetail?.paymentMethodName,
                    paymentStatus: orderDetail?.paymentStatus,
                    orderStatus: orderDetail?.orderStatus,
                });
                setCutomerDetail((existing: any) => ({
                    ...existing,
                    details: {
                        email: orderDetail?.customeremail || "--",
                        phone: orderDetail?.customerPhoneNo || "--",
                        region: orderDetail?.shipmentRegion?.name || "--",
                        address: orderDetail?.address || "--",
                        zip: orderDetail?.zipCode || "--",
                    },
                }));
            },
            () => {}
        );
    }, []);

    useEffect(() => {
        const params: any = match.params;
        OrderService.getDetailOrder(
            params.orderId,
            (orderDetail: FinanceOrderDetail) => {
                const data: any = [];
                orderDetail?.orderItem?.forEach((orderItem, index) => {
                    setLength(index);
                    data.push(
                        orderItem.masterProduct?.vendorProducts?.map(
                            (vendorProduct) => {
                                return {
                                    index,
                                    lycaSku: orderItem.masterProduct?.sku || "--",
                                    vendorSku: vendorProduct?.sku || "--",
                                    vendorPrice: vendorProduct?.vendorPrice || "--",
                                    customerPrice:
                                        orderItem.masterProduct?.price || "--",
                                    markupPrice: vendorProduct?.markupPrice || "--",
                                    order_item: orderItem.id || "--",
                                    vendor_product: vendorProduct.id || "--",
                                    vendorName: vendorProduct?.vendor?.name || "--"
                                };
                            }
                        )
                    );
                });
                setData(data);
            },
            () => {}
        );
        setLoading(true);
        MetaService.getOrderStaus(
            (orderStatus: string[]) => {
                setOrderStatus(orderStatus);
                setLoading(false);
            },
            () => {
                setLoading(false);
            }
        );
    }, []);

    const handleSelected = (data: any, index: number) => {
        setSelected((existing: any) => ({
            ...existing,
            [index]: {
                order_item: data?.order_item,
                vendor_product: data?.vendor_product,
            },
        }));
    };

    const handleApproveVendor = () => {
        const params: any = match.params;
        OrderService.approveFinanceOrder(
            params.orderId,
            Object.values(selected),
            () => {
                history.goBack();
            },
            () => {}
        );
    };

    return (
        <div className="order-detail">
            <div className="order-detail__order-card">
                <PageTitle title="Order Details" />
                {state && state.pendingApproval ? (
                    <>
                        <Button
                            primary
                            className="float-right mt-4 mb-4"
                            disabled={
                                length !== Object.values(selected).length - 1
                            }
                            onClick={handleApproveVendor}
                        >
                            <Icon name="check" />
                            Approve Vendor
                        </Button>
                        {data.map((rows: any, index: number) => (
                            <RadioTable
                                className="mb-6"
                                rows={rows}
                                cols={cols}
                                onChange={(data: any) =>
                                    handleSelected(data, index)
                                }
                            />
                        ))}
                        <PageTitle noAction title="Order Details" />
                    </>
                ) : null}
                <table className="order-detail__table">
                    {keys.map((key) => {
                        if (loading) {
                            return null;
                        }

                        return (
                            <tr className="order-detail__table-row">
                                <td className="order-detail__table-left">
                                    {key.name}
                                </td>
                                <td className="order-detail__table-right">
                                    {orderStatus?.includes(
                                        orderDetail[key.key]
                                    ) ? (
                                        <Dropdown
                                            val={orderDetail[key.key]}
                                            options={orderStatus.map(
                                                (status) => {
                                                    const convertedStatus = snakecaseToTitleCase(
                                                        status
                                                    );
                                                    return {
                                                        key: status,
                                                        value: status,
                                                        text: convertedStatus,
                                                        // disabled:
                                                        //     status ===
                                                        //     "processing",
                                                    };
                                                }
                                            )}
                                            onChange={(_, { value }: any) => {
                                                OrderService.updateOrderStatus(
                                                    params?.orderId,
                                                    {
                                                        status: value,
                                                    },
                                                    () => {},
                                                    () => {}
                                                );
                                            }}
                                        />
                                    ) : typeof orderDetail[key.key] ==
                                          "string" ||
                                      typeof orderDetail[key.key] ===
                                          undefined ||
                                      typeof orderDetail[key.key] === null ? (
                                        orderDetail[key.key] || "--"
                                    ) : (
                                        <RadioTable
                                            className="noradio"
                                            noRadio
                                            rows={orderDetail[key.key]?.rows}
                                            cols={orderDetail[key.key]?.cols}
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </div>
            <div className="order-detail__customer-card">
                <PageTitle noAction title="Customer Details" />
                <table className="order-detail__table">
                    {customerDetail.keys.map((key: any) => {
                        return (
                            <tr className="order-detail__table-row">
                                <td className="order-detail__table-left">
                                    {key.name}
                                </td>
                                <td className="order-detail__table-right">
                                    {customerDetail.details[key.key] || "--"}
                                </td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
}

OrderDetail.propTypes = {};

export default withRouter(OrderDetail);
