import * as yup from "yup";

// export const productFormValidationSchema = yup.object({
//     attributeSet: yup.string().required("Attribute set is required"),
//     name: yup.string().required("Product name is required"),
//     sku: yup.string().required("SKU is required"),
//     productType: yup.string().required("Product type is required"),
//     color: yup.string().required("Color is required"),
//     variant: yup.string().required("Variant is required"),
//     width: yup.string().required("Width is required"),
//     length: yup.string().required("Length is required"),
//     weight: yup.string().required("Weight is required"),
//     height: yup.string().required("Height is required"),
//     country: yup.string().required("Country is required"),
//     taxClass: yup.string().required("Tax class is required"),
// });

export const productFormValidationSchema = yup.object({
    vendorSku: yup.string().label("Vendor SKU").required().min(3).max(191),
    vendorId: yup.string().label("Vendor Id").required().max(191),
    name: yup.string().label("Product name").required().min(3).max(191),
    category: yup.string().label("Product category").required().max(62),
    description: yup.string().label("Product Description").required().min(3).max(65000),
    brandId: yup.string().label("Brand").required().max(62),
    markupPrice: yup.number().label("Markup price").typeError("Invalid Price").required().min(0),
    vendorPrice: yup.number().label("Vendor price").typeError("Invalid Price").required().min(0).max(99999999),
    deliveryCost: yup.number().label("Delivery cost").typeError("Invalid Price").required().min(0),
    quantity: yup
        .number()
        .label('Quantity')
        .integer()
        .typeError("Invalid count")
        .required()
        .min(0),
    refurbishedStatus: yup.string().label('Refurbished status').required().max(62),
    storage: yup.string().label('Storage').when("category", {
        is: "phone",
        then: yup.string().required().max(62),
    }),
    memory: yup.string().label('Memory').when("category", {
        is: "phone",
        then: yup.string().required().max(62),
    }),
    color: yup.string().label('Color').required().max(62),
    miscellaneousInfo: yup.string().label('Miscellaneous info').max(128),
    priceExcludingTax: yup
        .number()
        .label('Price without tax')
        .typeError("Invalid Price")
        .required()
        .min(0),
    priceIncludingTax: yup
        .number()
        .label('Price with tax')
        .typeError("Invalid Price")
        .required()
        .min(0),
});
