import React, { useState, useEffect } from "react";
import { getAllOrders } from "../../../shared/utils/faker_data";
import ReactTable from "../../../shared/components/ReactTable";
import { Icon, Pagination } from "semantic-ui-react";
import {
    ORDER_STATUS_COLORS,
    ORDER_STATUS,
    INCOMING_STATUS,
} from "../../../shared/Constants/OrderStatus";
import { useHistory, generatePath } from "react-router";
import { ORDER_DETAIL } from "../../../routes/route-constants/app-routes";
import { fetchOrderService } from "../OrderService/service";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { namedDebounce } from "../../../shared/utils/debounce";
import { getPoNumbers } from "../../../shared/utils/StringConvertor";

interface Props {
    type: "processed" | "completed" | "others";
    text: string;
}

export const OtherOrders = SearchContainer(({ type, text }: Props) => {
    const [search, setSearch] = useState(text)
    const [data, setData] = useState<{ [key: string]: any }[]>([]);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);


    useEffect(()=>{
        namedDebounce(
            () => setSearch(text),
            250,
            "SEARCH_WaitingOnFinanceOrders"
        )
    },[text])

    useEffect(() => {
        (async () => {
            try {
                const {
                    orders,
                    currentPage,
                    pageCount,
                }: any = await fetchOrderService({
                    page: 1,
                    type:
                        type,
                    orderNo: text,
                });
                setData(orders);
                setCurrent(currentPage);
                setPagecount(pageCount);
            } catch (error) {}
        })();
    }, [search, text, type]);

    const history = useHistory();
    const columns = [
        // {
        //     Header: "Lyca Order No",
        //     accessor: "id",
        //     Cell: ({ value }: any) => isFieldEmpty(value),
        // },
        {
            Header: "Magento Order No",
            accessor: "orderNo",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Order Date",
            accessor: "orderDate",
            Cell: ({ value }: any) => {
                const date = new Date(value);
                return `${date.getDate()}/${
                    date.getMonth() + 1
                }/${date.getFullYear()}`;
            },
        },
        {
            Header: "Vendor Order No",
            accessor: "orderItem",
            Cell: ({ value }: any) => isFieldEmpty(getPoNumbers(value)),
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ value }: any) => <span>€{value}</span>,
        },
        {
            Header: "Customer Name",
            accessor: "customerName",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Pmt method",
            accessor: "paymentMethodName",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Region",
            accessor: "shipmentRegion[name]",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },

        {
            Header: "Status",
            accessor: "orderStatus",
            Cell: ({ value }: any) => (
                <>
                    <span
                        className="status-color"
                        style={{
                            background:
                                ORDER_STATUS_COLORS[INCOMING_STATUS[value]],
                        }}
                    ></span>
                    <span>{isFieldEmpty(value)}</span>
                </>
            ),
        },
        {
            Header: "Action",
            Cell: ({ row }: any) => (
                <span
                    className="all-orders__action-button cursor-pointer"
                    onClick={() => {
                        history.push({
                            pathname: generatePath(ORDER_DETAIL, {
                                orderId: row.original?.id,
                            }),
                        });
                    }}
                >
                    <Icon name="eye" />
                </span>
            ),
        },
    ];

    console.log(data);
    return (
        <div className="all-orders__table">
            <ReactTable headerLeft name="" columns={columns} data={data} />
            {pageCount === 1 || pageCount === null ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={async (_, { activePage }: any) => {
                        try {
                            const {
                                orders,
                                currentPage,
                                pageCount,
                            }: any = await fetchOrderService(activePage);
                            setData(orders);
                            setCurrent(currentPage);
                            setPagecount(pageCount);
                        } catch (error) {}
                    }}
                />
            )}
        </div>
    );
});
