import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { UserService } from "../../../store/services/UserService/User.service";
import BackIcon from "../../../assets/icons/chevron-right.svg";
import "./userDetails.scss";

const keys = {
    Name: "userName",
    Email: "email",
    "phone Number": "phoneNo",
    Role: "roles",
    Country: "countryName",
};

const UserDetails = () => {
    const params: any = useParams();
    const history = useHistory();
    const [user, setUser] = useState<any>(null);

    useEffect(() => {
        UserService.getUserById(
            params?.id,
            (data: any) => {
                console.log(data);
                setUser({
                    ...data,
                    roles: data?.roles?.join(", "),
                    countryName: data?.countryName?.join(", "),
                });
            },
            (error: Error) => {
                console.log(error?.message);
            }
        );
    }, []);

    return (
        <div className="user-details__container">
            <div className="title-head">
                <img src={BackIcon} alt="" onClick={() => history.goBack()} />
                <p className="title">User Details</p>
            </div>
            <div className="line"></div>
            {user ? (
                <div className="content_wrapper">
                    {Object.entries(keys).map((data) => (
                        <div className="content">
                            <p className="head">{data[0]}</p>
                            <p>{user[data[1] || "--"]}</p>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default UserDetails;
