import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageTitle from "../../../shared/components/PageTitle";
import { Button, Icon } from "semantic-ui-react";
import { RadioTable } from "../../../shared/components/RadioTable";
import {
    getProductDetailsForApproval,
    getProductDetails,
    getCustomers,
} from "../../../shared/utils/faker_data";
import { RouteComponentProps, withRouter } from "react-router";
import "./invoiceSummary.scss";
import { Dropdown } from "../../../shared/components/Dropdown";
import { PromptModal } from "../../../shared/components/PromptModal";
import { TranscationIdForm } from "../TransactionIdForm";
import { FinanceService } from "../../../store/services/FinanceService/Finance.service";

const productCount = (Math.round(Math.random() * 10) % 2) + 1;
const cols: { name: string; text: string }[] = [
    {
        name: "lycaSku",
        text: "Product SKU (Lyca)",
    },
    {
        name: "vendorSku",
        text: "Product SKU(vendor)",
    },
    {
        name: "vendorPrice",
        text: "Vendor Price",
    },
    {
        name: "customerPrice",
        text: "Customer price",
    },
    {
        name: "markupPrice",
        text: "Markup Price",
    },
];

const productDetailsTableCols: { name: string; text: string }[] = [
    {
        name: "lycaSku",
        text: "Lyca Order No",
    },
    {
        name: "quantity",
        text: "PO number",
    },
    {
        name: "unitRate",
        text: "Tax amount",
    },
    {
        name: "totalAmount",
        text: "Total amount",
    },
];

const getProducts = (n: number) =>
    Array(n)
        .fill(null)
        .map(() =>
            getProductDetailsForApproval(
                3,
                "SKU-" + Math.round(Math.random() * 100000)
            )
        );

const productDetails = getProductDetails(
    productCount,
    "SKU-" + Math.round(Math.random() * 100000)
);

const Products: { [key: string]: string }[][] = getProducts(productCount);

type keys =
    | "id"
    | "createdAt"
    | "type"
    | "vendorName"
    | "vendorAddress"
    | "billableTo"
    | "purchaseOrder"
    | "totalTax"
    | "totalAmount"
    | "paymentStatus";
const keys: { key: keys; name: string }[] = [
    {
        key: "id",
        name: "Invoice Id",
    },
    {
        key: "createdAt",
        name: "Invoice generated date",
    },
    {
        key: "type",
        name: "Type",
    },
    {
        key: "vendorName",
        name: "Vendor name",
    },
    {
        key: "vendorAddress",
        name: "Vendor Address",
    },
    {
        key: "billableTo",
        name: "Billable To",
    },
    {
        key: "purchaseOrder",
        name: "Purchase Order Details",
    },
    {
        key: "totalTax",
        name: "Total tax amount",
    },
    {
        key: "totalAmount",
        name: "Total amount",
    },
    {
        key: "paymentStatus",
        name: "Invoice Status",
    },
];

const mock: { [key in keys]: any } = {
    id: "",
    createdAt: "",
    type: "",
    vendorName: "",
    vendorAddress: "",
    billableTo: "",
    purchaseOrder: {
        cols: productDetailsTableCols,
        rows: productDetails,
    },
    totalTax: "",
    totalAmount: "",
    paymentStatus: "",
};

const statusOptions = [
    {
        text: "Processing",
        value: "Processing",
        key: "Processing",
    },
    {
        text: "Paid",
        value: "Paid",
        key: "Paid",
    },
];

interface Props extends RouteComponentProps { }

function generateAddress({ address, city
}: {
    address: string,
    city: string,
}): string {
    try {
        return `${address}, ${city}`
    } catch (error) {
        return "";
    }
}

function InvoiceSummary({ location, match }: Props) {
    const state: any = location.state;
    const [promptOpen, setPromptOpen] = useState(false);
    const [invoice, setInvoice] = useState<any>(mock);
    const [status, setStatus] = useState("Processing");

    useEffect(() => {
        const params: any = match.params;
        FinanceService.getInvoicesById(
            params?.id,
            (data: any) => {
                setStatus(data?.paymentStatus);
                setInvoice({
                    ...data,
                    vendorName: data?.vendor?.name,
                    vendorAddress: data?.vendor?.vendorShipmentAddress ? generateAddress(data?.vendor?.vendorShipmentAddress) : "--",
                    purchaseOrder: {
                        cols: productDetailsTableCols,
                        rows: data?.purchaseOrders?.map((dataCols: any) => ({
                            lycaSku: dataCols?.OrderItem?.id,
                            productName: dataCols?.OrderItem?.vendorId,
                            quantity: dataCols?.poNumber,
                            unitRate: dataCols?.poNumber,
                            productTotal: dataCols?.tax,
                            taxApplicable: dataCols?.vendorPrice,
                            totalAmount: `€${data?.totalAmount}`
                        })),
                    },
                });
            },
            () => { }
        );
    }, []);

    const handlePaid = (_: any, data: any) => {
        setStatus(data?.value);
        if (data.value == "Paid") {
            setPromptOpen(true);
        }
    };

    console.log({ invoice })

    return (
        <div className="invoice-summary">
            <div className="invoice-summary__order-card">
                <PageTitle title="Invoice Summary" />
                <table className="invoice-summary__table">
                    {keys.slice(0, keys.length - 1).map((key) => {
                        if (invoice[key.key] == "object") {
                            console.log(invoice[key.key]);
                        }
                        return (
                            <tr className="invoice-summary__table-row">
                                <td className="invoice-summary__table-left">
                                    {key.name}
                                </td>
                                <td className="invoice-summary__table-right">
                                    {typeof invoice[key.key] !== "object" ? (
                                        invoice[key.key]
                                    ) : (
                                            <RadioTable
                                                className="noradio"
                                                noRadio
                                                rows={invoice[key.key].rows}
                                                cols={invoice[key.key].cols}
                                            />
                                        )}
                                </td>
                            </tr>
                        );
                    })}
                    <tr className="invoice-summary__table-row">
                        <td className="invoice-summary__table-left">Status</td>
                        <td className="invoice-summary__table-right">
                            <Dropdown
                                defaultValue={invoice.paymentStatus}
                                options={statusOptions}
                                onChange={handlePaid}
                                val={status}
                                disabled={status === "Paid"}
                            />
                        </td>
                    </tr>
                </table>
            </div>
            <PromptModal
                open={promptOpen}
                noActions
                onClose={() => setPromptOpen(false)}
            >
                <TranscationIdForm
                    modalControl={(status?: string) => {
                        setStatus((existing: string) => status || existing);
                        setPromptOpen((state) => !state);
                    }}
                />
            </PromptModal>
        </div>
    );
}

InvoiceSummary.propTypes = {};

export default withRouter(InvoiceSummary);
