import React, { useState } from "react";
import { PromptModal } from "../../../shared/components/PromptModal";
import { getOptions } from "../../../shared/utils/faker_data";
import { ORDER_DETAIL } from "../../../routes/route-constants/app-routes";
import { Checkbox, Button, Icon } from "semantic-ui-react";
import { arrayChunk } from "../../../shared/utils/array";
import { Brand } from "../../../store/models/Brand/Brand.model";
import { ProductService } from "../../../store/services/ProductService/Product.service";

interface Props {
    brands: Brand[];
    open: boolean;
    onClose: () => void;
}
export const ManageBrands = ({ brands, open, onClose }: Props) => {
    return (
        <PromptModal open={open} onClose={onClose} noActions>
            <ManageBrandsContent brands={brands} onClose={onClose} />
        </PromptModal>
    );
};



// const brands = arrayChunk(getOptions(16), 4);
const ManageBrandsContent = ({ brands, onClose }: any) => {

    const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

    const handleSelect = (e: any, data: any) => {
        if (data.checked) {
            setSelectedBrands((prev) => [...prev, data.value]);
        } else {
            setSelectedBrands((prev) => {
                let temp = [...prev];
                temp.splice(temp.indexOf(data.value), 1);
                return temp;
            });
        }
    };

    const handleRemove = () => {
        ProductService.deleteMarkup(
            selectedBrands,
            () => {
                onClose();
            },
            () => {}
        );
    };

    return (
        <div className="manage-brands">
            <div className="manage-brands__header">
                <span>Manage Brands</span>
                <Button
                    className="float-right mb-5"
                    color="red"
                    onClick={handleRemove}
                >
                    <Icon name="close" />
                    Remove Brand
                </Button>
            </div>
            <table className="manage-brands__table">
                {arrayChunk(brands, 4).map((chunk: any) => (
                    <tr>
                        {chunk.map((brand: any) => (
                            <td className="manage-brands__table__cell">
                                <Checkbox
                                    onChange={handleSelect}
                                    value={brand.key}
                                    label={brand.text}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </table>
        </div>
    );
};
