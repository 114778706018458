import React, { Fragment, useEffect, useState } from "react";
import "./dateRange.scss";


function pad(n:string|number){return n<10 ? '0'+n : n}
const getFormatedDate = (date:Date) =>{
    return `${date.getFullYear()}-${pad(date.getMonth()+1)}-${pad(date.getDate())}`;
}

interface Props{
    onDateChange?: (x?:{start_date:String, end_date:String}) => void
}

export const DateRange = ({onDateChange}:Props) => {

    // const startDate ='2021-10-10'
    // const sd = getFormatedDate(new Date(
    //     new Date().setFullYear(new Date().getFullYear() - 1)
    //     ))
    //     console.log(startDate, sd)
    //     alert()
    const [date, setDate] = useState({
        start: getFormatedDate(new Date(
            new Date().setFullYear(new Date().getFullYear() - 1)
        )),
        end: getFormatedDate(new Date()),
    });
    const [limit, setLimit] = useState({
        start: {
            upper: getFormatedDate(new Date()),
            lower: getFormatedDate(new Date(new Date().setFullYear(2000))),
        },
        end: {
            upper: getFormatedDate(new Date()),
            lower: getFormatedDate(new Date(new Date().setFullYear(2000))),
        },
    });



    useEffect(()=>{
        setLimit((prev)=>(
            {
                ...prev,
                end:{
                    ...prev.end,
                    lower: date.start
                }
            }
        ))
    },[date])

    useEffect(()=>{
        if(onDateChange){
            onDateChange({
                start_date: date.start,
                end_date: date.end
            })
        }
    }, [date])
    

    const handleChange = (e: any) => {
        const newDate = getFormatedDate(new Date(e?.target?.valueAsNumber));
        if(e.target.name == 'start'){
            setDate(()=>({
                ...date,
                start: newDate
            }))
        }
        else if(e.target.name == 'end'){
            setDate({
                ...date,
                end: newDate
            })
        }
    };

    return (
        <Fragment>
            <input
                name='start'
                className="date-range start"
                max={limit.start.upper}
                min={limit.start.lower}
                type="date"
                value={date.start}
                onChange={handleChange}
            />
            <i className="date-range icon exchange" />
            <input
                name='end'
                className="date-range end"
                max={limit.end.upper}
                min={limit.end.lower}
                type="date"
                onChange={handleChange}
                value={date.end}
            />
        </Fragment>
    );
};
