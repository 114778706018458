import {takeEvery, call, put} from 'redux-saga/effects'
import {
    AUTHENTICATED,
    AUTHENTICATION_ERROR,
    REQUEST_LOGIN,
    REQUEST_LOGOUT,
    UNAUTHENTICATED
} from '../definitions/authConstants'
import * as API from '../api/authApi';
import {deserialize} from "serializr";
import {User} from "../models/User/user.model";
import {INVOKE_TOASTER} from "../definitions/toastConstants";
import {appHistory} from "../../routes";


function* loginFlow(action: any) {
    const {email, password, history, successCallback, errCallback} = action.payload;
    let response;
    try {
        response = yield call(API.signIn, email, password);
        if (response.status >= 200 && response.status <= 299) {
            // yield put({type: INVOKE_TOASTER, payload: {type: 'success', message: 'Successfully logged in'}});
            const user = deserialize(User, response.data);
            yield put({type: AUTHENTICATED, payload: user});
            successCallback();
            history.push('/');
        } else {
            errCallback();
            yield put({type: AUTHENTICATION_ERROR, payload: response.message})
        }
    } catch (error) {
        errCallback();
        yield put({type: AUTHENTICATION_ERROR, payload: error})
    }
    return response;
}

function* logoutFlow() {
    let response;
    // try {
    //     /*response = yield call(API.signOut);*/
    //     response = {};
    //     if (response) {
    //         yield put({type: UNAUTHENTICATED, payload: {}});
    //         yield call(redirectToPage, '/auth/login');
    //     } else {
    //         yield put({type: AUTHENTICATION_ERROR, payload: "Something went wrong"})
    //     }
    // } catch (error) {
    //     yield put({type: AUTHENTICATION_ERROR, payload: error})
    // } finally {
    //     localStorage.clear();
    //     yield put({type: UNAUTHENTICATED, payload: "Successfully logged out"});
    // }
    // return response;
}


function redirectToPage(location: string) {
    appHistory.push(location);
}

export default function* AuthSaga() {
    yield takeEvery(REQUEST_LOGIN, loginFlow);
    yield takeEvery(REQUEST_LOGOUT, logoutFlow);
}
