import {CreateReducer} from "../../shared/utils/create_reducer";
import _ from 'lodash';
import {LIST_VENDOR_SUCCESS} from "../definitions/vendorConstants";
import {Vendor} from "../models/Vendor/vendor.model";

interface IVendorState {
    vendorList: Vendor[];
    activeVendor?: Vendor;
}

const initState: IVendorState = {
    vendorList: [],
    activeVendor: undefined,
};

const vendorReducer = CreateReducer(initState, {
    [LIST_VENDOR_SUCCESS](state: any, action: any) {
        return {...state, vendorList:action.payload};
    }
});

export default vendorReducer;
