import React from "react";
import { useToasts } from "react-toast-notifications";
import ToastContainer from "../../../store/containers/toastContainer";
import "./appToast.scss";

interface toastInformation {
    type: "success" | "error" | "warning";
    message: string;
}

const AppToast = (props: toastInformation) => {
    const { type, message } = props;
    return type && message ? (
        <div className={`app-toast app-toast-${type}`}>
            <p>{message}</p>{" "}
        </div>
    ) : null;
};

export default ToastContainer(AppToast);
