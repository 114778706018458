import React, { useState } from "react";
import { getProductDetailsForApproval } from "../../utils/faker_data";
import "./radioTable.scss";
import { Icon } from "semantic-ui-react";
import { ObjkeyByStr } from "../../utils/array";

interface Props {
    className?: string;
    rows: any;
    cols: any;
    onChange?: (row: any) => void;
    noRadio?: boolean;
}
export const RadioTable = ({
    rows,
    cols,
    onChange,
    className,
    noRadio,
}: Props) => {
    const [selected, setSelected] = useState<{ index: number; row: {} }>({
        index: -1,
        row: {},
    });

    const handleChange = (index: number, row: {}) => {
        setSelected((prev) => {
            onChange && onChange(row);
            return { index, row };
        });
    };
    return (
        <table className={`radio-table ${className ? className : ""}`}>
            <tr className="radio-table__row">
                {cols?.map((col: any) => (
                    <th
                        className="radio-table__cell"
                        style={{ fontWeight: "bold" }}
                    >
                        {col.text}
                    </th>
                ))}
            </tr>
            {rows?.map((row: any, index: number) => (
                <tr className="radio-table__row">
                    {cols?.map((col: any, i: number) => (
                        <>
                            <td className="radio-table__cell">
                                {i == 0 &&
                                    !noRadio &&
                                    (selected.index == index ? (
                                        <span className="cursor-pointer">
                                            <Icon
                                                name="check circle"
                                                color="green"
                                            />
                                        </span>
                                    ) : (
                                        <span
                                            className="cursor-pointer"
                                            onClick={() =>
                                                handleChange(index, row)
                                            }
                                        >
                                            <Icon name="circle outline" />
                                        </span>
                                    ))}
                                {ObjkeyByStr(row, col.name)}
                            </td>
                        </>
                    ))}
                </tr>
            ))}
        </table>
    );
};
