export type ATTRIBUTE_TYPES =
    | "brand"
    | "memory"
    | "storage"
    | "sim_data"
    | "sim_ukmins"
    | "contract_length"
    | "sim_internationalmins"
    | "product_color";
export const ATTRIBUTE_TYPES_ARRAY: ATTRIBUTE_TYPES[] = [
    "brand",
    "memory",
    "storage",
    "sim_data",
    "sim_ukmins",
    "contract_length",
    "sim_internationalmins",
    "product_color",
];
