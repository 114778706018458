import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./myProducts.scss";
import ReactTable from "../../../shared/components/ReactTable";
import { getMagentoPendingProducts } from "../../../shared/utils/faker_data";
import { Button, Pagination } from "semantic-ui-react";
import { generatePath, useHistory } from "react-router";
import { PRODUCT_DETAIL } from "../../../routes/route-constants/app-routes";
import { PromptModal } from "../../../shared/components/PromptModal";
import { ProductService } from "../../../store/services/ProductService/Product.service";

function MyProducts() {
    const history = useHistory();
    const [showPrompt, setShowPrompt] = useState(false);
    const [products, setProducts] = useState<any>([]);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const [readyForApproval, setReadyForApproval] = useState<any>(null);

    const fetchMergedProducts = (pageNumber: number) => {
        ProductService.getAllVendorProducts(
            pageNumber,
            (list: any, currentPage: number, lastPage: number) => {
                setProducts(list);
                setCurrent(currentPage);
                setPagecount(lastPage);
            },
            () => {}
        );
    };

    useEffect(() => {
        fetchMergedProducts(current);
    }, []);

    const handleApprove = (e: any, data: any) => {
        setReadyForApproval(data?.original);
        e.stopPropagation();
        setShowPrompt(true);
    };

    const columns = [
        {
            Header: "Product Name",
            accessor: "name",
        },
        {
            Header: "Vendor Name",
            accessor: "vendor[name]",
        },
        {
            Header: "SKU",
            accessor: "sku",
        },
        {
            Header: "Quantity",
            accessor: "quantity",
        },
        {
            Header: "Storage",
            accessor: "storage",
        },
        {
            Header: "Memory",
            accessor: "memory",
        },
        {
            Header: "Color",
            accessor: "color",
        },
        {
            Header: "Total Price",
            accessor: "vendorPrice",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Markup Price",
            accessor: "markupPrice",
            Cell: ({ value }: any) => `€${value}`,
        },
        // {
        //     Header: "Action",
        //     Cell: ({ row }: any) => (
        //         <Button onClick={(e: any) => handleApprove(e, row)} primary>
        //             Approve
        //         </Button>
        //     ),
        // },
    ];

    return (
        <div>
            <ReactTable
                // selectable
                name=""
                columns={columns}
                data={products}
                getTrProps={({ ...args }: any) => {
                    history.push(
                        generatePath(PRODUCT_DETAIL, {
                            productId: args.original?.id,
                        })
                    );
                }}
            />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        fetchMergedProducts(activePage);
                    }}
                />
            )}
            <PromptModal
                open={showPrompt}
                onClose={() => setShowPrompt(false)}
                onSubmit={() => {
                    console.log(readyForApproval);
                    ProductService.approveVendorProducts(
                        {
                            vendor_product: readyForApproval?.id,
                        },
                        () => {
                            fetchMergedProducts(current);
                        },
                        () => {}
                    );
                }}
                buttons={{ cancel: "No", submit: "Yes" }}
            >
                Do you really want to approve this product?
            </PromptModal>
        </div>
    );
}

MyProducts.propTypes = {};

export default MyProducts;
