import {alias, primitive, serializable, object, list} from "serializr";
import { CountriesEnum } from "../../../../enums/countriesEnum";

export class CountryMeta {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: CountriesEnum;
}

export class ShippingZoneMeta {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

export class TaxTypeMeta {
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('name', primitive()))
    name?: string;
}

// export class Countries {
//     @serializable(alias('name', primitive()))
//     name?: string;
// }