import React, { useEffect, useState } from "react";
import ReactTable from "../../../shared/components/ReactTable";
import {
    getInvoiceList,
    getDropdownOptions,
} from "../../../shared/utils/faker_data";
import {
    INVOICE_STATUS_COLOR,
} from "../../../shared/Constants/InvoicePurchaseOrder";
import "./invoice.scss";
import { generatePath, useHistory } from "react-router";
import { INVOICE_DETAIL } from "../../../routes/route-constants/app-routes";
import { Dropdown } from "../../../shared/components/Dropdown";
import { FinanceService } from "../../../store/services/FinanceService/Finance.service";
import { MetaService } from "../../../store/services/MetaService/Meta.service";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";

const vendors = getDropdownOptions(2);

const columns = [
    {
        Header: "Invoice Id",
        accessor: "id",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Invoice generated Date",
        accessor: "createAt",
        Cell: ({ value }: any) => new Date(value).toLocaleDateString(),
    },
    {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Number of Orders",
        accessor: "purchaseOrderCount",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "TAX amount",
        accessor: "totalTax",
        Cell: ({ value }: any) => `€${value}`,
    },
    {
        Header: "Total cost",
        accessor: "totalAmount",
        Cell: ({ value }: any) => `€${value}`,
    },
    {
        Header: "Status",
        accessor: "paymentStatus",
        Cell: ({ value }: any) => (
            <>
                <span
                    className="status-color"
                    style={{ background: INVOICE_STATUS_COLOR[value] }}
                ></span>
                <span>{value}</span>
            </>
        ),
    },
];
const data: any = getInvoiceList(15);

export const Invoice = () => {
    const history = useHistory();
    const [vendors, setVendors] = useState<any>([]);
    const [invoices, setInvoices] = useState<any>([]);
    const [selected, setSelected] = useState<any>(null);
    const [type, setType] = useState<any>([]);

    const fetchData = (id: any) =>
        FinanceService.listAllInvoices(
            id,
            (data: any) => {
                setInvoices(data);
            },
            () => {}
        );

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user") as string);
        const role = user?.userRoles?.map((user: any) => user?.role);
        setType(role);
        if (role?.includes("Vendor")) {
            fetchData(user?.id);
            return;
        }
        MetaService.getUserAssigned(
            "Vendor",
            (vendors: any) => {
                setVendors(vendors);
                setSelected(vendors[0].key);
                fetchData(vendors[0].key);
            },
            () => {}
        );
    }, []);

    return (
        <div className="invoice">
            {type?.includes("Vendor") ? null : (
                <div className="invoice__card mb-6">
                    <div className="invoice__select">
                        <div>Select Vendor</div>
                        <Dropdown
                            placeholder="Select Vendor"
                            val={type?.includes("Vendor") ? selected: vendors[0]?.key}
                            options={vendors}
                            // defaultValue={selected}
                            onChange={(_, { value }: any) => {
                                fetchData(value);
                                setSelected(value)
                            }}
                        />
                    </div>
                </div>
            )}
            <div className="invoice__table">
                <ReactTable
                    headerLeft
                    name=""
                    columns={columns}
                    data={invoices}
                    getTrProps={(e: any) => {
                        console.log(e);
                        history.push(
                            generatePath(INVOICE_DETAIL, {
                                id: e?.original?.id,
                            })
                        );
                    }}
                />
            </div>
            {/* <AddBrand open={showModal} setOpen={setShowModal}/> */}
        </div>
    );
};
