export const debouce = (callback: Function, delay: number) => {
    let timerID: any;
    return (...args: any) => {
        if (timerID) {
            clearTimeout(timerID);
        }
        timerID = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};


let debounceFunction: { [x: string]: number | undefined } = {};

export const namedDebounce = (x: Function, y: number, key: string) => {
    if (debounceFunction[key]) clearTimeout(debounceFunction[key]);
    debounceFunction[key] = setTimeout(x, y);
};