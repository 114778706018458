import {
    REQUEST_LOGIN, REQUEST_LOGOUT,
} from "../definitions/authConstants";
import {Login} from "../models/Login/login.model";

export function signInAction(login: Login, history:any, successCallback: Function, errCallback: Function) {
    const {email,password} = login;
    return {
        type:REQUEST_LOGIN,
        payload:{
            email,
            password,
            history,
            successCallback,
            errCallback
        }
    }
}

export function signOutAction() {
    return{
        type: REQUEST_LOGOUT,
        payload: {
            message:"Request for logout",
        }
    }
}
