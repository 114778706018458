import React, { useEffect, useState } from "react";
import Card from "./Card";
import Increase from "../../../assets/icons/increase.svg";
import Decrease from "../../../assets/icons/decrease.svg";
import Orders from "../../../assets/icons/orders.svg";
import Profit from "../../../assets/icons/profit.svg";
import "./stats.scss";
import { DashboardService } from "../../../store/services/DashboardService/Dashboard.service";
import { DashboardCardModel } from "../../../store/models/Dashboard/DashboardCards.model";
import { boolean } from "yup";
import { namedDebounce } from "../../../shared/utils/debounce";
import { store } from "../../../store";

interface Stats {
    growth: boolean;
    amount: string;
    description: string;
}

export interface CompanyStats {
    img: any;
    title: string;
    value: string;
    stats: Stats;
}

const Stats = () => {
    const data = useDashboardCardsData();

    return (
        <div className="stats__container">
            <p className="stats__title">Home - Dashboard</p>
            <div className="stats-wrapper">
                {data?.map((stats) => (
                    <Card stats={stats} />
                ))}
            </div>
        </div>
    );
};
export default Stats;

const useDashboardCardsData = () => {
    const [cards, setCards] = useState<DashboardCardModel>();
    const [data, setData] = useState<CompanyStats[]>([
        {
            img: Increase,
            title: "Revenue",
            value: "123,455",
            stats: {
                growth: true,
                amount: "3.48",
                description: "Increase from previous month",
            },
        },
        {
            img: Decrease,
            title: "New Customers",
            value: "2,345",
            stats: {
                growth: false,
                amount: "3.48",
                description: "increased from previous month",
            },
        },
        {
            img: Profit,
            title: "Lyca profit",
            value: "32,924",
            stats: {
                growth: false,
                amount: "",
                description: "current month",
            },
        },
        {
            img: Orders,
            title: "Total",
            value: "2345",
            stats: {
                growth: false,
                amount: "",
                description: "current month",
            },
        },
    ]);
    useEffect(() => {
        namedDebounce(
            () => {
                if (store.getState().auth.authenticated)
                    DashboardService.getCardData(setCards);
            },
            400,
            "stats_card_debounce"
        );
    }, []);

    useEffect(() => {
        setData((prev) => {
            let newData = [...prev];
            newData[0].value = String(cards?.revenue?.CurrentMonth);
            newData[0].stats.amount = String(
                Math.abs(cards?.revenue?.channgeInPercentae || 0)
            );
            newData[0].stats.growth = Number(newData[0].stats.amount) >= 0;
            newData[0].img = newData[0].stats.growth ? Increase : Decrease;

            newData[1].value = String(cards?.customers?.newCustomers);
            newData[1].stats.amount = String(
                Math.abs(cards?.customers?.channgeInPercentae || 0)
            );
            newData[1].stats.growth = Number(newData[1].stats.amount) >= 0;
            newData[1].img = newData[1].stats.growth ? Increase : Decrease;

            newData[2].value = String(cards?.lycaProfit?.profit);
            newData[3].value = String(cards?.total?.total);

            return newData;
        });
    }, [cards]);
    return data;
};
