import React from "react";
import PropTypes from "prop-types";
import "./createProduct.scss";
import ProductForm from "../ProductForm";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import PageTitle from "../../../shared/components/PageTitle";

function CreateProduct() {
    return (
        <div className="create-product">
            <PageTitle title="New Product" />
            <div className="create-product__form-wrapper">
                <ProductForm />
            </div>
        </div>
    );
}

CreateProduct.propTypes = {};

export default withRouter(CreateProduct);
