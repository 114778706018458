import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import Canvas from "../../../../Canvas/canvasjs.react";
import { DateRange } from "../../../../shared/components/DateRange";
import { namedDebounce } from "../../../../shared/utils/debounce";
import { DashboardService } from "../../../../store/services/DashboardService/Dashboard.service";
import "./topSold.scss";

const CanvasJSChart = Canvas.CanvasJSChart;



const TopSold = () => {
    const [date, setDate] = useState<{start_date:String, end_date:String}>()
    const [dataPoints, setDataPoints] = useState<{label:String, y:number}[]>([])
    const [dropwownOptions, setDropdownOptions] = useState<
        {
            key: string | number;
            value: string | number;
            text: string;
        }[]
    >([
        {
            key: 1,
            value: 1,
            text: "Apr 2020 - Jul 2020",
        },
    ]);

    useEffect(()=>{
        namedDebounce(
            ()=>{
                date &&
                DashboardService.getTop5Products(date, (data)=>{
                    let temp = data?.products?.map(x=>({label:x.masterProductName || "", y: x.sold || 0}));
                    setDataPoints(temp || []);
                })
            },
            400,
            "topsold_chart_debounce"
        )
    },[date])

    
    const options = {
        animationEnabled: true,
        theme: "light2",
        dataPointWidth: 7,
        data: [
            {
                type: "column",
                dataPoints,
                color: "#7764E4",
            },
        ],
    };


    return (
        <div className="revenue-chart">
            <div className="top-sold__header">
                <p>Top 5 products sold</p>
                <div className="toggle-chart">
                    {/* <Dropdown
                        className="dropdown-date-selector"
                        options={dropwownOptions}
                        defaultValue={1}
                    /> */}
                    <DateRange onDateChange={setDate}/>
                </div>
            </div>
            <CanvasJSChart options={options} />
        </div>
    );
};

export default TopSold;
