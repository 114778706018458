import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./vendorDetail.scss";
import { Button } from "semantic-ui-react";
import VendorProfile from "../VendorProfile";
import VendorProducts from "../VendorProducts";
import VendorOrders from "../VendorOrders";
import VendorInvoices from "../VendorInvoices";
import _ from "lodash";
import { VendorService } from "../../../store/services/Vendor.service";
import { RouteComponentProps } from "react-router";
import { Vendor } from "../../../store/models/Vendor/vendor.model";

interface Props extends RouteComponentProps {}
function VendorDetail(props: Props) {
    const params: any = props.match.params;
    const vendor = useVendor(params.vendorId);
    const [activeTab, setActiveTab] = useState("profile");
    const tabs = [
        {
            name: "profile",
            text: "Profile",
            component: <VendorProfile vendor={vendor} />,
        },
        { name: "products", text: "Products", component: <VendorProducts /> },
        // { name: 'orders', text: 'Orders', component: <VendorOrders/> },
        // { name: 'invoices', text: 'Invoices', component: <VendorInvoices/> },
    ];
    const activeTabIndex = _.findIndex(tabs, { name: activeTab });
    return (
        <div className="vendor-detail">
            <div className="vendor-detail__header">
                {tabs.map((tab, i) => {
                    return (
                        <Button
                            secondary={activeTab === tab.name}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {" "}
                            {tab.text}{" "}
                        </Button>
                    );
                })}
            </div>
            <div className="vendor-detail__body">
                {activeTabIndex >= 0 && tabs[activeTabIndex].component}
            </div>
        </div>
    );
}

VendorDetail.propTypes = {};

const useVendor = (id: string) => {
    const [vendor, setVendor] = useState<Vendor>();
    useEffect(() => {
        VendorService.getVendor(
            id,
            (vendor: Vendor) => {
                setVendor(vendor);
            },
            () => {}
        );
    }, []);
    return vendor;
};

export default VendorDetail;
