import { OrderItem } from "../../store/models/Finance/Order.model";

export const toTitles = (s: string, country?:boolean) => {
    if(country && s.length <= 3) return s.toUpperCase();
    return String(s).replace(/\w\S*/g, function (t) {
        return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
};
export const splitCamel = (s: string) => {
    return String(s).replace(/_/g, " ");
};

export const splitSnake = (s: string) => {
    return String(s).replace(/_/g, " ");
};

export const camelToSentence = (s: string) =>
    String(s).replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, "$1");

export const snakecaseToTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return (
            txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        ).replaceAll("_", " ");
    });
};

export const getPoNumbers = (orderItems:OrderItem[]) =>{
    // console.log(orderItems);
    // debugger
    let result = '';
    orderItems?.forEach(orderItem => {
        result += orderItem?.purchaseOrder?.poNumber || ' ';
    })
    return result;
}

export const sentenceCase = (value:String) => value.charAt(0).toUpperCase() + value.slice(1)


// date

export const getWeekNumber = (x:Date) =>{
    let d = x;
    let totalDays = 0;
    let month = d.getMonth();
    let date = d.getDate();
    totalDays = date;
    for (let index = month - 1; index >=0 ; index--) {
        d.setDate(0);
        totalDays += d.getDate();
    }
    return Math.ceil(totalDays/7);
}