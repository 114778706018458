import { ADD_LOADER, REMOVE_LOADER } from "../definitions/loaderConstants";

export const addLoader = () => {
    return {
        type: ADD_LOADER,
    };
};

export const removeLoader = () => {
    return {
        type: REMOVE_LOADER,
    };
};
