import React, { useEffect, useState } from "react";
import "./editTicket.scss";
import PageTitle from "../../../shared/components/PageTitle";
import TicketForm from "../TicketForm";
import { RouteComponentProps } from "react-router";
import { TicketService } from "../../../store/services/TicketService/ticket.service";
import { Ticket } from "../../../store/models/Ticket/ticket.model";

interface Props extends RouteComponentProps {}
export const EditTicket = ({ match }: Props) => {
    const { params }: any = match;
    const [ticket, setTicket] = useState<any>({});

    useEffect(() => {
        TicketService.getTicketById(
            params?.id,
            (ticket: Ticket) => {
                setTicket({
                    ...ticket,
                    dueDate: ticket.dueDate ?? "",
                    issueUpdates: ticket.issueUpdates ?? "",
                    additionalDetails: ticket.additionalDetails ?? "",
                    groupAssigned: ticket.assignedGroup,
                    userAssigned: ticket.assignedUser?.id,
                });
            },
            () => {}
        );
    }, []);
    
    return (
        <div className="edit-ticket">
            <PageTitle title={`${params.id}`} />
            <div className="edit-ticket__form-wrapper">
                <TicketForm initialTicketValues={ticket} edit/>
            </div>
        </div>
    );
};
