import React, { useEffect, useState } from "react";
import "./ticketDetails.scss";
import { getTicketDetails } from "../../../shared/utils/faker_data";
import PageTitle from "../../../shared/components/PageTitle";
import { RouteComponentProps, withRouter, generatePath } from "react-router";
import { type } from "os";
import { Link } from "react-router-dom";
import { Icon, Button } from "semantic-ui-react";
import { EDIT_TICKET } from "../../../routes/route-constants/app-routes";
import { TicketService } from "../../../store/services/TicketService/ticket.service";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import { RMAService } from "../../../store/services/RMAService/rma.service";
import { RMA } from "../../../store/models/RMA/RMA.mode";
import { MetaService } from "../../../store/services/MetaService/Meta.service";
import { Dropdown } from "../../../shared/components/Dropdown";

type keys = "id" | "maintenanceTicketId" | "returnType" | "isCustomerApproved";

const rmaProperties: { label: string; key: keys; dataType?: string }[] = [
    {
        label: "RMA Id",
        key: "id",
    },
    {
        label: "Ticket Id",
        key: "maintenanceTicketId",
    },
    {
        key: "returnType",
        label: "Return Type",
    },
    {
        key: "isCustomerApproved",
        label: "Customer Confirmation",
    },
];

export const RMADetails = withRouter(({ match }: RouteComponentProps) => {
    const { params }: any = match;
    const [rma, setRma] = useState<RMA>({});
    const [issue, setIssue] = useState<any>("");
    const [issueType, setIssueType] = useState<any>([]);

    useEffect(() => {
        MetaService.getIssueTypes((data: any) => {
            setIssueType(data);
        });
        RMAService.RMADetails(
            params?.id,
            (rma: RMA) => {
                setRma(rma);
                setIssue(rma.returnType);
            },
            () => {}
        );
    }, []);

    const changeReturnType = (id: any, returnType: string) => {
        setIssue((cur: any) => cur);
        RMAService.updateRMA(
            id,
            returnType,
            (rma: RMA) => {
                setRma(rma);
                setIssue(returnType);
            },
            () => {}
        );
    };

    return (
        <div>
            <PageTitle title={`${params.id}`} />
            <div className="ticket-detail__body">
                <div className="ticket-detail__row">
                    <span className="ticket-detail__label">RMA Id</span>
                    <span className="ticket-detail__value">{rma.id}</span>
                </div>
                <div className="ticket-detail__row">
                    <span className="ticket-detail__label">Ticket Id</span>
                    <span className="ticket-detail__value">
                        {rma.maintenanceTicketId}
                    </span>
                </div>
                <div className="ticket-detail__row">
                    <span className="ticket-detail__label">Return Type</span>
                    <span className="ticket-detail__value">

                        <Dropdown
                            placeholder="Select Type"
                            options={issueType}
                            val={issue || ""}
                            onChange={(_, { value }: any) =>
                                changeReturnType(rma.id, value)
                            }
                            width={500}
                        />
                    </span>
                </div>
                <div className="ticket-detail__row">
                    <span className="ticket-detail__label">
                        Customer Confirmation
                    </span>
                    <span className="ticket-detail__value">
                        {String(Boolean(rma.isCustomerApproved))}
                    </span>
                </div>
            </div>
        </div>
    );
});
