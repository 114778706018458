import React, { useState, useEffect, useCallback } from "react";
import { Icon, Button, Pagination } from "semantic-ui-react";
import { getAllOrders } from "../../../shared/utils/faker_data";
import ReactTable from "../../../shared/components/ReactTable";
import "./waitingOnLycaFinance.scss";
import { ProductApprovalConfirmationModal } from "./ProductApprovalConfirmationModal";
import { useHistory, generatePath } from "react-router";
import { ORDER_DETAIL } from "../../../routes/route-constants/app-routes";
import { fetchOrderService } from "../OrderService/service";
import { OrderService } from "../../../store/services/OrderService/Order.service";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { namedDebounce } from "../../../shared/utils/debounce";

export const WaitingOnFinanceOrders = SearchContainer(
	({ text }: { text: string }) => {
		const [search, setSearch] = useState(text);
		const [showModal, setShowModal] = useState(false);
		const [data, setData] = useState<{ [key: string]: any }[]>([]);
		const [current, setCurrent] = useState<any>(null);
		const [pageCount, setPagecount] = useState<any>(null);
		const [id, setId] = useState<any>(null);
		const history = useHistory();

        const user = JSON.parse(localStorage.getItem("user") as string);
		const role = user?.userRoles?.map((user: any) => user?.role);

		useEffect(()=>{
			namedDebounce(
				() => setSearch(text),
				250,
				"SEARCH_WaitingOnFinanceOrders"
			)
		},[text])
		
		useEffect(() => {
				(async () => {
					try {
						const {
							orders,
							currentPage,
							pageCount,
						}: any = await fetchOrderService({
							page: 1,
							type: "waiting_on_lyca_finance",
							orderNo: search,
						});
						setData(orders);
						setCurrent(currentPage);
						setPagecount(pageCount);
					} catch (error) {}
				})();
		}, [search]);

		const reload = async () => {
			try {
				const {
					orders,
					currentPage,
					pageCount,
				}: any = await fetchOrderService({
					page: current,
					type: "waiting_on_lyca_finance",
				});
				setData(orders);
				setCurrent(currentPage);
				setPagecount(pageCount);
				setShowModal(false);
			} catch (error) {
				setShowModal(false);
			}
		};

		const handleFetchData = () => {
			reload();
		};

		const handleRejectOrder = (id: any) => {
			OrderService?.rejectFinanceOrder(
				id,
				() => {
					reload();
				},
				() => {}
			);
		};

		const columns = [
			{
				Header: "Magento Order No",
				accessor: "orderNo",
				Cell: ({ value }: any) => isFieldEmpty(value),
			},
			{
				Header: "Order Date",
				accessor: "orderDate",
				Cell: ({ value }: any) => {
					const date = new Date(value);
					return `${date.getDate()}/${
						date.getMonth() + 1
					}/${date.getFullYear()}`;
				},
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: ({ value }: any) => <span>€{value}</span>,
			},
			{
				Header: "Customer Name",
				accessor: "customerName",
				Cell: ({ value }: any) => isFieldEmpty(value),
			},
			{
				Header: "Pmt method",
				accessor: "paymentMethodName",
				Cell: ({ value }: any) => isFieldEmpty(value),
			},
			{
				Header: "Region",
				accessor: "shipmentRegion[name]",
				Cell: ({ value }: any) => value || "--",
			},
			{
				Header: "Action",
				Cell: ({ row }: any) => (
					<>
						<span
							className="waiting-on-lyca-finance__eye cursor-pointer"
							onClick={() => {
								history.push({
									pathname: generatePath(ORDER_DETAIL, {
										orderId: row.original?.id,
									}),
									state: { pendingApproval: true },
								});
							}}
						>
							<Icon name="eye" />
						</span>
						<Button
							secondary
							onClick={() => {
								setShowModal(true);
								setId(row.original?.id);
							}}
						>
							{" "}
							<Icon name="check" /> Approve
						</Button>
						<Button
							color="red"
							onClick={() => {
								handleRejectOrder(row.original?.id);
							}}
						>
							{" "}
							<Icon name="close" /> Reject
						</Button>
					</>
				),
			},
		];
		return (
			<div className="waiting-on-lyca-finance">
				<ReactTable headerLeft name="" columns={columns} data={data} />
				{pageCount === 1 || pageCount === null ? null : (
					<Pagination
						className="pagenation-container"
						boundaryRange={0}
						activePage={current}
						ellipsisItem={null}
						firstItem={null}
						lastItem={null}
						siblingRange={1}
						totalPages={pageCount}
						onPageChange={async (_, { activePage }: any) => {
							try {
								const {
									orders,
									currentPage,
									pageCount,
								}: any = await fetchOrderService({page: activePage, type:"waiting_on_lyca_finance"});
								setData(orders);
								setCurrent(currentPage);
								setPagecount(pageCount);
							} catch (error) {}
						}}
					/>
				)}

				<ProductApprovalConfirmationModal
					handleFetchData={handleFetchData}
					open={showModal}
					setOpen={setShowModal}
					data={id}
				/>
			</div>
		);
	}
);
