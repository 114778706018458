import { serializable, alias, primitive, list, object } from "serializr";
import { FinanceOrder, OrderItem } from "../Finance/Order.model";
import { User } from "../User/user.model";


export class RMA{
    @serializable(alias('id', primitive()))
    id?: number;

    @serializable(alias('return_type', primitive()))
    returnType?: number;

    @serializable(alias('is_customer_approved', primitive()))
    isCustomerApproved?: number;
}
export class Ticket {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("user_order_id", primitive()))
    userOrderId?: number;

    @serializable(alias("issue_type", primitive()))
    issueType?: string;

    @serializable(alias("issue_desc", primitive()))
    issueDesc?: string;

    @serializable(alias("assigned_group", primitive()))
    assignedGroup?: string;

    @serializable(alias("issue_updates", primitive()))
    issueUpdates?: string;

    @serializable(alias("issue_status", primitive()))
    issueStatus?: string;

    @serializable(alias("priority", primitive()))
    priority?: string;

    @serializable(alias("additional_details", primitive()))
    additionalDetails?: string;

    @serializable(alias("due_date", primitive()))
    dueDate?: string;

    @serializable(alias("assigned_user_id", primitive()))
    assignedUserId?: number;

    @serializable(alias("order_item_id", primitive()))
    orderItemId?: number;

    @serializable(alias("order_item", object(OrderItem)))
    orderItem?: OrderItem;

    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("mobile_no", primitive()))
    mobileNo?: string;

    @serializable(alias("return_product", object(RMA)))
    rma?: RMA;

    @serializable(alias("cs_team_group", primitive()))
    csTeamGroup?: string;

    @serializable(alias("user_order", object(FinanceOrder)))
    userOrder?: FinanceOrder;

    @serializable(alias("assigned_user", object(User)))
    assignedUser?: User;
}
