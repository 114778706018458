import { serializable, alias, primitive, object, list } from "serializr";

class Vendor{
    @serializable(alias("sold", primitive()))
    sold?: number;

    @serializable(alias("vendor_id", primitive()))
    vendorId?: string | number;

    @serializable(alias("vendor_name", primitive()))
    vendorName?: string;

    @serializable(alias("vendor_email", primitive()))
    vendorEmail?: string;
}

export class TopFiveVendors{
    @serializable(alias('data', list(object(Vendor))))
    vendors?: Vendor[];
}