import { CreateReducer } from "../../shared/utils/create_reducer";
import {REMOVE_TOAST, SET_TOAST} from "../definitions/toastConstants";

interface IToastState {
    type?: string;
    message?: string;

}

const initState: IToastState = {
    type: undefined,
    message: undefined,
};

const toastReducer = CreateReducer(initState, {
    [SET_TOAST](state: any, action: any) {
        const { type, message } = action.payload;
        if (action.payload !== undefined) {
            return {
                ...state,
                type,
                message,
            };
        }
        return {...state, type, message };
    },
    [REMOVE_TOAST](state: any, action: any) {
        return {...state, type: undefined, message: undefined };
    }
});

export default toastReducer;
