import { CreateReducer } from "../../shared/utils/create_reducer";
import { ADD_LOADER, REMOVE_LOADER } from "../definitions/loaderConstants";

interface LoaderState {
    loaderCount: number;
}

const loaderInitialValue: LoaderState = {
    loaderCount: 0,
};

export const loaderReducer = CreateReducer(loaderInitialValue, {
    [ADD_LOADER](state: LoaderState): LoaderState {
        return {
            ...state,
            loaderCount: state.loaderCount + 1,
        };
    },
    [REMOVE_LOADER](state: LoaderState): LoaderState {
        return {
            ...state,
            loaderCount: state.loaderCount - 1,
        };
    },
});
