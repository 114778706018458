import * as yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const getErr = (field:String, value:number = 0, type: "min" | "max" | "req" = "req") =>{
    if(type==="req") return `${field} is required`;
    return `${field} must be ${type == "max"? "atmost":"atleast"} ${value} characters`
}

export const ticketFormValidationSchema = yup.object({
    userOrderId: yup.string().required(getErr("Order Id")).min(1, getErr("Order Id", 1, "min")).max(62, getErr("Order Id", 62, "max")),
    orderItemId: yup.string().required(getErr("Product")).max(62),
    email: yup.string().email().required(getErr("Email")).max(191, getErr("Email", 191, "max")),
    mobileNo: yup.string().required(getErr("Phone number")).min(10,getErr("Phone number", 10, "min")).max(10,getErr("Phone number", 10, "max")).matches(phoneRegExp,"Invalid phone number"),
    issueType: yup.string().required(getErr("Issue type")).min(3).max(191),
    issueDesc: yup.string().required(getErr("Issue description")).max(65000, getErr("Issue description", 65000, "max")),
    groupAssigned: yup.string().required(getErr("Group")).max(191),
    userAssigned: yup.string().max(191),
    issueUpdates: yup.string().max(191, getErr("Issue Updates", 191, "max")),
    issueStatus: yup.string().required().max(191),
    csTeamGroup: yup.string().required().max(191),
    rmaNumber: yup.string().max(191, getErr("RMA number", 191, "max")),
    priority: yup.string().required().max(191),
    dueDate: yup.string().required().max(62),
    additionalDetails: yup.string().max(191, getErr("Additional details", 191, "max")),
    // attachments: yup.array(),
});
