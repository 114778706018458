import {AUTHENTICATED, AUTHENTICATION_ERROR, FORGOT_PASSWORD, UNAUTHENTICATED,RESET_PASSWORD} from '../definitions/authConstants';
import { CreateReducer } from "../../shared/utils/create_reducer";

interface IAuthState {
    authenticated: boolean;
    error?: string;
    userName?: string | null;
    success: boolean;
    successMessage?: string | null;

}

const initState:IAuthState = {
    authenticated: false,
    error: undefined,
    userName: null,
    success:false,
    successMessage:null,
};

const authReducer = CreateReducer(initState, {
    [AUTHENTICATED](state: any, action: any) {
        if (action.payload !== undefined) {
            return {
                ...state,
                authenticated: true,
                user: action.payload,
            };
        }
        return {...state, authenticated: true, userRole: action.userRole, userName: action.userName};
    },
    [UNAUTHENTICATED](state: any, action: any) {
        return {...state, authenticated: false};
    },
    [AUTHENTICATION_ERROR](state: any, action: any) {
        return {...state, error: action.payload};
    },
    [FORGOT_PASSWORD](state: any, action: any) {
        return {...state,success:action.payload.success,successMessage:action.payload.message};
    },
    [RESET_PASSWORD](state: any, action: any) {
        return {...state,success:action.payload.success,successMessage:action.payload.message};
    }
});

export default authReducer;
