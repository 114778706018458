import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, Button } from "semantic-ui-react";
import "./productForm.scss";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { productFormValidationSchema } from "./validationSchema";
import { useHistory } from "react-router-dom";
import {
    getVendors,
    getOptions,
    getDropdownOptions,
} from "../../../shared/utils/faker_data";
import { Dropdown } from "../../../shared/components/Dropdown";
import { MetaService } from "../../../store/services/MetaService.service";
import { Brand } from "../../../store/models/Brand/Brand.model";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import { VendorService } from "../../../store/services/Vendor.service";
import { Vendor } from "../../../store/models/Vendor/vendor.model";
import LocalStorage from "../../../shared/utils/LocalStorage";
import { VendorBrand } from "../../../store/models/Vendor/vendorBrand.model";

interface IProductForm {
    vendorSku: string;
    vendorId: string;
    name: string;
    category: string;
    description: string;
    brandId: string;
    markupPrice: string;
    vendorPrice: string;
    deliveryCost: string;
    quantity: string;
    refurbishedStatus: string;
    storage: string;
    memory: string;
    color: string;
    miscellaneousInfo: string;
    priceExcludingTax: string;
    priceIncludingTax: string;
}

const mock = {
    category: getDropdownOptions(4),
    brand: getDropdownOptions(4),
    refurbishedStatus: getDropdownOptions(4),
};

interface Props {
    productInitialValues?: IProductForm;
}

interface IDropdown {
    key: string;
    text: string;
    value: string;
}

function ProductForm({ productInitialValues }: Props) {
    const [brands, setBrands] = useState<any>([]);
    const [type, setType] = useState<any>(
        JSON.parse(localStorage.getItem("user") as string)?.userRoles?.map(
            (user: any) => user?.role
        )
    );
    const [storage, setStorage] = useState<any>([]);
    const [memory, setMemory] = useState<any>([]);
    const [color, setColor] = useState<any>([]);
    const [markupPercentage, setMarkupPercentage] = useState(0);
    const [taxPercent, setTaxPercent] = useState(0);
    const [vendorPrice, setVendorPrice] = useState(0);
    const [deliveryCost, setDeliveryCost] = useState(0);
    const setAttributes = () => {
        setStorage(
            LocalStorage.getAttribute("storage", true)?.map((item) => ({
                key: item.value,
                value: item.value,
                text: item.label,
            }))
        );

        setMemory(
            LocalStorage.getAttribute("memory", true)?.map((item) => ({
                key: item.value,
                value: item.value,
                text: item.label,
            }))
        );

        setColor(
            LocalStorage.getAttribute("product_color", true)?.map((item) => ({
                key: item.value,
                value: item.value,
                text: (
                    // <div className="color__container">
                    //     <div
                    //         className="color-round"
                    //         style={{
                    //             background: `#${item.label}`,
                    //         }}
                    //     ></div>
                    //     <span className="hex-color">{item.label}</span>
                    // </div>
                    <div>{item.label}</div>
                ),
            }))
        );
    };

    const setData = () => {};

    useEffect(() => {
        const roles = JSON.parse(
            localStorage.getItem("user") as string
        )?.userRoles?.map((user: any) => user?.role);
        setType(roles);
        setAttributes();
    }, []);

    let history = useHistory();
    const vendors = useVendors();
    const initialValues: IProductForm = productInitialValues || {
        vendorSku: "",
        vendorId: "",
        name: "",
        category: "",
        description: "",
        brandId: "",
        markupPrice: "0",
        vendorPrice: "0",
        deliveryCost: "0",
        quantity: "",
        refurbishedStatus: "",
        storage: "",
        memory: "",
        color: "",
        miscellaneousInfo: "",
        priceExcludingTax: "",
        priceIncludingTax: "",
    };

    useEffect(() => {
        ProductService.getAllBrandProducts(
            (brands: Brand[]) => {
                setBrands(
                    brands.map((brand) => ({
                        text: brand.name,
                        key: brand.id,
                        value: brand.id,
                    }))
                );
            },
            () => {}
        );
    }, []);

    const getBrandMarkupPercentage = (vendor: any, brand: any) => {
        if (vendor && brand)
            ProductService.getAllProductBrand(
                {
                    page: 1,
                    brand_id: brand,
                    vendor_id: vendor,
                    search: "",
                },
                (data: VendorBrand[]) => {
                    setMarkupPercentage(data[0].markupPercentage || 0);
                },
                () => {}
            );
    };

    const getTaxPercent = (vendorId: string) => {
        VendorService.getVendorTax(
            vendorId,
            (data: number) => setTaxPercent(data),
            () => {}
        );
    };

    const getMarkupPrice = (
        price: any,
        markupPercent: any = markupPercentage
    ): number => {
        price = Number(price);
        markupPercent = Number(markupPercent);
        const markupPrice = (price * markupPercent) / 100;
        const fixed = markupPrice.toFixed(2);
        return Number(fixed);
    };

    const getPriceIncTax = (
        price: any = vendorPrice,
        tax: any = taxPercent,
        delCost = deliveryCost
    ) => {
        [price, tax, delCost] = [price, tax, delCost].map(Number);
        const taxAmount = (price * tax) / 100;
        const markup = getMarkupPrice(price);
        const priceInclTax = taxAmount + price + delCost + markup;
        const fixed = priceInclTax.toFixed(2);
        return fixed;
    };

    return (
        <div className="product-form">
            <Formik
                initialValues={initialValues}
                validationSchema={productFormValidationSchema}
                onSubmit={(values, actions) => {
                    ProductService.createAdminProducts(
                        values,
                        () => {
                            history.goBack();
                        },
                        () => {}
                    );
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                    setFieldValue,
                    setFieldTouched,
                    handleBlur,
                    touched,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Field>
                                    <label>Vendor SKU</label>
                                    <input
                                        name="vendorSku"
                                        onBlur={handleBlur}
                                        placeholder="SKU"
                                        type="text"
                                        value={values.vendorSku}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={(touched.vendorSku&&errors.vendorSku)?errors.vendorSku:""}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Vendor ID</label>
                                    <Dropdown
                                        placeholder="Select Vendor"
                                        options={vendors}
                                        defaultValue={initialValues.vendorId}
                                        id={
                                            type.includes("Vendor")
                                                ? () => {
                                                      setTimeout(() =>
                                                          setFieldValue(
                                                              "vendorId",
                                                              JSON.parse(
                                                                  localStorage.getItem(
                                                                      "user"
                                                                  ) as string
                                                              )?.id
                                                          )
                                                      );

                                                      return JSON.parse(
                                                          localStorage.getItem(
                                                              "user"
                                                          ) as string
                                                      )?.id;
                                                  }
                                                : null
                                        }
                                        onChange={(_, { value }: any) => {
                                            setTimeout(() =>
                                                setFieldValue("vendorId", value)
                                            );
                                            getTaxPercent(value);
                                            getBrandMarkupPercentage(
                                                value,
                                                values.brandId
                                            );
                                        }}
                                        disabled={type.includes("Vendor")}
                                    />
                                    <FormErrorMessage
                                        message={(touched.vendorId&&errors.vendorId)?errors.vendorId:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Product Name</label>
                                    <input
                                        name="name"
                                        onBlur={handleBlur}
                                        placeholder="Product Name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage message={(touched.name&&errors.name)?errors.name:""} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Product Category</label>
                                    <Dropdown
                                        placeholder="Product Category"
                                        options={[
                                            {
                                                value: "phone",
                                                key: "phone",
                                                text: "phone",
                                            },
                                            {
                                                value: "accessory",
                                                key: "accessory",
                                                text: "accessory",
                                            },
                                        ]}
                                        defaultValue={initialValues.category}
                                        onChange={(_, { value }: any) => {
                                            setTimeout(() =>
                                                setFieldValue("category", value)
                                            );
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={(touched.category&&errors.category)?errors.category:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field className="fullwidth">
                                    <label>Product description</label>
                                    <input
                                        onBlur={handleBlur}
                                        type="text"
                                        name="description"
                                        placeholder="Description"
                                        value={values.description}
                                        onChange={handleChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Brand</label>
                                    <Dropdown
                                        placeholder="Select Brand"
                                        options={brands}
                                        // defaultValue={initialValues.brand}
                                        onChange={(_, { value }: any) => {
                                            setTimeout(() =>
                                                setFieldValue("brandId", value)
                                            );
                                            console.log(value);
                                            getBrandMarkupPercentage(
                                                values.vendorId,
                                                value
                                            );
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={(touched.brandId&&errors.brandId)?errors.brandId:""}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Markup Price</label>
                                    <input
                                        name="markupPrice"
                                        placeholder="Markup Price"
                                        type="text"
                                        value={values.markupPrice}
                                        disabled
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={(touched.markupPrice&&errors.markupPrice)?errors.markupPrice:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Vendor Price</label>
                                    <input
                                        onBlur={handleBlur}
                                        name="vendorPrice"
                                        placeholder="Vendor Price"
                                        type="text"
                                        value={values.vendorPrice}
                                        onChange={({
                                            target: { value },
                                        }: any) => {
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "vendorPrice",
                                                    value
                                                )
                                            );
                                            setVendorPrice(value);
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "markupPrice",
                                                    getMarkupPrice(value)
                                                )
                                            );
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "priceIncludingTax",
                                                    getPriceIncTax(value)
                                                )
                                            );
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "priceExcludingTax",
                                                    getPriceIncTax(value, 0)
                                                )
                                            );
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={(touched.vendorPrice&&errors.vendorPrice)?errors.vendorPrice:""}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Delivery Cost</label>
                                    <input
                                        onBlur={handleBlur}
                                        name="deliveryCost"
                                        placeholder="Delivery Cost"
                                        type="text"
                                        value={values.deliveryCost}
                                        onChange={({
                                            target: { value },
                                        }: any) => {
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "deliveryCost",
                                                    value
                                                )
                                            );
                                            setDeliveryCost(value);
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "priceIncludingTax",
                                                    getPriceIncTax(
                                                        vendorPrice,
                                                        taxPercent,
                                                        value
                                                    )
                                                )
                                            );
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "priceExcludingTax",
                                                    getPriceIncTax(
                                                        vendorPrice,
                                                        0,
                                                        value
                                                    )
                                                )
                                            );
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={(touched.deliveryCost&&errors.deliveryCost)?errors.deliveryCost:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Stock units</label>
                                    <input
                                        onBlur={handleBlur}
                                        name="quantity"
                                        placeholder="Stock Units"
                                        type="text"
                                        value={values.quantity}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={(touched.quantity&&errors.quantity)?errors.quantity:""}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Refurbished Status</label>
                                    <Dropdown
                                        placeholder="Refurbished Status"
                                        options={[
                                            {
                                                text: "New",
                                                value: "new",
                                                key: "new",
                                            },
                                            {
                                                text: "Like new",
                                                value: "like_new",
                                                key: "like_new",
                                            },
                                            {
                                                text: "Excellent",
                                                value: "excellent",
                                                key: "excellent",
                                            },
                                            {
                                                text: "Very good",
                                                value: "very_good",
                                                key: "very_good",
                                            },
                                            {
                                                text: "Good",
                                                value: "good",
                                                key: "good",
                                            },
                                        ]}
                                        defaultValue={
                                            initialValues.refurbishedStatus
                                        }
                                        onChange={(_, { value }: any) =>
                                            setTimeout(() =>
                                                setFieldValue(
                                                    "refurbishedStatus",
                                                    value
                                                )
                                            )
                                        }
                                    />{" "}
                                    <FormErrorMessage
                                        message={(touched.refurbishedStatus&&errors.refurbishedStatus)?errors.refurbishedStatus:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Storage</label>
                                    <Dropdown
                                        placeholder="Select Storage"
                                        options={storage}
                                        disabled={values?.category === "sim"}
                                        onChange={(_, { value }: any) => {
                                            setTimeout(() =>
                                                setFieldValue("storage", value)
                                            );
                                        }}
                                    />
                                    <FormErrorMessage
                                        message={(touched.storage&&errors.storage)?errors.storage:""}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Memory</label>
                                    <Dropdown
                                        placeholder="Select Memory"
                                        disabled={values?.category === "sim"}
                                        options={memory}
                                        onChange={(_, { value }: any) => {
                                            console.log(value);
                                            setFieldValue("memory", value);
                                        }}
                                    />
                                    <FormErrorMessage message={(touched.memory&&errors.memory)?errors.memory:""} />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Color</label>
                                    <Dropdown
                                        placeholder="Select Color"
                                        disabled={values?.category === "sim"}
                                        options={color}
                                        onChange={(_, { value }: any) => {
                                            setTimeout(() =>
                                                setFieldValue("color", value)
                                            );
                                        }}
                                    />
                                    <FormErrorMessage message={(touched.color&&errors.color)?errors.color:""} />
                                </Form.Field>
                                <Form.Field>
                                    <label>Miscellaneous Info</label>
                                    <input
                                        onBlur={handleBlur}
                                        name="miscellaneousInfo"
                                        placeholder="Miscllaneous Info"
                                        type="text"
                                        value={values.miscellaneousInfo}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={(touched.miscellaneousInfo&&errors.miscellaneousInfo)?errors.miscellaneousInfo:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Price (Excluding tax)</label>
                                    <input
                                        onBlur={handleBlur}
                                        name="priceExcludingTax"
                                        placeholder="Price"
                                        type="text"
                                        value={values.priceExcludingTax}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    <FormErrorMessage
                                        message={(touched.priceExcludingTax&&errors.priceExcludingTax)?errors.priceExcludingTax:""}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Price (Including tax)</label>
                                    <input
                                        onBlur={handleBlur}
                                        name="priceIncludingTax"
                                        placeholder="Price"
                                        type="text"
                                        value={values.priceIncludingTax}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    <FormErrorMessage
                                        message={(touched.priceIncludingTax&&errors.priceIncludingTax)?errors.priceIncludingTax:""}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                primary
                                className="float-right"
                                type="submit"
                                disabled={!isValid || !dirty}
                            >
                                {" "}
                                Submit{" "}
                            </Button>
                            <Button
                                className="float-right mr-2"
                                type="button"
                                onClick={() => history.goBack()}
                            >
                                {" "}
                                Cancel
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

ProductForm.propTypes = {};

export default ProductForm;

const useVendors = () => {
    const [vendors, setVendors] = useState<any>([]);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user") as string);
        const roles = user?.userRoles?.map((usr: any) => usr?.role);
        if (roles.includes("Vendor")) {
            setVendors([
                {
                    key: user.id,
                    value: user.id,
                    text: user.name,
                },
            ]);
            return;
        }
        VendorService.getAllVendor(
            (vendors: Vendor[]) => {
                setVendors(
                    vendors.map((vendor: Vendor) => ({
                        key: vendor.id,
                        value: vendor.id,
                        text: vendor.name,
                    }))
                );
            },
            () => {},
            () => {}
        );
    }, []);
    return vendors;
};
