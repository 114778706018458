import axios from "axios";
import { store } from "../../store";
import { REQUEST_LOGOUT } from "../definitions/authConstants";
import * as ApiRoutes from "../../routes/route-constants/api-routes";
import { BASE_NAME, LOGIN } from "../../routes/route-constants/app-routes";
import { useHistory } from "react-router-dom";
import { UserService } from "../services/UserService/User.service";

interface IApiResponse {
    data: any;
    message: string;
    status: number;
}

export const getHeaders = (): any => {
    let headers, access_token: string;
    access_token = localStorage.getItem("access_token") || "";
    headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
    };
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 20000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        let sanitizedResponse: IApiResponse = {
            data: response.data.data,
            message: response.data.message,
            status: response.status,
        };
        return sanitizedResponse;
    },
    (error) => {
        // const history: any = useHistory();
        const {
            response: { status },
        } = error;
        UserService.toastNotify(error, "error");
        if (status === 401 || status === 403) {
            // store.dispatch({
            //     type: REQUEST_LOGOUT,
            //     payload: "Request for logout",
            // });
            // localStorage.clear();
            // // history.push(LOGIN);
            // window.location.replace(BASE_NAME + LOGIN);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
