import React, { useEffect, useState } from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { RadioTable } from "../../../shared/components/RadioTable";
import {
    FinanceOrderDetail,
    VendorOrder,
} from "../../../store/models/Finance/Order.model";
import { OrderService } from "../../../store/services/OrderService/Order.service";
import { Dropdown } from "../../../shared/components/Dropdown";
import { VENDOR_ORDER_STATUS } from "../../../enums/VendorOrderStatus";

const cols: { name: string; text: string }[] = [
    {
        name: "productSku",
        text: "Product SKU",
    },
    {
        name: "orderNumber",
        text: "Purchase Order Number",
    },
    {
        name: "deliveryAddress[name]",
        text: "Customer Name",
    },
    {
        name: "price",
        text: "Price",
    },
    {
        name: "deliveryAddress[region]",
        text: "Shipment Region",
    },
];

interface Props {
    open: boolean;
    setOpen: (x: boolean) => void;
    data: VendorOrder;
    handleFetchData: Function;
    noAccept?: boolean;
}

export const ProductApprovalConfirmationModal = ({
    open,
    setOpen,
    data,
    handleFetchData,
    noAccept,
}: Props) => {
    const [selected, setSelected] = useState<any>({});
    const [length, setLength] = useState(-1);

    const orderStatus: VENDOR_ORDER_STATUS[] = [
        "processing",
        "rejected",
        "accepted",
        "shipped",
        "delivered",
        "returned",
    ];

    const handleApprove = (status: VENDOR_ORDER_STATUS = "accepted") => {
        data?.orderNumber &&
            OrderService.vendorUpdateOrder(
                status,
                data.orderNumber,
                () => {
                    handleFetchData();
                    setOpen(false);
                },
                () => {}
            );
    };

    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className="add-brand-modal"
        >
            <Modal.Content>
                <div className="add-brand-modal__container">
                    <Modal.Actions>
                        {noAccept ? null : (
                            <Button
                                className={`mb-5`}
                                primary
                                disabled={
                                    length !==
                                    Object.values(selected).length - 1
                                }
                                onClick={() => handleApprove()}
                            >
                                <Icon name="check" />
                                Accept Order
                            </Button>
                        )}
                    </Modal.Actions>
                    {data && (
                        <RadioTable
                            noRadio
                            rows={[data]}
                            cols={cols}
                            onChange={(detail) => {
                                const selected = (data: any) => {
                                    return {
                                        ...data,
                                        [detail?.index]: {
                                            order_item: detail.order_item,
                                            vendor_product:
                                                detail.vendor_product,
                                        },
                                    };
                                };
                                setSelected(selected);
                            }}
                        />
                    )}
                </div>
                <div>
                    <table className="order-detail__table">
                        <tr className="order-detail__table-row">
                            <td className="order-detail__table-left">
                                Product Category:
                            </td>
                            <td className="order-detail__table-right">
                                {data?.category}
                            </td>
                        </tr>
                        <tr className="order-detail__table-row">
                            <td className="order-detail__table-left">
                                Product Name:
                            </td>
                            <td className="order-detail__table-right">
                                {data?.productName}
                            </td>
                        </tr>
                        <tr className="order-detail__table-row">
                            <td className="order-detail__table-left">
                                Order date:
                            </td>
                            <td className="order-detail__table-right">
                                {data?.orderDate &&
                                    new Date(data?.orderDate).toLocaleString()}
                            </td>
                        </tr>
                        <tr className="order-detail__table-row">
                            <td className="order-detail__table-left">
                                Order Status:
                            </td>
                            <td className="order-detail__table-right">
                                <Dropdown
                                    val={data.status}
                                    options={orderStatus.map((status) => {
                                        return {
                                            key: status,
                                            value: status,
                                            text: status,
                                            disabled: !orderStatus
                                                .slice(
                                                    orderStatus.findIndex(
                                                        (x) => x == data?.status
                                                    )
                                                )
                                                .includes(status),
                                        };
                                    })}
                                    onChange={(_, { value }: any) => {
                                        handleApprove(value);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr className="order-detail__table-row">
                            <td className="order-detail__table-left">
                                Shipping Address:
                            </td>
                            <tr className="order-detail__table-right">
                                <div>{data?.deliveryAddress?.name}</div>
                                <div>{data?.deliveryAddress?.address}</div>
                                <div>{data?.deliveryAddress?.region}</div>
                                <div>{data?.deliveryAddress?.zip}</div>
                                <div>Phone: {data?.deliveryAddress?.phone}</div>
                            </tr>
                        </tr>
                    </table>
                </div>
            </Modal.Content>
        </Modal>
    );
};
