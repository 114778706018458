import { deserialize } from "serializr";
import {
    GET_ALL_PURCHASE_ORDERS_URL,
    INVOICE_URL,
    MARK_AS_PAID_URL,
} from "../../../routes/route-constants/api-routes";
import { store } from "../../../store";
import { PurchaseOrder } from "../../../store/models/Invoice/purchaseOrder.model";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { Invoice } from "../../models/Invoice/Invoice.model";

export class FinanceService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static listPurchaseOrders(
        vendor_id: any,
        page: number,
        onSuccess: Function,
        onError: Function,
        type?: "regular" | "credit_memo"
    ) {
        const country_id = localStorage.getItem("country");
        const params: any = {
            country_id,
            vendor_id,
            page,
        };

        if (type) {
            params["type"] = type;
        }
        this.addLoader();
        return axiosInstance
            .get(GET_ALL_PURCHASE_ORDERS_URL, {
                params,
            })
            .then((response) => {
                const data = deserialize(PurchaseOrder, response.data?.data);
                onSuccess(
                    data,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static raiseInvoice(
        payload: any,
        vendor_id: any,
        type: "regular" | "credit_memo",
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();

        return axiosInstance
            .post(INVOICE_URL, payload, {
                params: {
                    vendor_id,
                    type,
                },
            })
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static listAllInvoices(
        vendor_id: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();

        const country_id = localStorage.getItem("country");
        return axiosInstance
            .get(INVOICE_URL, {
                params: {
                    country_id,
                    vendor_id,
                },
            })
            .then((response) => {
                const data = deserialize(Invoice, response?.data?.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getInvoicesById(id: any, onSuccess: Function, onError: Function) {
        this.addLoader();

        return axiosInstance
            .get(`${INVOICE_URL}/${id}`)
            .then((response) => {
                const data = deserialize(Invoice, response?.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static markAsPaid(
        id: any,
        payload: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(MARK_AS_PAID_URL(id), payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }
}
