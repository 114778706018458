import React, { MouseEventHandler, PropsWithChildren } from "react";
import PropTypes from "prop-types";
import {
    useTable,
    usePagination,
    TableOptions,
    TableInstance,
    Row,
    useSortBy,
    useRowSelect,
} from "react-table";
import "./reactTable.scss";
import { Icon } from "semantic-ui-react";

export interface ReactTable<T extends object = {}> extends TableOptions<T> {
    name: string;
    onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
    onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
    onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
    getTrProps?: Function;
    onClick?: (row: Row<T>) => void;
    selectable?: boolean;
    headerLeft?: boolean;
    pageCount?: number;
    current?: number;
    handleClick?: Function;
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }: any, ref: any) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <input
                    type="checkbox"
                    className="ui secondary"
                    ref={resolvedRef}
                    {...rest}
                />
            </div>
        );
    }
);

function ReactTable<T extends object>(props: PropsWithChildren<ReactTable<T>>) {
    const {
        name,
        columns,
        onAdd,
        onDelete,
        onEdit,
        onClick,
        data,
        getTrProps,
        selectable,
        pageCount = 0,
        headerLeft,
        handleClick = () => {},
    } = props;
    const initialState = {};
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        // pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    }: any = useTable<T>(
        {
            columns,
            data,
            initialState,
        },
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                // Let's make a column for selection
                {
                    id: "selection",
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({ getToggleAllRowsSelectedProps }: any) =>
                        selectable ? (
                            <div>
                                <IndeterminateCheckbox
                                    onClick={() => {
                                        handleClick(data, "cols");
                                    }}
                                    {...getToggleAllRowsSelectedProps()}
                                />
                            </div>
                        ) : null,
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }: any) =>
                        selectable ? (
                            <div>
                                <IndeterminateCheckbox
                                    onClick={() => {
                                        handleClick(row, "row");
                                    }}
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ) : null,
                },
                ...columns,
            ]);
        }
    );

    return (
        <div className="react-table-wraper">
            <table {...getTableProps()} className="react-table">
                <thead>
                    {headerGroups.map((headerGroup: any) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th
                                    className={`table-header ${
                                        headerLeft ? "align-left" : ""
                                    }`}
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <Icon name="caret down" />
                                            ) : (
                                                <Icon name="caret up" />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row: any, i: number) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                onClick={() =>
                                    getTrProps ? getTrProps(row) : null
                                }
                                className={getTrProps ? "cursor-pointer" : null}
                            >
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {/* <div
                className="react-table__pagination"
                style={{
                    display: canNextPage || canPreviousPage ? "unset" : "none",
                }}
            >
                <button
                    className="react-table__pagination-badge"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    <Icon name="angle left" />
                </button>
                {[...Array(pageCount)].map((x, i) => {
                    return i + 1 <= 4 ? (
                        <button
                            key={i}
                            onClick={() => gotoPage(i)}
                            className={
                                pageIndex === i
                                    ? "react-table__pagination-badge active"
                                    : "react-table__pagination-badge"
                            }
                        >
                            {i + 1}
                        </button>
                    ) : null;
                })}
                <button
                    className="react-table__pagination-badge"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    <Icon name="angle right" />
                </button>
            </div> */}
        </div>
    );
}

export default ReactTable;
