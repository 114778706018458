import React, { useEffect, useState } from "react";
import { UserForm } from "../UserForm";
import { UserService } from "../../../store/services/UserService/User.service";
import { User } from "../../../store/models/User/user.model";
import { Button, Modal, Form } from "semantic-ui-react";
import PageTitle from "../../../shared/components/PageTitle";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { Formik } from "formik";
import { passwordResetValidationSchema } from "./passwordResetValidationSchema";

export const Profile = () => {
    const [user, setUser] = useState<User>();
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        UserService.me(
            (user) => setUser(user),
            () => {},
            () => {
                setLoaded(true);
            }
        );
    }, []);

    const handleEdit = (values: any) => {
        const payload = new FormData();
        payload.append("name", values?.name);
        payload.append("phone", values?.phone);
        if (values?.pic) {
            payload.append("profile_pic", values?.pic);
        }
        // const payload = {
        //     ...values,
        //     profile_pid: values?.pic,
        // };
        console.log(payload);

        UserService.updateMe(
            payload,
            (data: any) => {
                console.log(data);
            },
            () => {},
            () => {}
        );
    };
    interface resetable {
        old_password: string;
        new_password: string;
        confirm_password: string;
    }
    const initialValues: resetable = {
        old_password: "",
        new_password: "",
        confirm_password: "",
    };

    const handleResetPassword = ({ old_password, new_password }: resetable) => {
        UserService.updatePassword(
            { old_password, new_password }, ()=>{}, ()=>{}, ()=>{
                setOpen(false)
            }
        );
    };
    return (
        <div>
            {loaded ? (
                <>
                    <UserForm
                        profilePage
                        initValues={user}
                        onSubmit={handleEdit}
                    />
                    <Button
                        primary
                        className="float-left"
                        onClick={() => setOpen(true)}
                    >
                        Update Password
                    </Button>

                    <Modal
                        closeIcon
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        className="add-brand-modal"
                    >
                        <Modal.Content>
                            <div className="add-brand-modal__container">
                                <PageTitle title="Password Reset" noAction />
                                <Formik
                                    onSubmit={handleResetPassword}
                                    initialValues={initialValues}
                                    validationSchema={
                                        passwordResetValidationSchema
                                    }
                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        values,
                                        errors,
                                        isValid,
                                        dirty,
                                        setFieldValue,
                                    }) => {
                                        return (
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Field>
                                                    <label>Old Password</label>
                                                    <input
                                                        name="old_password"
                                                        placeholder="Old Password"
                                                        type="password"
                                                        value={
                                                            values.old_password
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <FormErrorMessage
                                                        message={
                                                            errors.old_password
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>New Password</label>
                                                    <input
                                                        name="new_password"
                                                        placeholder="New Password"
                                                        type="password"
                                                        value={
                                                            values.new_password
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <FormErrorMessage
                                                        message={
                                                            errors.new_password
                                                        }
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>
                                                        Confirm Password
                                                    </label>
                                                    <input
                                                        name="confirm_password"
                                                        placeholder="Confirm Password"
                                                        type="password"
                                                        value={
                                                            values.confirm_password
                                                        }
                                                        onChange={handleChange}
                                                    />
                                                    <FormErrorMessage
                                                        message={
                                                            errors.confirm_password
                                                        }
                                                    />
                                                </Form.Field>

                                                <Button
                                                    primary
                                                    className="float-right"
                                                    type="submit"
                                                    disabled={
                                                        !isValid || !dirty
                                                    }
                                                >
                                                    {" "}
                                                    Submit{" "}
                                                </Button>
                                                <Button
                                                    className="float-right mr-2"
                                                    type="button"
                                                    onClick={() =>
                                                        setOpen(false)
                                                    }
                                                >
                                                    {" "}
                                                    Cancel
                                                </Button>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </Modal.Content>
                    </Modal>
                </>
            ) : null}
        </div>
    );
};
