import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import { UserForm } from "../UserForm";
import PageTitle from "../../../shared/components/PageTitle";

interface Props {
    open: boolean;
    setOpen: (x: boolean) => void;
    id?: any;
    onData: any;
}
export const CreateUser = ({ open, setOpen, id = false, onData }: Props) => {
    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className="add-brand-modal"
        >
            <Modal.Content>
                <div className="add-brand-modal__container">
                    <PageTitle title="New User Management Admin" noAction />
                    <UserForm controller={setOpen} id={id} onData={onData} />
                </div>
            </Modal.Content>
        </Modal>
    );
};
