import React, { useState, useEffect } from "react";
import { Link, generatePath } from "react-router-dom";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import { RMA } from "../../../store/models/RMA/RMA.mode";
import { RMA_DETAILS } from "../../../routes/route-constants/app-routes";
import "./oneClickRma.scss";
import { RMAService } from "../../../store/services/RMAService/rma.service";

interface Props {
    rma: RMA;
    ticketId: any;
}

export const OneClickRMA = ({ rma, ticketId }: Props) => {
    const [id, setId] = useState(rma?.id);
    const createRMA = () => {
        RMAService.createRMA(ticketId, (rma: RMA) => {
            setId(rma.id);
        });
    };
    useEffect(() => {
        setId(rma?.id);
    }, [rma]);
    if (id) {
        return <Link to={generatePath(RMA_DETAILS, { id })}>{id}</Link>;
    }
    return (
        <span
            className="one-click-rma__generate-button cursor-pointer"
            onClick={createRMA}
        >
            Generate RMA
        </span>
    );
};
