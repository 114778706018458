import * as yup from "yup";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const userValidationSchema = yup.object({
    name: yup.string().label('Name').required().min(3).max(191),
    email: yup.string().label('Email').email().lowercase().max(100).required(),
    phone: yup.string().label('Phone').max(16).required().min(8).max(15).matches(phoneRegExp, "Invalid phone number"),
    country: yup.array().label('Country').of(yup.string()).required("Country is required field"),
    role: yup.string().label('Role').required(),
});

export const userValidationSchemaProfilePage = yup.object({
    name: yup.string().label('Name').required().min(3).max(191),
    email: yup.string().label('Email').email().lowercase().max(100).required(),
    phone: yup.string().label('Phone').max(16).required().min(8).max(15).matches(phoneRegExp, "Invalid phone number"),
    role: yup.string().label('Role').required(),
    pic:  yup.mixed().label('File').nullable() .test('fileSize', "File Size is too large", (value:any) => value == undefined || value?.size <= 1900000) .test('fileType', "Unsupported File Format", (value:any) => value == undefined || ['image/jpeg', 'image/jpg', 'image/png'].includes(value?.type) )
});
