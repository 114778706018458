import React, { useState, useEffect, useCallback } from "react";
import { Icon, Button, Pagination } from "semantic-ui-react";
import { getAllOrders } from "../../../shared/utils/faker_data";
import ReactTable from "../../../shared/components/ReactTable";
import "./waitingOnLycaFinance.scss";
import { ProductApprovalConfirmationModal } from "./ProductApprovalConfirmationModal";
import { useHistory, generatePath } from "react-router";
import { ORDER_DETAIL } from "../../../routes/route-constants/app-routes";
import { fetchOrderService } from "../OrderService/service";
import { OrderService } from "../../../store/services/OrderService/Order.service";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { VendorOrder } from "../../../store/models/Finance/Order.model";
import { boolean } from "yup";
import { VENDOR_ORDER_STATUS } from "../../../enums/VendorOrderStatus";

interface Props {
    text: string;
    filter: any;
}

export const WaitingOnVendorApproval = SearchContainer(
    ({ text, filter }: Props) => {
        const [showModal, setShowModal] = useState(false);
        const [data, setData] = useState<{ [key: string]: any }[]>([]);
        const [current, setCurrent] = useState<any>(null);
        const [pageCount, setPagecount] = useState<any>(null);
        const [order, setOrder] = useState<{
            data?: VendorOrder;
            noAccept?: boolean;
        }>({});
        const history = useHistory();

        const user = JSON.parse(localStorage.getItem("user") as string);
        const role = user?.userRoles?.map((user: any) => user?.role);

        useEffect(() => {
            setData([]);
            (async () => {
                try {
                    const {
                        orders,
                        currentPage,
                        pageCount,
                    }: any = await fetchOrderService({
                        page: 1,
                        type: filter,
                        orderNo: text,
                    });
                    setData(orders);
                    setCurrent(currentPage);
                    setPagecount(pageCount);
                } catch (error) {}
            })();
        }, [text, filter]);

        const reload = async () => {
            try {
                const {
                    orders,
                    currentPage,
                    pageCount,
                }: any = await fetchOrderService({
                    page: current,
                    type: filter,
                });
                setData(orders);
                setCurrent(currentPage);
                setPagecount(pageCount);
                setShowModal(false);
            } catch (error) {
                setShowModal(false);
            }
        };

        const handleFetchData = () => {
            reload();
        };

        const handleRejectOrder = (po: string) => {
            OrderService.vendorUpdateOrder(
                "rejected",
                po,
                () => {
                    handleFetchData();
                },
                () => {}
            );
        };

        const columns = [
            {
                Header: "Purchase Order No",
                accessor: "orderNumber",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "SKU",
                accessor: "productSku",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Order Date",
                accessor: "orderDate",
                Cell: ({ value }: any) => {
                    const date = new Date(value);
                    return `${date.getDate()}/${
                        date.getMonth() + 1
                    }/${date.getFullYear()}`;
                },
            },
            {
                Header: "Amount",
                accessor: "price",
                Cell: ({ value }: any) => <span>€{value}</span>,
            },
            {
                Header: "Customer Name",
                accessor: "deliveryAddress[name]",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Region",
                accessor: "deliveryAddress[region]",
                Cell: ({ value }: any) => value || "--",
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ value }: any) => isFieldEmpty(value),
            },
            {
                Header: "Action",
                Cell: ({ row }: any) => (
                    <>
                        <span
                            className={`waiting-on-lyca-finance__eye cursor-pointer ${
                                filter == "processing" ? "hide" : ""
                            }`}
                            onClick={() => {
                                setShowModal(true);
                                setOrder({
                                    data: row.original,
                                    noAccept: true,
                                });
                            }}
                        >
                            <Icon name="eye" />
                        </span>
                        <Button
                            className={`${
                                filter == "processing" ? "" : "hide"
                            }`}
                            secondary
                            onClick={() => {
                                setShowModal(true);
                                setOrder({ data: row.original });
                            }}
                        >
                            {" "}
                            <Icon name="check" /> Approve
                        </Button>
                        <Button
                            className={`${
                                filter == "processing" ? "" : "hide"
                            }`}
                            color="red"
                            onClick={() => {
                                handleRejectOrder(row.original?.orderNumber);
                            }}
                        >
                            {" "}
                            <Icon name="close" /> Reject
                        </Button>
                    </>
                ),
            },
        ];
        return (
            <div className="waiting-on-lyca-finance">
                <ReactTable headerLeft name="" columns={columns} data={data} />
                {pageCount === 1 || pageCount === null ? null : (
                    <Pagination
                        className="pagenation-container"
                        boundaryRange={0}
                        activePage={current}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={pageCount}
                        onPageChange={async (_, { activePage }: any) => {
                            try {
                                const {
                                    orders,
                                    currentPage,
                                    pageCount,
                                }: any = await fetchOrderService({
                                    page: activePage,
                                });
                                setData(orders);
                                setCurrent(currentPage);
                                setPagecount(pageCount);
                            } catch (error) {}
                        }}
                    />
                )}
                {order?.data && (
                    <ProductApprovalConfirmationModal
                        handleFetchData={handleFetchData}
                        open={showModal}
                        setOpen={setShowModal}
                        data={order?.data}
                        noAccept={order?.noAccept}
                    />
                )}
            </div>
        );
    }
);
