import { takeEvery, call, put, delay } from "redux-saga/effects";
import {
    LIST_VENDOR,
    LIST_VENDOR_SUCCESS,
} from "../definitions/vendorConstants";
import { deserialize } from "serializr";
import { Vendor } from "../models/Vendor/vendor.model";
import { INVOKE_TOASTER } from "../definitions/toastConstants";
import { VENDOR_API_URL } from "../../routes/route-constants/api-routes";
import { ApiService } from "../services/ApiService";
const apiService = new ApiService();

/* Executor Sagas */

function* getVendorsFlow(action: any) {
    const { search, successCallback, errorCallback } = action.payload;
    let response: any;
    try {
        const country_id = localStorage.getItem("country");
        const params:{[x:string]:any} = {country_id};
        if(search){
            params['search'] = search;
        }
        response = yield call(apiService.get, VENDOR_API_URL, params);
        if (response.data) {
            const vendorList = deserialize(Vendor, response.data);
            yield put({ type: LIST_VENDOR_SUCCESS, payload: vendorList });
            successCallback();
        }
    } catch (error) {
        let message = "Something went wrong";
        if (
            error.response &&
            error.response.data &&
            error.response.data["message"]
        ) {
            message = error.response.data["message"];
        }
        yield put({
            type: INVOKE_TOASTER,
            payload: { type: "error", message },
        });
        errorCallback();
    }
}

/*Watcher sagas*/

export default function* VendorSaga() {
    yield takeEvery(LIST_VENDOR, getVendorsFlow);
}
