import React, { useState, useEffect, useCallback } from "react";
import "./userTable.scss";
import {
    useHistory,
    RouteComponentProps,
    withRouter,
    generatePath,
} from "react-router";
import {
    getProcessingOrders,
    getUsers,
} from "../../../shared/utils/faker_data";
import ReactTable from "../../../shared/components/ReactTable";
import { TYPE_USER_ROLE } from "../../../shared/Constants/UserConstants";
import {
    ORDER_DETAIL,
    USER_DETAILS,
    ADD_USER,
} from "../../../routes/route-constants/app-routes";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { UserService } from "../../../store/services/UserService/User.service";
import { CreateUser } from "../CreateUser";
import { PromptModal } from "../../../shared/components/PromptModal";

interface Props extends RouteComponentProps {
    role: TYPE_USER_ROLE;
}

export const UserTable = withRouter(
    ({ role, showModall, setShowModall }: any) => {
        const [data, setData] = useState<any>([]);
        const [showModal, setShowModal] = useState(false);
        const [selected, setSelected] = useState<any>(null);
        const [toDelete, setToDelete] = useState(0);
        const [reload, setReload] = useState(0)

        const history = useHistory();

        const fetchData = () => {
            UserService.getAllUsers(
                role,
                (data: any) => {
                    setData(
                        data?.map((d: any) => {
                            console.log(d?.userCountryMaps);
                            return {
                                ...d,
                                country: d?.userCountryMaps
                                    ?.map((coun: any) => coun?.country?.name)
                                    .join(),
                            };
                        })
                    );
                },
                () => {}
            );
        };
        useEffect(() => {
            fetchData();
        }, [role, reload]);

        const columns = [
            {
                Header: "Name",
                accessor: "name",
                // accessor: "user",
                // Cell: ({ value }: any) => (
                //     <div className="user-table__profile">
                //         <img
                //             className="user-table__profile-pic"
                //             src={value?.profile}
                //         ></img>
                //         {value?.name}
                //     </div>
                // ),
            },
            {
                Header: "Email",
                accessor: "email",
            },
            {
                Header: "Phone",
                accessor: "phoneNo",
            },
            {
                Header: "Country Access",
                accessor: "country",
            },
            {
                Header: "Action",
                Cell: ({ row }: any) => (
                    <span>
                        <span
                            className="user-table__action-button cursor-pointer"
                            onClick={() => {
                                setShowModal(true);
                                setSelected(row?.original?.id);
                            }}
                        >
                            <Icon name="edit" />
                        </span>
                        <span
                            className="user-table__action-button cursor-pointer"
                            onClick={() => {
                                history.push({
                                    pathname: generatePath(USER_DETAILS, {
                                        id: row.original?.id,
                                    }),
                                });
                            }}
                        >
                            <Icon name="eye" />
                        </span>
                        <span
                            className="user-table__action-button cursor-pointer"
                            onClick={() => setToDelete(row.original?.id)}
                        >
                            <Icon name="trash alternate" />
                        </span>
                    </span>
                ),
            },
        ];

        const onAdd = useCallback((data: any) => {
            fetchData();
        }, []);
        return (
            <div className="user-table">
                <ReactTable headerLeft name="" columns={columns} data={data} />
                <CreateUser
                    setOpen={setShowModal}
                    open={showModal}
                    id={selected}
                    onData={onAdd}
                />

                <CreateUser
                    setOpen={setShowModall}
                    open={showModall}
                    onData={onAdd}
                />
                <PromptModal
                    open={Boolean(toDelete)}
                    onClose={() => setToDelete(0)}
                    onSubmit={() => {
                        UserService.deleteUser(toDelete,()=>{
                            setReload((x)=>x+1);
                        });
                    }}
                    buttons={{
                        cancel: "No",
                        submit: "Yes",
                        submitColor: "red",
                    }}
                >
                    Do you really want to delete this user?
                </PromptModal>
            </div>
        );
    }
);
