import React, { useEffect, useState } from "react";
import PageTitle from "../../../shared/components/PageTitle";
import { Button } from "semantic-ui-react";
import { PO_ORDER_STATUS_COLOR } from "../../../shared/Constants/InvoicePurchaseOrder";
import ReactTable from "../../../shared/components/ReactTable";
import { Dropdown } from "../../../shared/components/Dropdown";
import { FinanceService } from "../../../store/services/FinanceService/Finance.service";
import { useHistory, useLocation } from "react-router";
import { Pagination } from "semantic-ui-react";
import "./raiseInvoice.scss";

const invoiceTypes = [
    {
        value: "regular",
        text: "Regular",
        key: "regular",
    },
    {
        value: "credit_memo",
        text: "Credit Memo",
        key: "credit_memo",
    },
];

const columns = [
    {
        Header: "Lyca Order No",
        accessor: "lycaOrderNo",
    },
    {
        Header: "Vendor Order No",
        accessor: "vendorOrderNo",
        Cell: ({ value }: any) => value || "--",
    },
    {
        Header: "PO number",
        accessor: "poNumber",
    },
    {
        Header: "Mark up amount",
        accessor: "vendorMarkupPrice",
    },
    {
        Header: "TAX amount",
        accessor: "tax",
    },
    {
        Header: "Vendor cost",
        accessor: "vendorPrice",
    },
    {
        Header: "Status",
        accessor: "deliveryStatus",
        Cell: ({ value }: any) => (
            <>
                <span
                    className="status-color"
                    style={{ background: PO_ORDER_STATUS_COLOR[value] }}
                ></span>
                <span>{value}</span>
            </>
        ),
    },
];
export const RaiseInvoice = () => {
    const history = useHistory();
    const location = useLocation();
    const [invoices, setInvoices] = useState<any>([]);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const [selected, setSelected] = useState<any>("regular");
    const [payload, setPayload] = useState<any>([]);
    const [cols, setCols] = useState(false);

    const fetchFinanceInvoices = (
        type: "regular" | "credit_memo",
        page: number
    ) => {
        const id = location.search?.split("=")[1];
        FinanceService.listPurchaseOrders(
            id,
            page,
            (invoices: any, current: number, lastPage: number) => {
                setInvoices(invoices);
                setCurrent(current);
                setPagecount(lastPage);
            },
            () => {},
            type
        );
    };

    useEffect(() => {
        fetchFinanceInvoices("regular", 1);
    }, []);

    useEffect(() => {
        if (cols) {
            setPayload(invoices?.map((data: any) => data?.id));
        }
    }, [cols]);

    const handleProceed = () => {
        const id = location.search?.split("=")[1];
        FinanceService.raiseInvoice(
            {
                po_ids: payload,
            },
            id,
            selected,
            () => {
                history.goBack();
            },
            () => {}
        );
    };

    return (
        <div className="raise-invoice">
            <PageTitle title="Raise Invoice" />
            <div className="raise-invoice__card mb-6">
                <div className="raise-invoice__select">
                    <div>Select Type</div>
                    <Dropdown
                        placeholder="Select type"
                        options={invoiceTypes}
                        val="regular"
                        onChange={(_, { value }: any) => {
                            fetchFinanceInvoices(value, current);
                            setSelected(value);
                        }}
                    />
                </div>
                <div>
                    <Button
                        className="mr-2 ml-2"
                        primary
                        onClick={handleProceed}
                    >
                        <span>Proceed</span>
                    </Button>
                </div>
            </div>
            <ReactTable
                selectable
                columns={columns}
                headerLeft
                name=""
                data={invoices}
                handleClick={(data: any, type: "row" | "cols") => {
                    if (type === "cols") {
                        setCols((state) => !state);
                        return;
                    }
                    setPayload((existing: any) => {
                        setCols(false);
                        if (existing.includes(data?.original?.id)) {
                            return existing?.filter(
                                (value: any) => value !== data?.original?.id
                            );
                        }
                        return [...existing, data?.original?.id];
                    });
                }}
            />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        fetchFinanceInvoices(selected, activePage);
                        setCurrent(activePage);
                    }}
                />
            )}
        </div>
    );
};
