import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./productList.scss";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import VendorRequestsTable from "../VendorRequestsTable";
import MagentoProductsTable from "../MagentoProductsTable";
import MasterProductsTable from "../MasterProductsTable";
import * as appRoutes from "../../../routes/route-constants/app-routes";
import { BrandsAndMarkup } from "../BrandsAndMarkup";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import MyProducts from "../MyProducts";
import AuthContainer from "../../../store/containers/authContainer";

function ProductList({ authenticated }: any) {
    const [activeTab, setActiveTab] = useState("");
    const [update, setUpdate] = useState(false);
    const [type, setType] = useState<any>([]);
    useEffect(() => {
        const user = JSON.parse(
            localStorage.getItem("user") as string
        )?.userRoles?.map((user: any) => user?.role);
        setType(user);
        if (user?.includes("Vendor")) {
            setActiveTab("myProducts");
        } else {
            setActiveTab("vendorRequests");
        }
    }, [authenticated]);

    const tabs = [
        {
            name: "myProducts",
            text: "My Products",
            component: <MyProducts />,
        },
        {
            name: "vendorRequests",
            text: "Vendor Request",
            component: <VendorRequestsTable />,
        },
        {
            name: "magentoProducts",
            text: "Magento - Pending approvals",
            component: <MagentoProductsTable />,
        },
        {
            name: "masterProducts",
            text: "Master Products",
            component: <MasterProductsTable update={update} />,
        },
        {
            name: "brandsMarkup",
            text: "Brands & Markup",
            component: <BrandsAndMarkup />,
        },
    ];

    const VendorTabs = [
        {
            name: "myProducts",
            text: "My Products",
            component: <MyProducts />,
        },
        {
            name: "masterProducts",
            text: "Master Products",
            component: <MasterProductsTable update={update} />,
        },
    ];

    const activeTabIndex = _.findIndex(tabs, { name: activeTab });
    return (
        <div className="product-list">
            <div className="product-list__header">
                {type?.includes("Vendor")
                    ? VendorTabs?.map((tab, i) => {
                          return (
                              <Button
                                  secondary={activeTab === tab.name}
                                  key={i}
                                  onClick={() => setActiveTab(tab.name)}
                              >
                                  {" "}
                                  {tab.text}{" "}
                              </Button>
                          );
                      })
                    : tabs.map((tab, i) => {
                          if (
                              !type?.includes("Vendor") &&
                              tab?.name === "myProducts"
                          ) {
                              return null;
                          }
                          return (
                              <Button
                                  secondary={activeTab === tab.name}
                                  key={i}
                                  onClick={() => setActiveTab(tab.name)}
                              >
                                  {" "}
                                  {tab.text}{" "}
                              </Button>
                          );
                      })}
                {getSideButton(
                    activeTab,
                    () => {
                        setUpdate((state) => !state);
                    },
                    !type?.includes("Vendor")
                )}
            </div>
            <div className="product-list__body">
                {activeTabIndex >= 0 && tabs[activeTabIndex].component}
            </div>
        </div>
    );
}

const getSideButton = (tab: string, callback: () => void, showSync = true) => {
    let style: { [key: string]: string } = {};
    !showSync && (style["display"] = "none");
    switch (tab) {
        case "vendorRequests":
            return (
                <Link to={appRoutes.ADD_PRODUCT}>
                    <Button className="float-right" secondary>
                        <Icon name="plus" />
                        New Product{" "}
                    </Button>
                </Link>
            );
        case "masterProducts":
            return (
                <div className="float-right">
                    <Button
                        style={style}
                        primary
                        onClick={() => {
                            ProductService.syncMasterProducts(
                                () => {
                                    callback();
                                },
                                () => {}
                            );
                        }}
                    >
                        <Icon name="sync alternate" />
                        Fetch Products{" "}
                    </Button>
                    <Link to={appRoutes.ADD_PRODUCT}>
                        <Button secondary>
                            <Icon name="plus" />
                            New Product{" "}
                        </Button>
                    </Link>
                </div>
            );
        case "magentoProducts":
            return (
                <div className="float-right">
                    <Link to={appRoutes.ADD_PRODUCT}>
                        <Button secondary>
                            <Icon name="plus" />
                            New Product{" "}
                        </Button>
                    </Link>
                    <Button primary>Approve All </Button>
                </div>
            );
        case "myProducts":
            return (
                <Link to={appRoutes.ADD_PRODUCT}>
                    <Button className="float-right" secondary>
                        <Icon name="plus" />
                        New Product{" "}
                    </Button>
                </Link>
            );
        default:
            return null;
    }
};

ProductList.propTypes = {};

export default AuthContainer(ProductList);
