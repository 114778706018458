import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { setSearch } from "../actions/searchAction";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        text: state.search.text,
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            setSearch,
        },
        dispatch
    );

const SearchContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default SearchContainer;
