import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import Canvas from "../../../Canvas/canvasjs.react";
import { DateRange } from "../../../shared/components/DateRange";
import { namedDebounce } from "../../../shared/utils/debounce";
import { DashboardService } from "../../../store/services/DashboardService/Dashboard.service";
import "./revenueChart.scss";

const CanvasJSChart = Canvas.CanvasJSChart;

type chartTypes = "month" | "week";

const month = [
    { x: new Date(2017, 0), y: 25060 },
    { x: new Date(2017, 1), y: 27980 },
    { x: new Date(2017, 2), y: 42800 },
    { x: new Date(2017, 3), y: 32400 },
    { x: new Date(2017, 4), y: 35260 },
    { x: new Date(2017, 5), y: 33900 },
    { x: new Date(2017, 6), y: 40000 },
    { x: new Date(2017, 7), y: 52500 },
    { x: new Date(2017, 8), y: 32300 },
    { x: new Date(2017, 9), y: 42000 },
    { x: new Date(2017, 10), y: 37160 },
    { x: new Date(2017, 11), y: 38400 },
];

const week = [
    { x: new Date(2017, 0), y: 23060 },
    { x: new Date(2017, 1), y: 26980 },
    { x: new Date(2017, 2), y: 40800 },
    { x: new Date(2017, 3), y: 32400 },
    { x: new Date(2017, 4), y: 36260 },
    { x: new Date(2017, 5), y: 37900 },
    { x: new Date(2017, 6), y: 40000 },
    { x: new Date(2017, 7), y: 52500 },
    { x: new Date(2017, 8), y: 32300 },
    { x: new Date(2017, 9), y: 22000 },
    { x: new Date(2017, 10), y: 39160 },
    { x: new Date(2017, 11), y: 48400 },
];

let chartRef: any;

const RevenueChart = () => {
    const [selected, setSelected] = useState<chartTypes>("month");
    const [date, setDate] = useState<{
        start_date: String;
        end_date: String;
    }>();
    const [dataPoints, setDataPoints] = useState<
        { label: String; y: number }[]
    >([]);

    const handleChart = (type: chartTypes) => {
        console.log(chartRef);
        chartRef.options.data[0].dataPoints = type === "month" ? month : week;
        chartRef?.render();
        setSelected(type);
    };

    useEffect(() => {
        namedDebounce(
            ()=>{
                if (date) {
                    if (selected == "month") {
                        DashboardService.getRevenueMonth(date, (data) => {
                            let temp = data?.revenue?.map((x) => ({
                                label: (x.month || "") + " " + (x.year || ""),
                                y: x.amount || 0,
                            }));
                            setDataPoints(temp || []);
                        });
                    } else {
                        DashboardService.getRevenueWeek(date, (data) => {
                            let temp = data?.revenue?.map((x) => ({
                                label: (x.week || "") + " " + (x.year || ""),
                                y: x.amount || 0,
                            }));
                            setDataPoints(temp || []);
                        });
                    }
                }
            },
            400,
            "revenue_chart_debounce"
        )
    }, [date, selected]);

    const options = {
        animationEnabled: true,
        theme: "light2",
        axisX: {
            // valueFormatString: "MMM",
        },
        axisY: {
            prefix: "£",
            color: "red",
        },
        data: [
            {
                markerType: "none",
                yValueFormatString: "£#,###",
                // xValueFormatString: "MMMM",
                type: "spline",
                color: "#0BB14E",
                dataPoints: dataPoints,
            },
        ],
    };

    return (
        <div className="revenue-chart">
            <div className="header">
                <p>Revenue</p>
                {/* <div className="toggle-chart">
                </div> */}
                <div className="toggle-chart">
                    {/* <input type="date" min="2021-04-17"></input> */}
                    <DateRange onDateChange={setDate} />
                    {/* <Dropdown className="dropdown-date-selector" options={dropwownOptions} defaultValue={1} /> */}
                    <span
                        className={`btn ${
                            selected === "month" ? "btn-active" : ""
                        }`}
                        onClick={() => handleChart("month")}
                    >
                        <p>Month</p>
                    </span>
                    <span
                        className={`btn ${
                            selected === "week" ? "btn-active" : ""
                        }`}
                        onClick={() => handleChart("week")}
                    >
                        <p>Week</p>{" "}
                    </span>
                </div>
            </div>
            <CanvasJSChart
                options={options}
                onRef={(ref: any) => (chartRef = ref)}
            />
        </div>
    );
};

export default RevenueChart;
