import {alias, primitive, serializable, object, list} from "serializr";

export class Meta1 {
    @serializable(alias('name', primitive()))
    text?: string;

    @serializable(alias('name', primitive()))
    key?: string;

    @serializable(alias('name', primitive()))
    value?: string;
}


export class Meta2 {
    @serializable(alias('name', primitive()))
    text?: string;

    @serializable(alias('id', primitive()))
    key?: string;

    @serializable(alias('id', primitive()))
    value?: string;
}
