import React from "react";
import { Form, Button } from "semantic-ui-react";
import { Formik } from "formik";
import * as yup from "yup";
import "./loginForm.scss";
import { Login } from "../../../store/models/Login/login.model";
import { serialize } from "serializr";
import AuthContainer from "../../../store/containers/authContainer";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { ForgotPassword } from "../ForgotPassword";

const loginValidationSchema = yup.object({
    email: yup
        .string()
        .email("Email is not valid")
        .lowercase()
        .required("Email is required"),
    password: yup.string().required("Password is required"),
});

interface ILoginForm {
    email: string;
    password: string;
}

function LoginForm(props: any) {
    const initialValues: ILoginForm = { email: "", password: "" };
    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={loginValidationSchema}
                onSubmit={(values, actions) => {
                    const loginObject = Object.assign(new Login(), values);
                    props.signInAction(
                        loginObject,
                        props.history,
                        () => {},
                        (err:any) => {
                            console.log(err);
                            
                        }
                    );
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                }) => {
                    return (
                        <div className="login-form">
                            <h1 className="text-heading">Login</h1>
                            <Form onSubmit={handleSubmit}>
                                <Form.Field>
                                    <input
                                        className="input-login"
                                        name="email"
                                        placeholder="Email Address"
                                        type="email"
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage message={errors.email} />
                                </Form.Field>
                                <Form.Field>
                                    <input
                                        className="input-login"
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={errors.password}
                                    />
                                </Form.Field>
                                <ForgotPassword />
                                <Button
                                    className="float-right w-100 login-btn"
                                    type="submit"
                                    disabled={!isValid || !dirty}
                                >
                                    {" "}
                                    Login{" "}
                                </Button>
                            </Form>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
}

export default AuthContainer(LoginForm);
