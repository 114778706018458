import React from "react";
import PropTypes from "prop-types";
import "./createTicket.scss";
import PageTitle from "../../../shared/components/PageTitle";
import TicketForm from "../TicketForm";

function CreateTicket() {
    return (
        <div className="create-ticket">
            <PageTitle title="New Ticket" />
            <div className="create-ticket__form-wrapper">
                <TicketForm />
            </div>
        </div>
    );
}

CreateTicket.propTypes = {};

export default CreateTicket;
