import { deserialize } from "serializr";
import {
    COUNTRIES_META_API,
    ORDER_STATUS,
    SHIPPING_ADDRESS_META_API,
    TAX_API,
} from "../../routes/route-constants/api-routes";
import { store } from "../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../definitions/loaderConstants";
import axiosInstance from "../interceptors/axiosInterceptor";
import {
    CountryMeta,
    ShippingZoneMeta,
    TaxTypeMeta,
} from "../models/Meta/CountryMeta/countryMeta.model";

export class MetaService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static shippingDeliveryAddress(
        id: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .get(`${SHIPPING_ADDRESS_META_API}?country_id=${id}`)
            .then((response) => {
                const shippingZone = deserialize(
                    ShippingZoneMeta,
                    response.data
                );
                onSuccess(shippingZone);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getCountries(
        onSuccess: Function,
        onError: Function,
        onFinal?: () => void
    ) {
        this.addLoader();
        return axiosInstance
            .get(COUNTRIES_META_API)
            .then((response) => {
                const countries = deserialize(CountryMeta, response.data);
                onSuccess(countries);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                if (onFinal) {
                    onFinal();
                }
                this.removeLoader();
            });
    }

    static getTaxType(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(TAX_API)
            .then((response) => {
                const taxType = deserialize(TaxTypeMeta, response.data);
                onSuccess(taxType);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }
    static getOrderStaus(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(ORDER_STATUS)
            .then((response) => {
                onSuccess(response?.data);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }
}
