import React, { useEffect, useState } from "react";
import "./ticketDetails.scss";
import { getTicketDetails } from "../../../shared/utils/faker_data";
import PageTitle from "../../../shared/components/PageTitle";
import { RouteComponentProps, withRouter, generatePath } from "react-router";
import { type } from "os";
import { Link } from "react-router-dom";
import { Icon, Button } from "semantic-ui-react";
import {
    EDIT_TICKET,
    ORDER_DETAIL,
} from "../../../routes/route-constants/app-routes";
import { TicketService } from "../../../store/services/TicketService/ticket.service";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import { OneClickRMA } from "../../RMA/OneClickRMA";

type keys =
    | "userOrderId"
    | "orderItemName"
    | "email"
    | "mobileNo"
    | "issueType"
    | "issueDesc"
    | "assignedGroup"
    | "assignedUserName"
    | "csTeamGroup"
    | "rma"
    | "issueUpdates"
    | "issueStatus"
    | "priority"
    | "dueDate"
    | "additionalDetails"
    | "userOrderAddress"
    | "userOrderStatus"
    | "attachments";

const ticketProperties: { label: string; key: keys; dataType?: string }[] = [
    {
        label: "Order Id",
        key: "userOrderId",
    },
    {
        label: "Product",
        key: "orderItemName",
    },
    {
        key: "email",
        label: "Customer Email address",
    },
    {
        key: "mobileNo",
        label: "Customer Phone Number",
    },
    {
        key: "issueType",
        label: "Issue Type",
    },
    {
        key: "issueDesc",
        label: "Issue Description",
    },
    {
        key: "assignedGroup",
        label: "Group Assigned",
    },
    {
        key: "assignedUserName",
        label: "User Assigned",
    },
    {
        key: "csTeamGroup",
        label: "CS Team Group",
    },
    {
        key: "rma",
        label: "RMA number",
        dataType: "rma",
    },
    {
        key: "issueUpdates",
        label: "Issue Updates",
    },
    {
        key: "issueStatus",
        label: "Issue Status",
    },
    {
        key: "priority",
        label: "Priority",
    },
    {
        key: "dueDate",
        label: "Due Date",
    },
    {
        key: "additionalDetails",
        label: "Additional Details",
    },
    {
        key: "userOrderAddress",
        label: "Shipping Information",
    },
    {
        key: "userOrderStatus",
        label: "Order Status",
    },
    {
        key: "attachments",
        label: "Attachments",
        dataType: "array",
    },
];

export const TicketDetails = withRouter(({ match }: RouteComponentProps) => {
    const { params }: any = match;
    const [ticket, setTicket] = useState<any>({});

    useEffect(() => {
        TicketService.getTicketById(
            params?.id,
            (ticket: Ticket) => {
                setTicket({
                    ...ticket,
                    orderItemName: ticket.orderItem?.masterProduct?.name,
                    assignedUserName: ticket?.assignedUser?.name,
                    userOrderAddress: ticket?.userOrder?.address,
                    userOrderStatus: ticket?.userOrder?.orderStatus,
                });
            },
            () => {}
        );
    }, []);

    const render = (x: { label: string; key: keys; dataType?: string }) => {
        switch (x.dataType) {
            case "array":
                return (
                    <div className="ticket-detail__array">
                        {ticket[x.key]?.map((item: any) => (
                            <div className="ticket-detail__array-item">
                                <Link to={item.link}>
                                    <Icon name="download" />
                                    {item.name || "--"}
                                </Link>
                            </div>
                        ))}
                    </div>
                );
            case "rma":
                return <OneClickRMA rma={ticket.rma} ticketId={params?.id} />;

            default:
                return ticket[x.key] || "--";
        }
    };

    return (
        <div>
            <PageTitle title={`${params.id}`} />
            <Link
                to={generatePath(EDIT_TICKET, { id: params.id })}
                className="float-right"
            >
                <Icon name="edit" />
                edit
            </Link>
            <div className="ticket-detail__body">
                {ticketProperties.map((property) => {
                    console.log(property);
                    return (
                        <div className="ticket-detail__row">
                            <span className="ticket-detail__label">
                                {property.label}
                            </span>
                            <span className="ticket-detail__value">
                                {render(property)}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
