import React, { useState } from "react";
import { Modal, Header, Button, Icon, Input } from "semantic-ui-react";
import { ProductService } from "../../../store/services/ProductService/Product.service";

interface Props {
    open: boolean;
    setClose: () => void;
}
export const AddBrand = ({ open, setClose }: Props) => {
    const [brand, setBrand] = useState("");
    return (
        <Modal
            closeIcon
            open={open}
            onClose={() => setClose()}
            // onOpen={() => setOpen(true)}
            className="add-brand-modal"
        >
            <Modal.Content>
                <div className="add-brand-modal__container">
                    <div className="mb-2">Enter Brand Name</div>
                    <Input
                        type="text"
                        name="brand"
                        placeholder="Brand Name"
                        className="mb-4"
                        onChange={({ target: { value } }: any) => {
                            setBrand(value);
                        }}
                    />
                    <Modal.Actions>
                        <Button onClick={setClose}>Cancel</Button>
                        <Button
                            color="green"
                            onClick={() => {
                                ProductService.createBrand(
                                    {
                                        name: brand,
                                    },
                                    () => {
                                        setClose();
                                    },
                                    () => {}
                                );
                            }}
                        >
                            Submit
                        </Button>
                    </Modal.Actions>
                </div>
            </Modal.Content>
        </Modal>
    );
};
