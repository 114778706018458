import React, { useEffect, useState } from "react";
import { Link, useHistory, generatePath } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import {
    ADD_TICKET,
    TICKET_DETAILS,
} from "../../../routes/route-constants/app-routes";
import ReactTable from "../../../shared/components/ReactTable";

import {
    ISSUE_STATUS_COLOR,
} from "../../../shared/Constants/CustomerSupport";
import { TicketService } from "../../../store/services/TicketService/ticket.service";
import { Ticket } from "../../../store/models/Ticket/ticket.model";
import "./ticketList.scss";
import SearchContainer from "../../../store/containers/searchContainer";
import { namedDebounce } from "../../../shared/utils/debounce";

const isFieldEmpty = (value: any) => {
    return value || "--"
}

function TicketList({ text }: { text: string }) {
    const [tickets, setTickets] = useState<any>([]);
    const [search, setSearch] = useState(text);

    const handleTicket = () => {
        TicketService.getAllTicket(
            { search: text },
            (tickets: Ticket[]) => {
                setTickets(tickets);
            },
            () => { }
        );
    };

    useEffect(()=>{
        namedDebounce(
            () => setSearch(text),
            250,
            "SEARCH_WaitingOnFinanceOrders"
        )
    },[text])

    useEffect(() => {
        handleTicket();
    }, [search]);

    console.log({ text });
    const history = useHistory();
    const columns = [
        {
            Header: "Ticket Id",
            accessor: "id",
            Cell: ({ value }: any) => isFieldEmpty(value)
        },
        {
            Header: "Order Id",
            accessor: "userOrder[orderNo]",
            Cell: ({ value }: any) => isFieldEmpty(value)

        },
        {
            Header: "Email",
            accessor: "email",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Issue Type",
            accessor: "issueType",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Assigned To",
            accessor: "assignedUser[name]",
            Cell: ({ value }: any) =>
                value ? (
                    value
                ) : (
                        <span style={{ color: "red" }}>Unassigned</span>
                    ),
        },
        {
            Header: "CS Team Group",
            accessor: "csTeamGroup",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Due Date",
            accessor: "dueDate",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "Status",
            accessor: "issueStatus",
            Cell: ({ value }: any) => (
                <>
                    <span
                        className="status-color"
                        style={{ background: ISSUE_STATUS_COLOR[value] }}
                    ></span>
                    <span>{value}</span>
                </>
            ),
        },
    ];

    return (
        <div className="ticket-list">
            <Link to={ADD_TICKET}>
                <Button className="float-right mb-6" secondary>
                    <Icon name="plus" />
                    New Ticket{" "}
                </Button>
            </Link>
            <ReactTable
                headerLeft
                name=""
                columns={columns}
                data={tickets}
                getTrProps={(e: any) => {
                    history.push(
                        generatePath(TICKET_DETAILS, { id: e?.original?.id })
                    );
                }}
            />
        </div>
    );
}

TicketList.propTypes = {};

export default SearchContainer(TicketList);
