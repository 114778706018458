import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import "./appSidebar.scss";
import lycaLogo from "../../../assets/images/lyca-logo.png";
import { Link, withRouter } from "react-router-dom";
import * as appRoutes from "../../../routes/route-constants/app-routes";
import AuthContainer from "../../../store/containers/authContainer";

const findCommon = (arr1: string[], arr2: string[]) => {
    if (!arr1) {
        return;
    }
    return arr1.some((item) => arr2.includes(item));
};

function AppSidebar(props: any) {
    const { pathname } = props.location;
    const { authenticated } = props;
    const [activeItem, setActiveItem] = useState("");
    const handleItemClick = (e: any, { name }: any) => setActiveItem(name);
    const [type, setType] = useState<any>([]);

    useEffect(() => {
        const user = JSON.parse(
            localStorage.getItem("user") as string
        )?.userRoles?.map((user: any) => user?.role);
        setType(user);
    }, [authenticated]);

    return authenticated ? (
        <Menu secondary vertical className="app-sidebar">
            <Link to={{ pathname: appRoutes.DASHBOARD, state: { search: "" } }}>
                <div className="app-sidebar__logo-wrapper">
                    <img src={lycaLogo} alt="Lyca logo" />
                </div>
            </Link>
            <Menu.Item
                as={Link}
                to={{ pathname: appRoutes.DASHBOARD, state: { search: "" } }}
                className="app-sidebar__menu-item"
                name="dashboard"
                active={pathname === appRoutes.DASHBOARD}
                onClick={handleItemClick}
            >
                <i className="icon-dashboard" />
                Dashboard
            </Menu.Item>
            {findCommon(type, ["Finance", "Admin"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.VENDORS}
                    className="app-sidebar__menu-item"
                    name="vendors"
                    active={
                        pathname.includes(appRoutes.VENDORS) ||
                        pathname.includes(appRoutes.ADD_VENDOR)
                    }
                    onClick={handleItemClick}
                >
                    <i className="icon-vendors" />
                    Vendors
                </Menu.Item>
            ) : null}
            {findCommon(type, ["Admin", "Product", "Vendor"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.PRODUCTS}
                    className="app-sidebar__menu-item"
                    name="products"
                    active={
                        pathname.includes(appRoutes.PRODUCTS) ||
                        pathname.includes(appRoutes.ADD_PRODUCT)
                    }
                    onClick={handleItemClick}
                >
                    <i className="icon-products" />
                    Products
                </Menu.Item>
            ) : null}

            {findCommon(type, ["Finance", "Support", "Vendor", "Admin"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.ORDERS}
                    className="app-sidebar__menu-item"
                    name="orders"
                    active={pathname.includes(appRoutes.ORDERS)}
                    onClick={handleItemClick}
                >
                    <i className="icon-orders" /> Orders
                </Menu.Item>
            ) : null}
            {findCommon(type, ["Finance", "Support", "Admin"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.TICKETS}
                    className="app-sidebar__menu-item"
                    name="tickets"
                    active={
                        pathname.includes(appRoutes.TICKETS) ||
                        pathname.includes(appRoutes.ADD_TICKET)
                    }
                    onClick={handleItemClick}
                >
                    <i className="icon-tickets" /> Tickets
                </Menu.Item>
            ) : null}

            {findCommon(type, ["Finance", "Vendor", "Admin"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.INVOICE}
                    className="app-sidebar__menu-item"
                    active={
                        pathname.includes(appRoutes.INVOICE) ||
                        pathname.includes(appRoutes.INVOICE)
                    }
                    name="invoice"
                    onClick={handleItemClick}
                >
                    <i className="icon-purchase-order" /> Invoice
                </Menu.Item>
            ) : null}
            {findCommon(type, ["Admin"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.RMA}
                    className="app-sidebar__menu-item"
                    active={
                        pathname.includes(appRoutes.RMA) ||
                        pathname.includes(appRoutes.RMA)
                    }
                    name="invoice"
                    onClick={handleItemClick}
                >
                    <i className="icon-purchase-order" /> RMA
                </Menu.Item>
            ) : null}
            {findCommon(type, ["Admin"]) ? (
                <Menu.Item
                    as={Link}
                    to={appRoutes.USER}
                    className="app-sidebar__menu-item"
                    active={
                        pathname.includes(appRoutes.USER) ||
                        pathname.includes(appRoutes.USER)
                    }
                    name="users"
                    onClick={handleItemClick}
                >
                    <i className="icon-users" /> Users
                </Menu.Item>
            ) : null}
        </Menu>
    ) : null;
}

AppSidebar.propTypes = {};

export default withRouter(AuthContainer(AppSidebar));
