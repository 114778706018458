import {all} from 'redux-saga/effects';
import AuthSaga from "./authSaga";
import ToastSaga from "./toastSaga";
import VendorSaga from "./vendorSaga";
export default function* RootSaga() {
    yield  all([
        AuthSaga(),
        ToastSaga(),
        VendorSaga()
    ]);
}
