import React, { useState, FC } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";
import "./imageUpload.scss";

interface ImageUploadProps {
    label?: string;
    maxNumber?: number;
    onUpload: (image: any) => void;
}

const ImageUpload: FC<ImageUploadProps> = ({
    label,
    onUpload,
    maxNumber = 1,
}) => {
    const [images, setImages] = useState([]);

    const onChange = (
        imageList: ImageListType,
        addUpdateIndex: number[] | undefined
    ) => {
        console.log(imageList, addUpdateIndex);
        setImages(imageList as never[]);
        onUpload(imageList);
    };

    return (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => (
                <div className="upload__image-wrapper">
                    <button
                        type="button"
                        className="image-upload"
                        style={isDragging ? { color: "red" } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                    >
                        <i className="plus circle icon"></i>
                        {label ?? "Choose your file"}
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image.dataURL} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                                <i
                                    className="edit outline icon image-ico"
                                    onClick={() => onImageUpdate(index)}
                                />
                                <i
                                    className="trash alternate outline icon image-ico"
                                    onClick={() => onImageRemove(index)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </ImageUploading>
    );
};

export default ImageUpload;
