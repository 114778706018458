export const ISSUE_TYPES = ["Cancellation", "Return", "Dead on Arrival"];

export const CS_TEAM_GROUPS = ["Admin", "Manager", "Executive"];

export const ISSUE_STATUS = ["Invalid", "Closed", "Open", "In Progress"];
// export const ISSUE_STATUS_COLOR = ["#F53C56", "#2DCE98", "#11CDEF", "#FB6340"];

type IssueStatusColorType = "Invalid" | "Closed" | "Open" | "In progress"



export const ISSUE_STATUS_COLOR: any = {
    Invalid: "#F53C56",
    Closed: "#2DCE98",
    Open: "#11CDEF",
    "In progress": "#FB6340",
};
