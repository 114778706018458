import { serializable, alias, primitive, object, list } from "serializr";
import { Brand } from "../Brand/Brand.model";
import { Vendor } from "./vendor.model";

export class VendorBrand {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("vendor_id", primitive()))
    vendorId?: number;

    @serializable(alias("brand_id", primitive()))
    brandId?: number;

    @serializable(alias("markup_percentage", primitive()))
    markupPercentage?: number;

    @serializable(alias("vendor", object(Vendor)))
    vendor?: Vendor;

    @serializable(alias("brand", object(Brand)))
    brand?: Brand;
}