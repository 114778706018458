import { deserialize, serialize } from "serializr";
import {
    GET_TICKETS_URL,
    GET_TICKET_URL,
} from "../../../routes/route-constants/api-routes";
import { store } from "../../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { Ticket } from "../../models/Ticket/ticket.model";

export class TicketService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static getAllTicket(
        options: {
            search?: string;
        },
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        const country_id = localStorage.getItem("country");
        let params: {
            country_id: string | null;
            ticket_id?: string;
        } = {
            country_id,
        };
        if (options?.search) {
            params = {
                ...params,
                ticket_id: options.search,
            };
        }
        return axiosInstance
            .get(GET_TICKETS_URL(country_id), {
                params,
            })
            .then((response) => {
                const Tickets = deserialize(Ticket, response.data);
                onSuccess(Tickets);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getTicketById(
        ticketId: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        const country_id = localStorage.getItem("country");
        return axiosInstance
            .get(GET_TICKET_URL(country_id, ticketId), {
                params: {
                    id: ticketId,
                },
            })
            .then((response) => {
                const Tickets = deserialize(Ticket, response.data);
                onSuccess(Tickets);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static createTicket(payload: any, onSuccess: Function, onError: Function) {
        const country_id = localStorage.getItem("country") as string;
        const data = serialize(Ticket, payload);
        const formData = new FormData();
        formData.append("country_id", country_id);
        if (payload.attachments) {
            payload.attachments?.map(
                (attachment: { file: File }, index: number) => {
                    console.log(attachment);
                    formData.append(
                        `attachments[${index}][document]`,
                        attachment?.file
                    );
                }
            );
        }
        for (let key in data) {
            formData.append(key, data[key]);
        }
        this.addLoader();
        return axiosInstance
            .post(GET_TICKETS_URL(country_id), formData)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static editTicket(
        id: any,
        payload: Ticket,
        onSuccess: Function,
        onError: Function
    ) {
        const country_id = localStorage.getItem("country");
        const data = serialize(Ticket, payload);
        this.addLoader();
        return axiosInstance
            .put(`${GET_TICKETS_URL(country_id)}/${id}`, {
                ...data,
                country_id,
            })
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }
}
