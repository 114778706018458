import { alias, primitive, serializable, object, list } from "serializr";

export class ProductMeta {
    @serializable(alias("order_item_id", primitive()))
    orderItemId?: number;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("email", primitive()))
    email?: string;

    @serializable(alias("phone", primitive()))
    phone?: string;

    @serializable(alias("order_item_id", primitive()))
    value?: string;

    @serializable(alias("order_item_id", primitive()))
    key?: string;

    @serializable(alias("name", primitive()))
    text?: string;
}
