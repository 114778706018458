import { User } from "../../store/models/Auth/user.model";
import { Category } from "../../store/models/Meta/CategoryModel";
import { ATTRIBUTE_TYPES } from "../../enums/attributeTypes";
import { Attributes } from "../../store/models/Meta/AttributesModel";

export interface ResolvedAttributes {
    label: string;
    value: string;
}

type attributes_type = { [x in ATTRIBUTE_TYPES]?: ResolvedAttributes[] };

const prefix = "OMS_";

type key =
    | "token"
    | "user"
    | "guest_cart"
    | "product_category"
    | "attributes"
    | "country";
type value = string | User | Category | attributes_type;

export default class LocalStorage {
    static getValue(key: "token", addPrefix?:boolean): string;
    static getValue(key: "user", addPrefix?:boolean): User;
    static getValue(key: "guest_cart", addPrefix?:boolean): string;
    static getValue(key: "product_category", addPrefix?:boolean): Category;
    static getValue(key: "attributes", addPrefix?:boolean): attributes_type;
    static getValue(key: "country", addPrefix?:boolean): string;

    static getValue(key: key, addPrefix:boolean=false) {
        let accessKey = `${addPrefix?prefix:''}${key}`;
        let value = localStorage.getItem(accessKey);
        return value && JSON.parse(value);
    }

    static setValue(key: "token", value: string, addPrefix?:boolean): void;
    static setValue(key: "user", value: User, addPrefix?:boolean): void;
    static setValue(key: "guest_cart", value: string, addPrefix?:boolean): void;
    static setValue(key: "product_category", value: Category, addPrefix?:boolean): void;
    static setValue(key: "attributes", value: attributes_type, addPrefix?:boolean): void;

    static setValue(key: key, value: value, addPrefix:boolean=false) {
        let accessKey = `${addPrefix?prefix:''}${key}`;

        localStorage.setItem(accessKey, JSON.stringify(value));
    }

    static removeValue(key: key, addPrefix:boolean=false) {
        let accessKey = `${addPrefix?prefix:''}${key}`;
        localStorage.removeItem(accessKey);
    }

    static isSet(key: key, addPrefix:boolean=false) {
        let accessKey = `${addPrefix?prefix:''}${key}`;
        return localStorage.getItem(accessKey) !== null;
    }

    /* 
        Use bellow functions to deal with attributes
    */
    static setAttribute(key: ATTRIBUTE_TYPES, value: Attributes, addPrefix:boolean=false) {
        const attributes: ResolvedAttributes[] = value.options.filter(
            (x) => x.value.length
        );
        let toSave: attributes_type = {
            [key]: attributes,
        };
        if (LocalStorage.isSet("attributes", addPrefix)) {
            const currentValue = LocalStorage.getValue("attributes", true);
            toSave = {
                ...currentValue,
                ...toSave,
            };
        }
        LocalStorage.setValue("attributes", toSave, addPrefix );
    }

    static getAttribute(key: ATTRIBUTE_TYPES, addPrefix:boolean=false) {
        try {
            const attribute = LocalStorage.getValue("attributes", addPrefix);
            return attribute[key];
        } catch (error) {
            console.log({ error: error.message });
        }
    }
}
