import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { addLoader, removeLoader } from "../actions/loader";

const mapStateToProps = (state: any, ownProps: any) => {
    return {
        loader: state.loader.loaderCount,
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            addLoader,
            removeLoader,
        },
        dispatch
    );

const LoaderContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default LoaderContainer;
