import { deserialize } from "serializr";
import {
    CS_Team_GROUP_URL,
    GROUP_URL,
    ISSUE_PRIORITIES_URL,
    ISSUE_STATUS_URL,
    ISSUE_TYPE_URL,
    PRODUCT_LIST_URL,
    USER_ASSIGNED_URL,
    ORDER_STATUS,
    META_API,
} from "../../../routes/route-constants/api-routes";
import { store } from "../../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { ProductMeta } from "../../models/Meta/CountryMeta/Product.model";
import { Meta1, Meta2 } from "../../models/Meta/meta.model";

export class MetaService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static getProductList(
        orderId: string,
        onSuccess: Function,
        onError: Function
    ) {
        if (orderId) {
            this.addLoader();
            return axiosInstance
                .get(PRODUCT_LIST_URL(orderId))
                .then((response) => {
                    const products = deserialize(ProductMeta, response.data?.products);
                    const data = {
                        email: response.data?.email,
                        phone: response.data?.phone,
                        products
                    }
                    onSuccess(data);
                })
                .catch((error) => {
                    onError(error);
                })
                .finally(() => {
                    this.removeLoader();
                });
        }
    }

    static getCSTeamGroup(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(CS_Team_GROUP_URL)
            .then((response) => {
                const data = deserialize(Meta1, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getGroup(onSuccess: Function, onError: Function) {
        this.addLoader();

        return axiosInstance
            .get(GROUP_URL)
            .then((response) => {
                const data = deserialize(Meta1, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getIssueProperties(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(ISSUE_PRIORITIES_URL)
            .then((response) => {
                const data = deserialize(Meta1, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getIssueStatus(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(ISSUE_STATUS_URL)
            .then((response) => {
                const data = deserialize(Meta1, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getIssueType(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(ISSUE_TYPE_URL)
            .then((response) => {
                const data = deserialize(Meta1, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getUserAssigned(
        userType: string,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        const country_id = localStorage.getItem("country") as string;
        return axiosInstance
            .get(USER_ASSIGNED_URL(country_id), {
                params: {
                    country_id,
                    role: userType,
                },
            })
            .then((response) => {
                const data = deserialize(Meta2, response.data);
                onSuccess(data);
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getIssueTypes(onSuccess: (issueTypes: Meta1) => void) {
        this.addLoader();

        return axiosInstance.get(META_API.ISSUE_TYPES).then(response => {
            const issueTypes = deserialize(Meta1, response?.data);
            onSuccess(issueTypes)
        }).catch(error => {
            console.log(error?.message);
        }).finally(() => {
            this.removeLoader();
        })
    }
}
