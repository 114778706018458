import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, generatePath } from "react-router";
import "./vendorRequestsTable.scss";
import ReactTable from "../../../shared/components/ReactTable";
import { getProducts } from "../../../shared/utils/faker_data";
import * as appRoutes from "../../../routes/route-constants/app-routes";
import { Pagination } from "semantic-ui-react";
import { EditableCell } from "../../../shared/components/ReactTable/EditableCell";
import { ActionButton } from "../../../shared/components/ReactTable/ActionButton";
import { ProductMergeModal } from "./ProductMergeModal";
import { ProductService } from "../../../store/services/ProductService/Product.service";
import LocalStorage from "../../../shared/utils/LocalStorage";
import SearchContainer from "../../../store/containers/searchContainer";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";
import { namedDebounce } from "../../../shared/utils/debounce";

function VendorRequestsTable(props: any) {
    const { history, text } = props;
    const [search, setSearch] = useState(text);
    const [showMerge, setShowMerge] = useState(false);
    const [edit, setEdit] = useState(undefined);
    const [products, setProducts] = useState<any[]>([]);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const [prod, setProd] = useState<any>(null);

    const fetchPendingProducts = (pageNumber: number) => {
        ProductService.getAllProductFilter(
            pageNumber,
            "pending",
            props?.text,
            (list: any, currentPage: number, lastPage: number) => {
                // console.log({list,currentPage,lastPage})
                setProducts(list);
                setCurrent(currentPage);
                setPagecount(lastPage);
            },
            () => {},
            () => {}
        );
    };

    useEffect(()=>{
        namedDebounce(
            () => setSearch(text),
            250,
            "SEARCH_WaitingOnFinanceOrders"
        )
    },[text])

    useEffect(() => {
        if (!showMerge) {
            fetchPendingProducts(1);
        }
    }, [showMerge, search]);
    const updateMyData = (index: number, id: string, value: any) => {
        setProducts(([...prev]) => {
            prev[index][id] = value;
            return prev;
        });
    };

    const memory = LocalStorage.getAttribute("memory", true);
    const storage = LocalStorage.getAttribute("storage", true);
    const color = LocalStorage.getAttribute("product_color", true);

    const columns = [
        {
            Header: "Product Name",
            accessor: "name",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "vendor Name",
            accessor: "vendor[name]",
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        {
            Header: "SKU",
            accessor: "sku",
            style: { whiteSpace: "unset", maxWidth: "3.5rem" },
            Cell: ({ value }: any) => isFieldEmpty(value),
        },
        // {
        //     Header: "Storage",
        //     accessor: "storage",
        //     Cell: ({ value }: any) =>
        //         value
        //             ? storage?.find((item) => item?.value === value)?.label
        //             : "--",
        // },
        {
            Header: "Memory",
            accessor: "memory",
            Cell: ({ value }: any) => (
                value
                    ? memory?.find((item) => item?.value === value)?.label ||
                      value
                    : value
            )
        },
        {
            Header: "Color",
            accessor: "color",
            Cell: ({ value }: any) => {
                const productColor = color?.find(
                    (item) => item?.value === value
                )?.label;
                return value ? (
                    // ? color?.find((item) => item?.value === value)?.label
                    <div>
                        {/* <div
                            style={{
                                background: `#${productColor}`,
                                color: `#${productColor}`,
                                border: "1px solid #eee",
                                borderRadius: 100,
                                height: 15,
                                width: 15,
                                display: "inline-block",
                                paddingRight: "4px",
                            }}
                        ></div> */}
                        <span>{value}</span>
                    </div>
                ) : (
                    "--"
                );
            },
        },
        {
            Header: "Vendor Price",
            accessor: "vendorPrice",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Markup Price",
            accessor: "markupPrice",
            Cell: (all: any) => (
                edit === all.row.id ? (
                    EditableCell({ ...all, updateMyData })
                ) : (
                    <span className="vendor-request__markup-price__price">
                        {all.value}
                    </span>
                )
            )
        },
        {
            Header: "Delivery price",
            accessor: "deliveryCost",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Price (Excluding tax)",
            accessor: "priceExcludingTax",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Price (Including tax)",
            accessor: "priceIncludingTax",
            Cell: ({ value }: any) => `€${value}`,
        },
        {
            Header: "Action",
            // accessor: "action",
            Cell: ({ row, value }: any) => (
                <ActionButton
                    merge={(data: any) => {
                        setShowMerge(true);
                        setProd(data);
                    }}
                    row={row}
                    value={value}
                    edit={edit}
                    setEdit={setEdit}
                />
            ),
        },
    ];

    return (
        <div>
            <ReactTable name="" columns={columns} data={products} />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        fetchPendingProducts(activePage);
                    }}
                />
            )}
            <ProductMergeModal
                prods={prod}
                open={prod && showMerge}
                onClose={() => setShowMerge(false)}
            />
        </div>
    );
}

VendorRequestsTable.propTypes = {};

export default withRouter(SearchContainer(VendorRequestsTable));
