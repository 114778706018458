import { Formik } from "formik";
import * as yup from 'yup'
import React, { Fragment, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import PageTitle from "../../../shared/components/PageTitle";
import "./forgotPassword.scss";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import { UserService } from "../../../store/services/UserService/User.service";
import { useHistory } from "react-router";
import { LOGIN } from "../../../routes/route-constants/app-routes";

export const ForgotPassword = () => {
    const [open, setOpen] = useState(false);

    const history = useHistory()
    const handleSubmit = (values:any) => {
        UserService.forgotPassword(
            values,
            ()=>{
            },
            ()=>{},
            ()=>{
                setOpen(false)
            },
            
        )
    };

    const initialValues = {
        email: "",
    };

    const validationSchema = yup.object({
        email : yup.string().email().required().max(120)
    });

    return (
        <Fragment>
            <span
            onClick={()=>setOpen(true)}
            className="forgot-password">Forgot password?</span>
            <Modal
                closeIcon
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                className="add-brand-modal forgot-password-modal"
            >
                <Modal.Content>
                    <div className="add-brand-modal__container">
                        <PageTitle title="Forgot Password" noAction />
                        <Formik
                            onSubmit={handleSubmit}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                errors,
                                isValid,
                                dirty,
                                setFieldValue,
                            }) => {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Field>
                                            <label>Email</label>
                                            <input
                                                name="email"
                                                placeholder="Email"
                                                type="text"
                                                value={values.email}
                                                onChange={handleChange}
                                            />
                                            <FormErrorMessage
                                                message={errors.email}
                                            />
                                        </Form.Field>

                                        <Button
                                            primary
                                            className="float-right"
                                            type="submit"
                                            disabled={!isValid || !dirty}
                                        >
                                            {" "}
                                            Submit{" "}
                                        </Button>
                                        <Button
                                            className="float-right mr-2"
                                            type="button"
                                            onClick={() => setOpen(false)}
                                        >
                                            {" "}
                                            Cancel
                                        </Button>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </Modal.Content>
            </Modal>
        </Fragment>
    );
};
