import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "./orderList.scss";
import { Button } from "semantic-ui-react";
import { AllOrders } from "../AllOrders";
import { WaitingOnFinanceOrders } from "../WaitingOnFinanceOrders";
import { ProcessingOrders } from "../ProcessingOrders";
import { OtherOrders } from "../OtherOrders";
import { WaitingOnVendorApproval } from "../WaitingOnVendorApproval";
import { snakecaseToTitleCase } from "../../../shared/utils/StringConvertor";

function OrderList() {
    const user = JSON.parse(localStorage.getItem("user") as string);
    const role = user?.userRoles?.map((user: any) => user?.role);
    const isVendor = role?.includes("Vendor");
    // debugger
    const [activeTab, setActiveTab] = useState("all");
    const [type, setType] = useState<any>([]);
    const [tabs, setTabs] = useState<
        { name: string; text: string; component: any }[]
    >([]);
    useEffect(() => {
        setType(role);
        if (role?.includes("Vendor")) {
            setActiveTab("finance");
        }

        if (role?.includes("Support")) {
            setActiveTab("processing");
        }
        let temp = [
            {
                name: "all",
                text: "All",
                component: <AllOrders />,
            },
            {
                name: "finance",
                text: "Waiting on Lyca Finance",
                component: isVendor ? (
                    <WaitingOnVendorApproval filter="processing" />
                ) : (
                    <WaitingOnFinanceOrders />
                ),
            },
            {
                name: "processing",
                text: "Processing",
                component: isVendor ? (
                    <WaitingOnVendorApproval filter="accepted" />
                ) : (
                    <ProcessingOrders filter="processing" />
                ),
            },
            {
                name: "complete",
                text: "Complete",
                component: isVendor ? (
                    <WaitingOnVendorApproval filter="shipped" />
                ) : (
                    <OtherOrders type="processed" />
                ),
            },
            {
                name: "closed",
                text: "Closed",
                component: isVendor ? (
                    <WaitingOnVendorApproval filter="delivered" />
                ) : (
                    <OtherOrders type="completed" />
                ),
            },
            {
                name: "others",
                text: "Others",
                component: isVendor ? (
                    <WaitingOnVendorApproval filter="others" />
                ) : (
                    <OtherOrders type="others" />
                ),
            },
        ];
        setTabs(temp);
    }, []);
    const activeTabIndex = _.findIndex(tabs, { name: activeTab });
    const getTabName = (text: string) => {
        if (isVendor)
            switch (text) {
                case "finance":
                    return "Pending";
                case "processing":
                    return "Accepted";
                case "complete":
                    return "Shipped";
                case "closed":
                    return "Delivered";
                default:
                    return snakecaseToTitleCase(text);
            }
        else
            switch (text) {
                case "finance":
                    return "Waiting On Lyca Finance"
                case "complete":
                    return "Processed"
                case "closed":
                    return "Completed"
                default:
                    return snakecaseToTitleCase(text);
            }
    };
    return (
        <div className="order-list">
            <div className="order-list__header">
                {tabs.map((tab, i) => {
                    if (type?.includes("Vendor")) {
                        if (tab?.name === "all") {
                            return null;
                        }
                    }
                    if (type?.includes("Support")) {
                        if (tab?.name === "all" || tab?.name === "finance") {
                            return null;
                        }
                    }
                    return (
                        <Button
                            secondary={activeTab === tab.name}
                            key={i}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            {" "}
                            {getTabName(tab.name)}{" "}
                        </Button>
                    );
                })}
            </div>
            <div className="order-list__body">
                {activeTabIndex >= 0 && tabs[activeTabIndex].component}
            </div>
        </div>
    );
}

OrderList.propTypes = {};

export default OrderList;
