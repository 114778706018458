import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from "semantic-ui-react";
import './vendorList.scss';
import VendorTile from "../VendorTile";
import {Link} from "react-router-dom";
import * as appRoutes from '../../../routes/route-constants/app-routes';
import VendorContainer from "../../../store/containers/vendorContainer";
import {Vendor} from "../../../store/models/Vendor/vendor.model";
import SearchContainer from '../../../store/containers/searchContainer';

function VendorList(props: any) {
    const { getVendorActions, vendorList } = props;
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true);
        getVendorActions(() => {
            setLoader(false);
        }, () => {
            setLoader(false);
        }, props?.text);
    }, [props?.text]);
    return (
        <div className='vendor-list'>
            <div className='text-right'>
                <Link to={appRoutes.ADD_VENDOR}> <Button primary> New Vendor </Button> </Link>
            </div>
            <div className='vendor-list__vendor-tiles-wrapper'>
                {vendorList.map((vendor: Vendor, i: number) => {
                    return(
                        <VendorTile vendor={vendor} key={i}/>
                    )
                })}
                {vendorList.length <= 0 && !loader && <div> No vendors found. Please add a new vendor</div>}
            </div>
        </div>
    );
}

VendorList.propTypes = {
};

export default SearchContainer(VendorContainer(VendorList));
