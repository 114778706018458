import React from "react";
import Stats from "./Stats";
import "./dashboard.scss";
import RevenueChart from "./RevenueChart";
import Chart from "./Chart";

function Dashboard() {
    return (
        <div className="dashboard">
            <Stats />
            <RevenueChart />
            <Chart />
        </div>
    );
}

Dashboard.propTypes = {};

export default Dashboard;
