import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {signInAction} from "../actions/authActions";

export default function AuthContainer(component: any) {
    return connect(mapStateToProps, mapDispatchToProps)(component);
}

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators({signInAction}, dispatch);
}

function mapStateToProps(state: any) {
    return {
        successMessage:state.auth.successMessage,
        errorMessage: state.auth.error,
        userRole: state.auth.userRole,
        userName: state.auth.userName,
        authenticated: state.auth.authenticated
    };
}
