import { Formik } from 'formik';
import React from 'react'
import { Button, Form, Modal } from 'semantic-ui-react';
import FormErrorMessage from '../../../shared/components/FormErrorMessage';
import PageTitle from '../../../shared/components/PageTitle';
import { UserService } from '../../../store/services/UserService/User.service';
import * as yup from "yup"


interface Props{
    open:boolean|number; 
    setOpen:React.Dispatch<boolean|number>;
    parentModelClose: ()=>void
}
export const ResetPasswordForAnyUser = ({open, setOpen, parentModelClose}:Props) => {

    interface resetable{
        new_password:string;
        confirm_password:string;
    } 
    const initialValues:resetable= {
        new_password:"",
        confirm_password:""
    }

    const handleResetPassword = ({new_password}:resetable) => {
        UserService.resetPassword(
            open,
            { new_password }, ()=>{}, ()=>{}, ()=>{
                setOpen(false);
                parentModelClose();
            }
        );
    };

    const passwordResetValidationSchema = yup.object({
        new_password: yup.string().required().min(8).max(40),
        confirm_password: yup
            .string()
            .required()
            .min(8)
            .max(40)
            .oneOf([yup.ref("new_password"), null], "Passwords must match"),
    });
    
    return (
        <Modal
            closeIcon
            open={Boolean(open)}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            className="add-brand-modal"
        >
            <Modal.Content>
                <div className="add-brand-modal__container">
                    <PageTitle title="Password Reset" noAction />
                    <Formik
                        onSubmit={handleResetPassword}
                        initialValues={initialValues}
                        validationSchema={passwordResetValidationSchema}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            isValid,
                            dirty,
                            setFieldValue,
                        }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Form.Field>
                                        <label>New Password</label>
                                        <input
                                            name="new_password"
                                            placeholder="New Password"
                                            type="password"
                                            value={values.new_password}
                                            onChange={handleChange}
                                        />
                                        <FormErrorMessage
                                            message={errors.new_password}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Confirm Password</label>
                                        <input
                                            name="confirm_password"
                                            placeholder="Confirm Password"
                                            type="password"
                                            value={values.confirm_password}
                                            onChange={handleChange}
                                        />
                                        <FormErrorMessage
                                            message={errors.confirm_password}
                                        />
                                    </Form.Field>

                                    <Button
                                        primary
                                        className="float-right"
                                        type="submit"
                                        disabled={!isValid || !dirty}
                                    >
                                        {" "}
                                        Submit{" "}
                                    </Button>
                                    <Button
                                        className="float-right mr-2"
                                        type="button"
                                        onClick={() => setOpen(false)}
                                    >
                                        {" "}
                                        Cancel
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Modal.Content>
        </Modal>
    );
}
