import axiosInstance from "../interceptors/axiosInterceptor";
import { get_paramed_uri } from "../../shared/utils/URI";
import {
    GET_VENDOR_PRODUCTS_API,
    VENDOR_API_URL,
    VENDOR_DETAILS_API_URL,
    GET_VENDOR_PRODUCT_API,
    GET_VENDOR_TAX_PERCENTAGE,
} from "../../routes/route-constants/api-routes";
import { deserialize } from "serializr";
import { Vendor } from "../models/Vendor/vendor.model";
import { Product, VendorProductModel } from "../models/Vendor/VendorProduct";
import { store } from "../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../definitions/loaderConstants";
import { generatePath } from "react-router";
import { INVOKE_TOASTER } from "../definitions/toastConstants";

export class VendorService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static async getVendor(
        id: string,
        onSuccess: Function,
        onError: Function,
        onEnd?: () => void
    ) {
        this.addLoader();
        return axiosInstance
            .get(get_paramed_uri(VENDOR_DETAILS_API_URL, { id }))
            .then((response) => {
                const { data } = response;
                const vendor = deserialize(Vendor, data);
                onSuccess(vendor);
            })
            .catch((error) => {
                console.log(error);
                onError();
            })
            .finally(() => {
                onEnd && onEnd();
                this.removeLoader();
            });
    }

    static async getAllVendor(
        onSuccess: Function,
        onError: Function,
        onEnd?: () => void
    ) {
        const params = {
            country_id: localStorage.getItem("country"),
        };
        this.addLoader();

        return axiosInstance
            .get(VENDOR_API_URL, {
                params,
            })
            .then((response) => {
                const { data } = response;
                const vendor = deserialize(Vendor, data);
                onSuccess(vendor);
            })
            .catch((error) => {
                console.log(error);
                onError();
            })
            .finally(() => {
                onEnd && onEnd();
                this.removeLoader();
            });
    }

    static createVendor(
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        const payload = new FormData();
        payload.append("name", data?.companyName);
        payload.append("email", data?.emailAddress);
        payload.append("phone_no", data?.phoneNumber);
        payload.append("point_of_contact", data?.pointOfContact);
        payload.append("address", data?.address);
        payload.append("city", data?.city);
        payload.append("country_id", data?.country);
        payload.append("zip_code", data?.zipCode);
        payload.append("company_logo", "");
        payload.append("vendor_tax_rule[tax_type_id]", data?.taxType);
        payload.append("vendor_tax_rule[tax_percentage]", data?.taxPercentage);
        payload.append(
            "vendor_tax_rule[default_markup_percentage]",
            data?.markupPercentage
        );
        payload.append(
            "vendor_shipment_address[address]",
            data?.shipmentAddress
        );
        payload.append("vendor_shipment_address[city]", data?.shipmentCity);
        payload.append(
            "vendor_shipment_address[zip_code]",
            data?.shipmentZipCode
        );
        payload.append(
            "vendor_shipment_address[country_id]",
            data?.shipmentCountry
        );

        data?.shipmentZones?.forEach((zone: any, index: number) => {
            payload.append(
                `vendor_shipment_rules[${index}][shipment_region_id]`,
                zone
            );
        });
        payload.append(
            "vendor_bank_detail[account_number]",
            data?.accountNumber
        );
        payload.append("vendor_bank_detail[sort_code]", data?.sortCode);
        payload.append("vendor_bank_detail[bank_name]", data?.bankName);
        if (data?.companyLogo) {
            payload.append("company_logo", data?.companyLogo?.file);
        }
        this.addLoader();
        return axiosInstance
            .post(VENDOR_API_URL, payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                store.dispatch({
                    type: INVOKE_TOASTER,
                    payload: {
                        type: "error",
                        message:
                            error?.response?.data?.message || "Something went wrong",
                    },
                });
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static vendorProducts(
        vendorId: number,
        pageNumber: number,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .get(`${GET_VENDOR_PRODUCTS_API(vendorId)}?page=${pageNumber}`)
            .then((response) => {
                const vendorProducts = deserialize(
                    VendorProductModel,
                    response.data?.data
                );
                onSuccess(
                    vendorProducts,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getVendorProduct(
        vendorProductId: number,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .get(GET_VENDOR_PRODUCT_API(vendorProductId))
            .then((response) => {
                const product = deserialize(Product, response.data);
                onSuccess(product);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getVendorTax(
        vendorId: string,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .get(generatePath(GET_VENDOR_TAX_PERCENTAGE, { id: vendorId }))
            .then((response) => {
                const vendorTaxPercentage = response.data.tax_percentage;
                onSuccess(vendorTaxPercentage);
            })
            .catch((error) => onError(error))
            .finally(() => this.removeLoader());
    }
}
