import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./productDetail.scss";
import PageTitle from "../../../shared/components/PageTitle";
import { Link, generatePath, RouteComponentProps } from "react-router-dom";
import { EDIT_PRODUCT } from "../../../routes/route-constants/app-routes";
import { Icon } from "semantic-ui-react";
import { VendorService } from "../../../store/services/Vendor.service";
import { Product } from "../../../store/models/Vendor/VendorProduct";

type keys =
    | "sku"
    | "vendorId"
    | "name"
    | "category"
    | "description"
    | "brand"
    | "markupPrice"
    | "vendorPrice"
    | "deliveryCost"
    | "quantity"
    | "refurbishedStatus"
    | "storage"
    | "memory"
    | "color"
    | "miscellaneousInfo"
    | "priceExcludingTax"
    | "priceIncludingTax";

const productProperties: { label: string; key: keys }[] = [
    {
        label: "Vendor SKU",
        key: "sku",
    },
    {
        label: "Vendor Id",
        key: "vendorId",
    },
    {
        key: "name",
        label: "Product Name",
    },
    {
        key: "category",
        label: "Product Category",
    },
    {
        key: "description",
        label: "Description",
    },
    {
        key: "brand",
        label: "Brand",
    },
    {
        key: "markupPrice",
        label: "Markup Price",
    },
    {
        key: "vendorPrice",
        label: "Vendor Price",
    },
    {
        key: "deliveryCost",
        label: "Delivery Cost",
    },
    {
        key: "quantity",
        label: "Stock Units",
    },
    {
        key: "refurbishedStatus",
        label: "Refurbished Status",
    },
    {
        key: "storage",
        label: "Storage",
    },
    {
        key: "memory",
        label: "Memory",
    },
    {
        key: "color",
        label: "Color",
    },
    {
        key: "miscellaneousInfo",
        label: "Miscellaneous Info",
    },
    {
        key: "priceExcludingTax",
        label: "Price (Excluding Tax)",
    },
    {
        key: "priceIncludingTax",
        label: "Price (Including Tax)",
    },
];

interface IProduct {
    sku: string;
    vendorId: string;
    name: string;
    category: string;
    description: string;
    brand: string;
    markupPrice: string;
    vendorPrice: string;
    deliveryCost: string;
    quantity: string;
    refurbishedStatus: string;
    storage: string;
    memory: string;
    color: string;
    miscellaneousInfo: string;
    priceExcludingTax: string;
    priceIncludingTax: string;
}

const mock: { [key in keys]: string } = {
    sku: "some-sku",
    vendorId: "some-id",
    name: "I Phone",
    category: "smart phone",
    description: "This is apple i phone",
    brand: "apple",
    markupPrice: "string",
    vendorPrice: "100",
    deliveryCost: "150",
    quantity: "2",
    refurbishedStatus: "new",
    storage: "64GB",
    memory: "8GB",
    color: "gold",
    miscellaneousInfo: "something",
    priceExcludingTax: "120",
    priceIncludingTax: "140",
};

interface Props extends RouteComponentProps {}

function ProductDetail({ match }: Props) {
    const { params }: any = match;
    const [product, setProduct] = useState<any>(mock);

    useEffect(() => {
        VendorService.getVendorProduct(
            params?.productId,
            (product: Product) => {
                setProduct({...product, brand: product?.brand?.name});
            },
            () => {}
        );
    }, []);

    return (
        <div className="product-detail">
            <PageTitle title="Product Details" />
            <Link
                to={generatePath(EDIT_PRODUCT, { id: params.productId })}
                className="float-right"
            >
                <Icon name="edit" />
                Edit
            </Link>
            <div className="product-detail__body">
                {productProperties.map((property) => (
                    <div className="product-detail__row">
                        <span className="product-detail__label">
                            {property.label}
                        </span>
                        <span className="product-detail__value">
                            {product[property.key]}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

ProductDetail.propTypes = {};

export default ProductDetail;
