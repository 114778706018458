import React, { useEffect, useState } from "react";
import {
    getOptions,
    gettickets,
    getPurchaseOrderList,
    getDropdownOptions,
} from "../../../shared/utils/faker_data";
import { Button } from "semantic-ui-react";
import ReactTable from "../../../shared/components/ReactTable";
import "./purchaseOrder.scss";
import {
    ISSUE_STATUS_COLOR,
    ISSUE_STATUS,
} from "../../../shared/Constants/CustomerSupport";
import {
    PO_ORDER_STATUS,
    PO_ORDER_STATUS_COLOR,
} from "../../../shared/Constants/InvoicePurchaseOrder";
import { Link } from "react-router-dom";
import { RAISE_INVOICE } from "../../../routes/route-constants/app-routes";
import { Dropdown } from "../../../shared/components/Dropdown";
import { FinanceService } from "../../../store/services/FinanceService/Finance.service";
import { MetaService } from "../../../store/services/MetaService/Meta.service";
import { Pagination } from "semantic-ui-react";
import isFieldEmpty from "../../../shared/utils/isFieldEmpty";

const columns = [
    {
        Header: "Lyca Order No",
        accessor: "lycaOrderNo",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Vendor Order No",
        accessor: "vendorOrderNo",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "PO number",
        accessor: "poNumber",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Invoice generated",
        accessor: "invoiceId",
        Cell: ({ value }: any) =>
            value ? (
                <span style={{ color: "green" }}>True</span>
            ) : (
                <span style={{ color: "red" }}>False</span>
            ),
    },
    {
        Header: "Mark up amount",
        accessor: "vendorMarkupPrice",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "TAX amount",
        accessor: "tax",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Vendor cost",
        accessor: "vendorPrice",
        Cell: ({ value }: any) => isFieldEmpty(value),
    },
    {
        Header: "Status",
        accessor: "deliveryStatus",
        Cell: ({ value }: any) => (
            <>
                <span
                    className="status-color"
                    style={{ background: PO_ORDER_STATUS_COLOR[value] }}
                ></span>
                <span>{value}</span>
            </>
        ),
    },
];
const data: any = getPurchaseOrderList(15);
export const PurchaseOrder = () => {
    const [vendors, setVendors] = useState<any>([]);
    const [invoices, setInvoices] = useState<any>([]);
    const [selected, setSelected] = useState<any>(null);
    const [current, setCurrent] = useState<any>(null);
    const [pageCount, setPagecount] = useState<any>(null);
    const [type, setType] = useState<any>([]);
    const [id, setId] = useState("");
    const getFinanceData = (id: any, page: number) =>
        FinanceService.listPurchaseOrders(
            id,
            page,
            (invoices: any, current: number, lastPage: number) => {
                setInvoices(invoices);
                setCurrent(current);
                setPagecount(lastPage);
            },
            () => {}
        );

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user") as string);
        const role = user?.userRoles?.map((user: any) => user?.role);
        setType(role);
        if (role?.includes("Vendor")) {
            setVendors([
                {
                    text: user?.name,
                    value: user?.id,
                    key: user?.id,
                },
            ]);
            setSelected(user?.id);
            getFinanceData(user?.id, 1);
            return;
        }
        MetaService.getUserAssigned(
            "Vendor",
            (vendors: any) => {
                setVendors(vendors);
                setSelected(vendors[0].key);
                getFinanceData(vendors[0].key, 1);
            },
            () => {}
        );
    }, []);

    return (
        <div className="purchase-order">
            {/* {type?.includes("Vendor") ? null : ( */}
                <div className="purchase-order__card mb-6">
                    <div className="purchase-order__select">
                        <div>Select Vendor</div>
                        {vendors ? (
                            <Dropdown
                                placeholder="Select Vendor"
                                disabled={type?.includes("Vendor")}
                                val={type?.includes("Vendor") ? selected: vendors[0]?.key}
                                options={vendors}
                                onChange={(_, { value }: any) => {
                                    getFinanceData(value, current);
                                    setSelected(value);
                                }}
                            />
                        ) : null}
                    </div>
                    <div>
                        <Link to={`${RAISE_INVOICE}?id=${selected}`}>
                            <Button className="mr-2 ml-2" primary>
                                <span>Raise Invoice</span>
                            </Button>
                        </Link>
                    </div>
                </div>
            {/* )} */}

            <div className="purchase-order__table">
                <ReactTable
                    headerLeft
                    name=""
                    columns={columns}
                    data={invoices}
                />
                {pageCount === 1 ? null : (
                    <Pagination
                        className="pagenation-container"
                        boundaryRange={0}
                        activePage={current}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                        totalPages={pageCount}
                        onPageChange={(_, { activePage }: any) => {
                            getFinanceData(selected, activePage);
                            setCurrent(activePage);
                        }}
                    />
                )}
            </div>
            {/* <AddBrand open={showModal} setOpen={setShowModal}/> */}
        </div>
    );
};
