import _ from "lodash";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Dropdown as DropdownSemantic, DropdownProps } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AsyncLocalStorage } from "async_hooks";

interface Props extends RouteComponentProps {
    options: {
        key: string;
        text: any;
        value: string;
        disabled?: boolean;
    }[];
    multiple?: boolean;
    search?: boolean;
    onChange?: (e: any, data: any) => void;
    defaultValue?: string[] | string | number[];
    placeholder?: string;
    disabled?: boolean;
    val?: any;
    edit?: boolean;
    id?: any;
    width?: any;
    valOnly?:Boolean
    onBlur?: (event: KeyboardEvent<HTMLElement>, data: DropdownProps) => void
}
export const Dropdown = withRouter(
    ({
        options,
        placeholder,
        multiple,
        search,
        onChange,
        defaultValue,
        disabled = false,
        location,
        val = "",
        edit = false,
        id = null,
        width,
        valOnly=false,
        onBlur
    }: Props) => {
        const [type] = location.pathname?.split("/").reverse();
        const [value, setValue] = useState<any>(val);

        useEffect(() => {
            if(valOnly)
            setValue(val)
            else
            setValue(id || value || val || defaultValue);
        }, [val, defaultValue]);

        if (defaultValue) {
            return (
                <DropdownSemantic
                    width={width}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    placeholder={placeholder}
                    fluid
                    multiple={multiple}
                    search={search}
                    selection
                    options={options}
                    disabled={disabled}
                    value={value}
                />
            );
        }

        if (type !== "edit" || edit) {
            return (
                <DropdownSemantic
                    defaultValue={defaultValue}
                    onChange={(...args) => {
                        if (onChange) {
                            onChange(...args);
                        }
                        setValue(args[1].value);
                    }}
                    placeholder={placeholder}
                    fluid
                    multiple={multiple}
                    search={search}
                    selection
                    options={options}
                    disabled={disabled}
                    value={value}
                    onBlur={onBlur}
                />
            );
        }
        return null;
    }
);
