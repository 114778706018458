import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./vendorProducts.scss";
import ReactTable from "../../../shared/components/ReactTable";
import { getProducts } from "../../../shared/utils/faker_data";
import { VendorService } from "../../../store/services/Vendor.service";
import { Pagination } from "semantic-ui-react";
import { RouteComponentProps, withRouter } from "react-router-dom";

function VendorProducts(props: any) {
    const data = getProducts(13);
    const [products, setProducts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [current, setCurrent] = useState(0);
    const columns: any = [
        {
            Header: "Product Name",
            accessor: "name",
        },
        {
            Header: "SKU",
            accessor: "masterProduct[sku]",
            style: { whiteSpace: "unset", maxWidth: "3.5rem" },
        },
        {
            Header: "Vendor SKU",
            accessor: "sku",
        },
        {
            Header: "Quantity",
            accessor: "quantity",
        },
        {
            Header: "Storage",
            accessor: "storage",
        },
        {
            Header: "Memory",
            accessor: "memory",
        },
    ];

    const fetchVendorProduct = (pageNumber: number) => {
        const params: any = props.match.params;
        VendorService.vendorProducts(
            params?.vendorId,
            pageNumber,
            (products: any, currentPage: number, lastPage: number) => {
                setProducts(products);
                setCurrent(currentPage);
                setPageCount(lastPage);
            },
            () => {}
        );
    };

    useEffect(() => {
        fetchVendorProduct(1);
    }, []);
    return (
        <div className="vendor-products">
            <ReactTable
                name=""
                columns={columns}
                data={products}
                pageCount={pageCount}
                current={current}
            />
            {pageCount === 1 ? null : (
                <Pagination
                    className="pagenation-container"
                    boundaryRange={0}
                    activePage={current}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={pageCount}
                    onPageChange={(_, { activePage }: any) => {
                        fetchVendorProduct(activePage);
                    }}
                />
            )}
        </div>
    );
}

VendorProducts.propTypes = {};

export default withRouter(VendorProducts);
