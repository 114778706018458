import * as yup from "yup";

export const passwordResetValidationSchema = yup.object({
    old_password: yup.string().required().min(8).max(40),
    new_password: yup.string().required().min(8).max(40),
    confirm_password: yup
        .string()
        .required()
        .min(8)
        .max(40)
        .oneOf([yup.ref("new_password"), null], "Passwords must match"),
});
