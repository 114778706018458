import React from "react";
import { Formik } from "formik";
import { Form, Button } from "semantic-ui-react";
import FormErrorMessage from "../../../shared/components/FormErrorMessage";
import "./transactionIdForm.scss";
import { FinanceService } from "../../../store/services/FinanceService/Finance.service";
import { useParams } from "react-router";

export const TranscationIdForm = ({ cancelHandler, modalControl }: any) => {
    const initialValues = {
        transactionId: "",
        modeOfTransfer: "",
    };

    const params: any = useParams();
    return (
        <div className="transaction-id-form">
            <Formik
                initialValues={initialValues}
                // validationSchema={productFormValidationSchema}
                onSubmit={(values, actions) => {
                    FinanceService.markAsPaid(
                        params?.id,
                        {
                            transaction_id: values?.transactionId,
                            mode_of_transfer: values?.modeOfTransfer,
                        },
                        () => {
                            modalControl(false);
                        },
                        () => {
                            modalControl(false);
                        }
                    );
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    errors,
                    isValid,
                    dirty,
                    setFieldValue,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Field>
                                    <label>Transaction Id</label>
                                    <input
                                        name="transactionId"
                                        placeholder="Transaction Id"
                                        type="text"
                                        value={values.transactionId}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={errors.transactionId}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field>
                                    <label>Mode of Transfer</label>
                                    <input
                                        name="modeOfTransfer"
                                        placeholder="Mode of Transfer"
                                        type="text"
                                        value={values.modeOfTransfer}
                                        onChange={handleChange}
                                    />
                                    <FormErrorMessage
                                        message={errors.modeOfTransfer}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Button
                                    // color="red"
                                    className="mb-5"
                                    onClick={() => {
                                        modalControl("Processing");
                                    }}
                                >
                                    {"Cancel"}
                                </Button>
                                <Button
                                    color="green"
                                    className="mb-5"
                                    onClick={() => {
                                        handleSubmit();
                                        // console.log("clicked")
                                    }}
                                >
                                    {"Submit"}
                                </Button>
                            </Form.Group>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
