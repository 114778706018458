import React, { useEffect } from "react";
import "./authWrapper.scss";
import PropTypes from "prop-types";
import AuthRoutes from "../auth-routes";
import { withRouter } from "react-router-dom";
import { useLockBodyScroll } from "../../../shared/components/LockBodyScroll";
import AuthContainer from "../../../store/containers/authContainer";
import LycaMobile from "../../../assets/images/LycaMobile.png";
import Curve from "../../../assets/images/curve.svg";

function AuthWrapper(props: any) {
    const { authenticated, history } = props;
    useLockBodyScroll();
    useEffect(() => {
        if (authenticated) {
            history.push("/");
        }
    }, []);
    return (
        <div className="auth-wrapper">
            <div className="auth-wrapper__logo-section">
                <div className="auth-wrapper__logo-section-content">
                    <img src={LycaMobile} alt="" />
                </div>
            </div>
            <div className="auth-wrapper__auth-section">
                <AuthRoutes />
            </div>
        </div>
    );
}

AuthWrapper.propTypes = {};

export default AuthContainer(withRouter(AuthWrapper));
