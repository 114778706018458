import { serializable, alias, primitive, object, list } from "serializr";
import { FinanceMasterProduct } from "../Vendor/VendorProduct";

class ShippingRegion {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("country_id", primitive()))
    countryId?: number;
}

export class FinanceOrder {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("order_no", primitive()))
    orderNo?: string;

    @serializable(alias("order_date", primitive()))
    orderDate?: string;

    @serializable(alias("shipment_charges", primitive()))
    shipmentCharges?: number;

    @serializable(alias("currency", primitive()))
    currency?: string;

    @serializable(alias("customer_name", primitive()))
    customerName?: string;

    @serializable(alias("customer_email", primitive()))
    customeremail?: string;

    @serializable(alias("customer_phone_no", primitive()))
    customerPhoneNo?: string;

    @serializable(alias("address", primitive()))
    address?: string;

    @serializable(alias("zip_code", primitive()))
    zipCode?: string;

    @serializable(alias("payment_method_name", primitive()))
    paymentMethodName?: string;

    @serializable(alias("payment_status", primitive()))
    paymentStatus?: string;

    @serializable(alias("shipment_region_id", primitive()))
    shipmentRegionId?: number;

    @serializable(alias("order_status", primitive()))
    orderStatus?: string;

    @serializable(alias("customer_id", primitive()))
    customerId?: number;

    @serializable(alias("sub_total", primitive()))
    subTotal?: number;

    @serializable(alias("amount", primitive()))
    amount?: number;

    @serializable(alias("country_id", primitive()))
    countryId?: number;

    @serializable(alias("shipment_region", object(ShippingRegion)))
    shipmentRegion?: ShippingRegion;
}

class DeliveryAddress {
    @serializable(alias("name", primitive()))
    name?: string;

    @serializable(alias("address", primitive()))
    address?: string;

    @serializable(alias("region", primitive()))
    region?: string;

    @serializable(alias("zip", primitive()))
    zip?: string;

    @serializable(alias("phone", primitive()))
    phone?: string;
}
export class VendorOrder {
    @serializable(alias("purchase_order_number", primitive()))
    orderNumber?: string;

    @serializable(alias("name", primitive()))
    productName?: string;

    @serializable(alias("sku", primitive()))
    productSku?: string;

    @serializable(alias("price", primitive()))
    price?: number;

    @serializable(alias("category", primitive()))
    category?: string;

    @serializable(alias("order_date", primitive()))
    orderDate?: string;

    @serializable(alias("last_modified", primitive()))
    lastModified?: string;

    @serializable(alias("status", primitive()))
    status?: string;

    @serializable(alias("delivery_address", object(DeliveryAddress)))
    deliveryAddress?: DeliveryAddress;
}

class PurchaseOrder{
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("order_item_id", primitive()))
    orderItemId?: number;

    @serializable(alias("po_number", primitive()))
    poNumber?: String;
}
export class OrderItem {
    @serializable(alias("id", primitive()))
    id?: number;

    @serializable(alias("user_order_id", primitive()))
    userOrderId?: number;

    @serializable(alias("master_product_id", primitive()))
    masterProductId?: number;

    @serializable(alias("quantity", primitive()))
    quantity?: number;

    @serializable(alias("unit_rate", primitive()))
    unitRate?: number;

    @serializable(alias("tax_applicable", primitive()))
    taxApplicable?: number;

    @serializable(alias("currency", primitive()))
    currency?: string;

    @serializable(alias("vendor_order_no", primitive()))
    vendorOrderNo?: string | null;

    @serializable(alias("vendor_product_id", primitive()))
    vendorProductId?: string | null;

    @serializable(alias("vendor_id", primitive()))
    vendorId?: number;

    @serializable(alias("status", primitive()))
    status?: number;

    @serializable(alias("master_product", object(FinanceMasterProduct)))
    masterProduct?: FinanceMasterProduct;    
    
    @serializable(alias("purchase_order", object(PurchaseOrder)))
    purchaseOrder?: PurchaseOrder;

}

export class FinanceOrderDetail extends FinanceOrder {
    @serializable(alias("order_item", list(object(OrderItem))))
    orderItem?: OrderItem[];
}
