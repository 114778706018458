import React from "react";
import PropTypes from "prop-types";
import "./vendorProfile.scss";
import lycaLogo from "../../../assets/images/lyca-logo.png";
import { Vendor } from "../../../store/models/Vendor/vendor.model";

interface Props {
    vendor?: Vendor;
}

function VendorProfile({ vendor }: Props) {
    return (
        <div className="vendor-profile">
            <div className="vendor-profile__thumbnail">
                <div className="vendor-profile__thumbnail-header">
                    <img src={lycaLogo} alt="Lyca Logo" />
                </div>
                <div className="vendor-profile__thumbnail-body">
                    <h2>{vendor?.name}</h2>
                    <p>{`${vendor?.address}, ${vendor?.city}`}</p>
                    <div>
                        <span className="badge primary rounded"> Active </span>
                    </div>
                </div>
            </div>
            <div className="vendor-profile__content">
                <div className="vendor-profile__overview">
                    <h3>Company Profile</h3>
                    <div className="vendor-profile__overview-row">
                        <span className="vendor-profile__overview-icon">
                            <i className="ui icon user"></i>
                        </span>
                        <span className="vendor-profile__overview-text">
                            {vendor?.name}
                        </span>
                    </div>
                    <div className="vendor-profile__overview-row">
                        <span className="vendor-profile__overview-icon">
                            <i className="ui icon envelope"></i>
                        </span>
                        <span className="vendor-profile__overview-text">
                            {vendor?.email}
                        </span>
                    </div>
                    <div className="vendor-profile__overview-row">
                        <span className="vendor-profile__overview-icon">
                            <i className="ui icon phone"></i>
                        </span>
                        <span className="vendor-profile__overview-text">
                            {vendor?.phoneNo}
                        </span>
                    </div>
                    <div className="vendor-profile__overview-row">
                        <span className="vendor-profile__overview-icon">
                            <i className="ui icon map marker alternate"></i>
                        </span>
                        <span className="vendor-profile__overview-text">
                            {`${vendor?.vendorShipmentAddress?.address}, ${vendor?.vendorShipmentAddress?.city}`}
                        </span>
                    </div>
                </div>
                <div className="vendor-profile__tax-details">
                    <h3>Tax Details</h3>
                    <table className="vendor-profile__tax-table">
                        <thead>
                            <tr>
                                <th>Tax Type</th>
                                <th>Standard</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-bold">
                                    {vendor?.vendorTaxRule?.taxTypeId && "VAT"}{" "}
                                    (%)
                                </td>
                                <td>{vendor?.vendorTaxRule?.taxPercentage}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="mt-5">
                        <span className="text-bold">
                            Default markup percentage
                        </span>
                        <span
                            className="text-dark-grey"
                            style={{ marginLeft: "20px" }}
                        >
                            {vendor?.vendorTaxRule?.globalMarkupPrice} %
                        </span>
                    </div>
                </div>
                <div className="vendor-profile__shipment-origin">
                    <h3>Shipment Origin Address</h3>
                    <div className="vendor-profile__shipment-row">
                        <span className="vendor-profile__shipment-icon">
                            <i className="ui icon map marker alternate"></i>
                        </span>
                        <span className="vendor-profile__shipment-text">
                            {`${vendor?.vendorShipmentAddress?.address}, ${vendor?.vendorShipmentAddress?.city}`}
                        </span>
                    </div>
                </div>
                <div className="vendor-profile__shipment-rules">
                    <h3>Shipment Rules</h3>
                    <div className="vendor-profile__shipment-row">
                        <span className="vendor-profile__shipment-label">
                            Shipment Zones
                        </span>
                        <span>Amsterdam</span>
                    </div>
                </div>
                <div className="vendor-profile__account-details">
                    <h3>Bank Account Details</h3>
                    <div className="vendor-profile__shipment-row">
                        <span className="vendor-profile__shipment-label">
                            Sort Code
                        </span>
                        <span>{vendor?.vendorBankDetail?.sortCode}</span>
                    </div>
                    <div className="vendor-profile__shipment-row">
                        <span className="vendor-profile__shipment-label">
                            Bank Name
                        </span>
                        <span>{vendor?.vendorBankDetail?.bankName}</span>
                    </div>
                    <div className="vendor-profile__shipment-row">
                        <span className="vendor-profile__shipment-label">
                            Account Number
                        </span>
                        <span>{vendor?.vendorBankDetail?.accountNumber}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

VendorProfile.propTypes = {};

export default VendorProfile;
