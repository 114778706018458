// export const BASE_URL = "http://localhost:8000/api/v1";
// export const BASE_URL = "http://ummtech.com/demo/lyca-oms-api/api/v1";
// export const BASE_URL = "http://qaukeshop.ldsvcplatform.com/lyca-oms-api/api/v1";
export const BASE_URL = "https://api.eshop2.lycamobile.co.uk/api/v1";
// export const MAGENTO_BASE_URL = "http://ummtech.com/demo/lyca-api/rest/V1";
export const MAGENTO_BASE_URL =
    "https://magento.eshop2.lycamobile.co.uk/rest/V1";

export const LOGIN_API_URL = BASE_URL + "/login";

/* Vendor API endpoints */

export const GET_PRODUCT_LIST = "/products";

export const GET_ATTRIBUTES = GET_PRODUCT_LIST + "/attributes";

export const VENDOR_API_URL = "/admin/vendors";
export const VENDOR_DETAILS_API_URL = "/admin/vendors/:id";
export const GET_VENDOR_PRODUCTS_API = (vendorId: any) =>
    `/product/vendor/${vendorId}`;

// Meta api
export const SHIPPING_ADDRESS_META_API = "/shipment-regions";
export const COUNTRIES_META_API = "/countries";
export const TAX_API = "/tax-types";

//vendor API
export const VENDOR_API_PRODUCTS = "vendor/product";
export const VENDOR_API_ORDERS = "vendor/orders";
export const VENDOR_API_ORDER_UPDATE = "vendor/orders/:po";

// Products
export const PRODUCT_VENDOR_API = "/product/vendor";
export const PRODUCT_API = PRODUCT_VENDOR_API + "/all";
export const GET_ALL_PRODUCTS_API = "/product/master";
export const GET_ALL_BRANDS_URL = "/product/brand";
export const SNYC_MASTER_PRODUCTS_API = "/product/master/sync";
export const MERGE_VENDOR_PRODUCTS_API = "/product/vendor/merge_to_master";
export const GET_PRODUCT_BRAND = "/product/vendor_brand";
export const GET_VENDOR_TAX_PERCENTAGE =
    "/product/vendor/:id/markup_percentage";
export const DELETE_PRODUCT_BRAND = GET_ALL_BRANDS_URL;
export const GET_VENDOR_PRODUCT_API = (vendorProductId: any) =>
    `/product/vendor_product/${vendorProductId}`;
export const APPROVE_VENDOR_PRODUCT = "/product/vendor/approve";

// Create admin products
export const CREATE_VENDOR_PRODUCT = "/admin/vendor_product";

// Orders
export const FINANCE_ORDERS = "/finance/order";
export const APPROVAL_FINANCE_ORDER = (id: number) =>
    `${FINANCE_ORDERS}/${id}/approve`;
export const REJECT_FINANCE_ORDER = (id: number) =>
    `${FINANCE_ORDERS}/${id}/reject`;
export const UPDATE_ORDER_STATUS = (orderId: number) =>
    `/finance/order/${orderId}`;

// Ticket
export const GET_TICKETS_URL = (id: any) =>
    `/support/country/${id}/maintenance-tickets`;

export const GET_TICKET_URL = (id: any, ticketId: any) =>
    `/support/country/${id}/maintenance-tickets/${ticketId}`;

// Meta
export const PRODUCT_LIST_URL = (id: any) =>
    `/support/user_order/${id}/products`;
export const ISSUE_TYPE_URL = `/issue-types`;
export const ISSUE_STATUS_URL = `/issue-statuses`;
export const CS_Team_GROUP_URL = `/cs-team-groups`;
export const GROUP_URL = "/groups";
export const ISSUE_PRIORITIES_URL = "/issue-priorities";
export const USER_ASSIGNED_URL = (countryId: string) =>
    `/country/${countryId}/users`;
export const ORDER_STATUS = `/order-status`;

// Finance
export const FINANCE = "/finance";
export const GET_ALL_PURCHASE_ORDERS_URL = FINANCE + "/purchase_order";
export const INVOICE_URL = FINANCE + "/invoice";
export const MARK_AS_PAID_URL = (id: any) =>
    `${INVOICE_URL}/${id}/mark_as_paid`;

export const ALL_USERS_URL = "/admin/users";

export const META_API = {
    PRODUCT_CATEGORIES: "/categories",
    ATTRIBUTES: "/products/attributes/:id",
    COUNTRIES: "/directory/countries",
    REGION: (id: any) => `/directory/countries/${id}`,
    ISSUE_TYPES: "/return_types",
};

export const DASHBOARD_API = {
    cards: "analytics/details",
    top5Products: "analytics/charts/top5products",
    top5Vendors: "analytics/charts/top5vendors",
    revenueByMonth: "analytics/charts/monthly_revenue",
    revenueByWeek: "analytics/charts/weekly_revenue",
};

export const RMA = {
    getAll: "/support/country/:id/rma",
    createRMA: "/support/country/:countryId/rma",
    get: "/support/country/:countryId/rma/:id",
};

export const USERS = {
    me: "/me",
    updatePassword: "update_password",
    resetPassword: "admin/user/:id/reset-password",
};
