import { deserialize } from "serializr";
import { DASHBOARD_API } from "../../../routes/route-constants/api-routes";
import LocalStorage from "../../../shared/utils/LocalStorage";
import { store } from "../../../store";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { DashboardCardModel } from "../../models/Dashboard/DashboardCards.model";
import { RevenueByMonth } from "../../models/Dashboard/RevenueByMonth.model";
import { RevenueByWeek } from "../../models/Dashboard/RevenueByWeek.model";
import { TopFiveProducts } from "../../models/Dashboard/TopFiveProducts.model";
import { TopFiveVendors } from "../../models/Dashboard/TopFiveVendors.model";

export class DashboardService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static getCardData(
        onSuccess?: (x?: DashboardCardModel) => void,
        onError?: (x?: Error) => void
    ) {
        const params = {
            country_id: localStorage.getItem("country"),
        };
        this.addLoader();
        return axiosInstance
            .get(DASHBOARD_API.cards, { params })
            .then((res) => {
                const data = deserialize(DashboardCardModel, res.data);
                onSuccess && onSuccess(data);
            })
            .catch((err) => {
                onError && onError(err);
            })
            .finally(this.removeLoader);
    }

    static getTop5Products(
        payload: {
            start_date : String,
            end_date: String
        },
        onSuccess?: (x?: TopFiveProducts) => void,
        onError?:(x:Error) => void
    ){
        const params = {
            country_id: localStorage.getItem("country"),
            ...payload
        };
        this.addLoader();
        return axiosInstance.get(DASHBOARD_API.top5Products, {params})
            .then((res)=>{
                const data = deserialize(TopFiveProducts, res);
                onSuccess && onSuccess(data);
            })
            .catch((err)=>{
                onError && onError(err);
            })
            .finally(this.removeLoader)
    }

    static getTop5Vendors(
        payload: {
            start_date : String,
            end_date: String
        },
        onSuccess?: (x?: TopFiveVendors) => void,
        onError?:(x:Error) => void
    ){
        const params = {
            country_id: localStorage.getItem("country"),
            ...payload
        };
        this.addLoader();
        return axiosInstance.get(DASHBOARD_API.top5Vendors, {params})
            .then((res)=>{
                const data = deserialize(TopFiveVendors, res);
                onSuccess && onSuccess(data);
            })
            .catch((err)=>{
                onError && onError(err);
            })
            .finally(this.removeLoader)
    }

    static getRevenueMonth(
        payload: {
            start_date : String,
            end_date: String
        },
        onSuccess?: (x?:RevenueByMonth) =>void,
        onError?: (x?:Error) =>void
    ){
        const params = {
            country_id: localStorage.getItem("country"),
            ...payload
        };
        this.addLoader();
        return axiosInstance.get(DASHBOARD_API.revenueByMonth, {params})
        .then(res=>{
            const data = deserialize(RevenueByMonth, res);
            onSuccess && onSuccess(data);
        })
        .catch(onError)
        .finally(this.removeLoader)

    }

    static getRevenueWeek(
        payload: {
            start_date : String,
            end_date: String
        },
        onSuccess?: (x?:RevenueByWeek) =>void,
        onError?: (x?:Error) =>void
    ){
        const params = {
            country_id: localStorage.getItem("country"),
            ...payload
        };
        this.addLoader();
        return axiosInstance.get(DASHBOARD_API.revenueByWeek, {params})
        .then(res=>{
            const data = deserialize(RevenueByWeek, res);
            onSuccess && onSuccess(data);
        })
        .catch(onError)
        .finally(this.removeLoader)

    }

}
