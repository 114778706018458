import React from 'react';
import PropTypes from 'prop-types';
import './formErrorMessage.scss';

function FormErrorMessage({ message }: {message: string | undefined}) {
    return (
        <div className='form-error-message'>
            {message}
        </div>
    );
}

FormErrorMessage.propTypes = {
    message: PropTypes.string
};

export default FormErrorMessage;
