import axiosInstance from "../../interceptors/axiosInterceptor";
import {
    GET_ALL_PRODUCTS_API,
    PRODUCT_API,
    GET_ALL_BRANDS_URL,
    SNYC_MASTER_PRODUCTS_API,
    CREATE_VENDOR_PRODUCT,
    MERGE_VENDOR_PRODUCTS_API,
    GET_PRODUCT_BRAND,
    DELETE_PRODUCT_BRAND,
    APPROVE_VENDOR_PRODUCT,
    PRODUCT_VENDOR_API,
    VENDOR_API_PRODUCTS,
} from "../../../routes/route-constants/api-routes";
import { deserialize, serialize } from "serializr";
import { Product } from "../../models/Vendor/VendorProduct";
import { Vendor, VendorProductModel } from "../../models/Vendor/vendor.model";
import { Brand } from "../../models/Brand/Brand.model";
import VendorProducts from "../../../views/Vendors/VendorProducts";
import { VendorBrand } from "../../models/Vendor/vendorBrand.model";
import AppToast from "../../../shared/components/AppToast";
import { store } from "../../../store";
import { INVOKE_TOASTER } from "../../definitions/toastConstants";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import LocalStorage from "../../../shared/utils/LocalStorage";
import { DimmerDimmable } from "semantic-ui-react";

type StyleType = "pending" | "merged" | "update" | "approved" | "hold";

export class ProductService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }

    static getAllProductFilter(
        page: number,
        status: StyleType,
        search: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        this.addLoader();
        const country_id = localStorage.getItem("country");

        const params: {
            status: StyleType;
            country_id: string | null;
            page: number;
            name?: string;
        } = {
            status,
            country_id,
            page,
        };
        if (search) {
            params.name = search;
        }
        return axiosInstance
            .get(PRODUCT_API, {
                params,
            })
            .then((response) => {
                const products = deserialize(Product, response.data?.data);
                onSuccess(
                    products,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getAllVendorProducts(
        page: number,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        const country_id = localStorage.getItem("country");
        return axiosInstance
            .get(VENDOR_API_PRODUCTS, {
                params: {
                    country_id,
                    page,
                },
            })
            .then((response) => {
                const products = deserialize(Product, response.data?.data);
                onSuccess(
                    products,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getAllMasterProduct(
        page: number,
        search: string,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        const country_id = localStorage.getItem("country");
        const params: {
            country_id: string | null;
            page: number;
            name?: string;
        } = {
            country_id,
            page,
        };

        if (search) {
            params.name = search;
        }
        return axiosInstance
            .get(GET_ALL_PRODUCTS_API, {
                params,
            })
            .then((response) => {
                const products = deserialize(Product, response.data?.data);
                onSuccess(
                    products,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static searchMergeProduct(
        options: { page: number; search: string },
        onSuccess: Function,
        onError: Function
    ) {
        const country_id = localStorage.getItem("country");
        const params: any = {
            page: options.page,
        };

        if (options.search) {
            params["search"] = options.search;
        }
        this.addLoader();
        return axiosInstance
            .get(GET_ALL_PRODUCTS_API, {
                params: {
                    ...params,
                    country_id,
                },
            })
            .then((response) => {
                const products = deserialize(Product, response.data?.data);
                onSuccess(
                    products,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getAllBrandProducts(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .get(GET_ALL_BRANDS_URL)
            .then((response) => {
                const brands = deserialize(Brand, response.data);
                onSuccess(
                    brands,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static createBrand(payload: any, onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .post(GET_ALL_BRANDS_URL, payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {})
            .finally(() => {
                this.removeLoader();
            });
    }

    static syncMasterProducts(onSuccess: Function, onError: Function) {
        this.addLoader();
        return axiosInstance
            .put(
                `${SNYC_MASTER_PRODUCTS_API}?country_id=${localStorage.getItem(
                    "country"
                )}`
            )
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static createAdminProducts(
        payload: any,
        onSuccess: Function,
        onError: Function
    ) {
        const data = Object.assign(new Product(), payload);
        const serilizedData = serialize(data);
        this.addLoader();
        return axiosInstance
            .post(CREATE_VENDOR_PRODUCT, {
                ...serilizedData,
                sku: data?.vendorSku,
                country_id: localStorage.getItem("country"),
            })
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static VendorMergeRequest(
        payload: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(MERGE_VENDOR_PRODUCTS_API, payload)
            .then((response) => {
                const product = deserialize(Product, response.data);
                onSuccess(product);
            })
            .catch((error) => {
                const errorResponse = error?.response;
                if (errorResponse?.status === 409) {
                    store.dispatch({
                        type: INVOKE_TOASTER,
                        payload: {
                            type: "error",
                            message:
                                "vendor should have only one product associated with one master product",
                        },
                    });
                }
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static getAllProductBrand(
        options: { page: number; brand_id: number; search: string, vendor_id?:number },
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        const params: {
            page: number;
            brand_id: number;
            country_id: string | null;
            name?: string;
            vendor_id?:number;
        } = {
            ...options,
            country_id: LocalStorage.getValue("country", false),
        };
        if (options.search) {
            params.name = options.search;
        }
        return axiosInstance
            .get(GET_PRODUCT_BRAND, {
                params,
            })
            .then((response) => {
                const product = deserialize(VendorBrand, response.data?.data);
                onSuccess(
                    product,
                    response?.data?.current_page,
                    response?.data?.last_page
                );
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static updateBrandMarkup(
        options: { productId: number; markupPercentage: number },
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(`${GET_PRODUCT_BRAND}/${options.productId}`, {
                markup_percentage: options.markupPercentage,
            })
            .then((response) => {
                const product = deserialize(VendorBrand, response.data?.data);
                onSuccess(product);
            })
            .catch((error) => {
                onError(error);
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static deleteMarkup(
        productIds: any,
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .delete(DELETE_PRODUCT_BRAND, {
                params: {
                    brand_ids: productIds.join(),
                },
            })
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }

    static approveVendorProducts(
        payload: {
            vendor_product: number;
        },
        onSuccess: Function,
        onError: Function
    ) {
        this.addLoader();
        return axiosInstance
            .put(APPROVE_VENDOR_PRODUCT, payload)
            .then((response) => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            })
            .finally(() => {
                this.removeLoader();
            });
    }
}
