export const BASE_NAME = "/admin";
export const AUTH = "/auth";
export const LOGIN = AUTH + "/login";
export const RESET_PASSWORD = AUTH + "/reset-password";
export const CREATE_NEW_PASSWORD = AUTH + "/create-new-password";

/* Dashboard */
export const DASHBOARD = "/";

/* Vendor routes */
export const VENDORS = "/vendors";
export const ADD_VENDOR = "/add-vendor";
export const VENDOR_DETAIL = "/vendors/:vendorId";

/* Products routes */
export const PRODUCTS = "/products";
export const ADD_PRODUCT = "/add-product";
export const PRODUCT_DETAIL = "/products/:productId";
export const EDIT_PRODUCT = "/products/:id/edit";

/* Orders routes */
export const ORDERS = "/orders";
export const ORDER_DETAIL = "/orders/:orderId";

/* Tickets routes */
export const TICKETS = "/tickets";
export const ADD_TICKET = "/add-ticket";
export const TICKET_DETAILS = "/tickets/:id";
export const EDIT_TICKET = "/tickets/:id/edit";

/* Invoice routes */

export const INVOICE = "/invoice";
export const RAISE_INVOICE = "/raise-invoice";
export const INVOICE_DETAIL = "/invoice/:id";

/* User routes */

export const USER = "/user";
export const USER_DETAILS = "/user/:id";
export const ADD_USER = "/add-user/:role";
export const MY_PROFILE = "/profile";

/* RMA routes */
export const RMA = "/rma";
export const RMA_FORM = "/rma/add-rma";
export const RMA_DETAILS = "/rma/:id";
