import { serializable, alias, primitive, object, list } from "serializr";

class Revenue{
    @serializable(alias("change_in_percentage", primitive()))
    channgeInPercentae?: number | null;

    @serializable(alias("curr_month", primitive()))
    CurrentMonth?: number;
}

class Customers{
    @serializable(alias("change_in_percentage", primitive()))
    channgeInPercentae?: number | null;

    @serializable(alias("new_customers", primitive()))
    newCustomers?: number;
}

class LycaProfit{
    @serializable(alias("profit", primitive()))
    profit?: number;
}

class Total{
    @serializable(alias("total", primitive()))
    total?: number;
}

export class DashboardCardModel{
    @serializable(alias("revenue", object(Revenue)))
    revenue?: Revenue;

    @serializable(alias("customers", object(Customers)))
    customers?: Customers;

    @serializable(alias("lyca_profit", object(LycaProfit)))
    lycaProfit?: LycaProfit;

    @serializable(alias("total", object(Total)))
    total?: Total;
}