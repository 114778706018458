export const ORDER_STATUS_COLORS = [
    "#FB6340",
    "#F53C56",
    "#2F11EF",
    "#FB6340",
    "#2DCE98",
    "#F53C56",
    "#F53C56",
    "#FB6340",
    "#33FF77",
];

export const ORDER_STATUS = [
    "Waiting on Lyca Finance",
    "Rejected by Lyca Finance",
    "Complete",
    "Processing",
    "Closed",
    "Cancelled",
    "Rejected",
    "Returned",
    "delivered",
];

export const INCOMING_STATUS: any = {
    waiting_on_lyca_finance: 0,
    rejected_by_lyca_finance: 1,
    on_the_way: 3,
    shipped: 2,
    processing: 3,
    accepted: 4,
    cancelled: 5,
    rejected: 6,
    returned: 7,
    delivered: 8,
};

export const ORDER_PROCESSING_STATUS = ["Processing", "Shipped"];
