import React from 'react';
import PropTypes from 'prop-types';

function CreatePassword() {
    return (
        <div>
            CreatePassword works
        </div>
    );
}

CreatePassword.propTypes = {};

export default CreatePassword;
