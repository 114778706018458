import {LIST_VENDOR} from "../definitions/vendorConstants";


export function getVendorActions(successCallback: Function, errorCallback: Function, search: string = "") {
    return {
        type: LIST_VENDOR,
        payload: {
            successCallback,
            errorCallback,
            search
        },
    }
}
