import {
    GET_ATTRIBUTES,
    META_API,
    MAGENTO_BASE_URL,
} from "../../../routes/route-constants/api-routes";
import { deserialize } from "serializr";
import { Attributes } from "../../models/Meta/AttributesModel";
import { generatePath } from "react-router-dom";
import LocalStorage from "../../../shared/utils/LocalStorage";
import { ATTRIBUTE_TYPES } from "../../../enums/attributeTypes";
import axiosInstance from "../../interceptors/axiosInterceptor";
import Axios from "axios";
import { ADD_LOADER, REMOVE_LOADER } from "../../definitions/loaderConstants";
import { store } from "../../../store";

type ATTRIBUTES =
    | "sim_data"
    | "sim_ukmins"
    | "contract_length"
    | "sim_internationalmins";

export class AttributeService {
    static addLoader() {
        store.dispatch({
            type: ADD_LOADER,
        });
    }

    static removeLoader() {
        store.dispatch({
            type: REMOVE_LOADER,
        });
    }
    static getAttributes(
        id: ATTRIBUTE_TYPES,
        onSuccess: (x: Attributes) => void = () => {},
        onError: (x?: any) => void = () => {},
        onEnd: (x?: any) => void = () => {}
    ) {
        this.addLoader();
        return Axios.get(
            `${MAGENTO_BASE_URL}${generatePath(META_API.ATTRIBUTES, { id })}`
        )
            .then((response) => {
                const attributes = deserialize(Attributes, response.data);
                LocalStorage.setAttribute(id, attributes, true);
                onSuccess(attributes);
            })
            .catch(onError)
            .finally(() => {
                this.removeLoader();
                onEnd();
            });
    }

    static getProductsAttributes(
        type: ATTRIBUTES,
        onSuccess: Function,
        onError: (error: Error) => void
    ) {
        this.addLoader();
        return axiosInstance
            .get(`${GET_ATTRIBUTES}`)
            .then((response) => {
                onSuccess();
            })
            .catch(onError)
            .finally(() => {
                this.removeLoader();
            });
    }
}
